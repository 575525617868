import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import * as Routes from "../../../constants/routes";
import * as Permissions from "../../../constants/permissions";
import { MainContext } from "../../../App";

import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import WebIcon from "@material-ui/icons/Web";
import AddIcon from "@material-ui/icons/Add";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DvrIcon from "@material-ui/icons/Dvr";
import FolderIcon from "@material-ui/icons/Folder";
import ComputerIcon from "@material-ui/icons/Computer";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PolicyIcon from "@material-ui/icons/Policy";
import WarningIcon from "@material-ui/icons/Warning";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import { cerrarSesion } from "../../../services/auth";

const useStyles = makeStyles((theme) => ({
  // root: {
  //   display: "flex",
  // },
  paper: {
    marginRight: theme.spacing(2),
  },

  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nested2: {
    paddingLeft: theme.spacing(14),
  },
  nested12: {
    paddingLeft: theme.spacing(10),
  },
}));

const itemMenu = [
  "Web CyD",
  "Prueba Sin Link",
  "NOTICIAS",
  "PLAN 2022",
  "LinkedIn",
  "CANAL DE DENUNCIAS",
];

export default function ItemMenuMobile(props) {
  const classes = useStyles();
  const { usuarioSesion, permisos } = useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const anchorRef = React.useRef(null);
  let history = useHistory();

  const handleClick = () => {
    setOpen1(!open1);
  };

  const handleClick2 = () => {
    setOpen2(!open2);
  };

  const handleClick3 = () => {
    setOpen3(!open3);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  async function handleCerrarSession() {
    handleToggle();
    await cerrarSesion();

    // handleMobileMenuClose();
  }

  const handleClose = (event, index) => {
    console.log(index);
    if (index == 0) {
      window.open("https://cydingenieria.cl/", "_blank");
    } else if (index === 1) {
      // history.push("/noticias")
    } else if (index === 2) {
      window.open(
        "https://sites.google.com/cydingenieria.cl/plancyd2022",
        "_blank"
      );
    } else if (index === 3) {
      window.open(
        "https://www.linkedin.com/company/c-y-d-ingenieria/",
        "_blank"
      );
    } else if (index === 4) {
      window.open("https://cydingenieria.cl/canal-de-denuncias/", "_blank");
    } else if (index === 5) {
      window.open("https://cydingenieria.cl/canal-de-denuncias/", "_blank");
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        Menú
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: "center bottom",
              width: "100vw",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                {/* <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {itemMenu.map((option, index) => {
                    return <MenuItem key={index} onClick={(event)=>handleClose(event,index)}>{option}</MenuItem>;
                  })}
                </MenuList> */}

                <List
                  component="nav"
                  aria-labelledby="nested-list-subheader"
                  className={classes.root}
                >
                  <ListItem
                    button
                    component="a"
                    href="https://cydingenieria.cl/"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <WebIcon />
                    </ListItemIcon>
                    <ListItemText primary="Web CyD" />
                  </ListItem>
                  <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary="Accesos" />
                    {open1 ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open1} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {permisos && permisos[Permissions.VER_ACCESOS_GERENTES] && (
                        <ListItem
                          button
                          component={Link}
                          to={Routes.GERENTES}
                          className={classes.nested}
                        // onClick={setOpen(false)}
                        >
                          <ListItemIcon>
                            <BusinessCenterIcon />
                          </ListItemIcon>
                          <ListItemText primary="Para Gerentes" />
                        </ListItem>
                      )}
                      <ListItem
                        button
                        className={classes.nested}
                        onClick={handleClick2}
                      >
                        <ListItemIcon>
                          <DvrIcon />
                        </ListItemIcon>
                        <ListItemText primary="CyDocs Administración" />
                        {open2 ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={open2} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItem
                            button
                            component="a"
                            href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://portafolio.cydocs.cl/"
                            target="_blank"
                            className={classes.nested2}
                          >
                            <ListItemText primary="Portafolio" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://administracion.cydocs.cl/"
                            target="_blank"
                            className={classes.nested2}
                          >
                            <ListItemText primary="Administración CyD" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            href="https://script.google.com/a/macros/cydingenieria.cl/s/AKfycbzBb4gDIQl7OgbTvHgzFLL_62o6RJYmlitD3blsKgJ-oOI6ILnO/exec?csrfToken=dS6WgO3l-pp4tZoA6O1iyK4F4lSuEyzC-B3w"
                            target="_blank"
                            className={classes.nested2}
                          >
                            <ListItemText primary="Administración CDZ" />
                          </ListItem>
                          <ListItem
                            button
                            component="a"
                            href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://sda.cydocs.cl/"
                            target="_blank"
                            className={classes.nested2}
                          >
                            <ListItemText primary="Sistema de Adquisiciones" />
                          </ListItem>
                        </List>
                      </Collapse>
                      <ListItem
                        button
                        component="a"
                        href="http://201.238.203.174:5501/"
                        target="_blank"
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <FolderIcon />
                        </ListItemIcon>
                        <ListItemText primary="Acceso Proyectos" />
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href="https://sites.google.com/cydingenieria.cl/recomendacionesdigitales"
                        target="_blank"
                        className={classes.nested}
                      >
                        <ListItemIcon>
                          <ComputerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Consulta Digital" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    component="a"
                    href="https://sites.google.com/cydingenieria.cl/plancyd2022"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <AssignmentTurnedInIcon />
                    </ListItemIcon>
                    <ListItemText primary="PLAN 2022" />
                  </ListItem>
                  <ListItem button onClick={handleClick3}>
                    <ListItemIcon>
                      <LinkedInIcon />
                    </ListItemIcon>
                    <ListItemText primary="LinkedIn" />
                    {open3 ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={open3} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <ListItem
                        button
                        component="a"
                        href="https://www.linkedin.com/company/c-y-d-ingenieria"
                        target="_blank"
                        className={classes.nested12}
                      >
                        <ListItemText primary="CyD Ingeniería" />
                      </ListItem>
                      <ListItem
                        button
                        component="a"
                        href="https://www.linkedin.com/company/c-y-d-tecnologia/"
                        target="_blank"
                        className={classes.nested12}
                      >
                        <ListItemText primary="CyD Tecnología" />
                      </ListItem>
                    </List>
                  </Collapse>
                  <ListItem
                    button
                    component="a"
                    href="https://cydingenieria.cl/canal-de-denuncias/"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText primary="Canal de Denuncias" />
                  </ListItem>

                  <ListItem
                    button
                    component="a"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScas67IjKmML3FSUovKetPrh9Wm83kmuxXncaPkhZu7xPb90Q/viewform"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <WarningIcon />
                    </ListItemIcon>
                    <ListItemText primary="reporta Cuasi-Accidentes" />
                  </ListItem>

                  {/* <ListItem
                    button
                    component="a"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScas67IjKmML3FSUovKetPrh9Wm83kmuxXncaPkhZu7xPb90Q/viewform"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <AnnouncementIcon />
                    </ListItemIcon>
                    <ListItemText primary="Gestor de Incidentes" />
                  </ListItem> */}
                  {permisos && permisos[Permissions.VER_ACCESOS_RECLUTADORES] && (
                    <ListItem
                      button
                      component="a"
                      href="http://reclutadores.cydingenieria.cl/"
                      target="_blank"
                    >
                      <ListItemIcon>
                        <AssignmentIndIcon />
                      </ListItemIcon>
                      <ListItemText primary="Reclutadores" />
                    </ListItem>
                  )}

                  <ListItem
                    button
                    component="a"
                    href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://activos.cydocs.cl/"
                    target="_blank"
                  >
                    <ListItemIcon>
                      <AssignmentIndIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sistema de Gestión de Activos" />
                  </ListItem>


                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
