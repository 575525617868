import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Grid, Typography } from "@material-ui/core";
import { isBrowser, isMobile } from "react-device-detect";

import logo_miCyD from '../../img/logomicyda2.svg';

// const logo = require("../../img/logomicyda.png");

function Page({ texto: Texto }) {
  const classes = useStyles();
  const logoCyD = "https://storage.googleapis.com/mi-cyd/home/logomicyd.png";

  return (
    <Fragment>
      {isMobile ? (
        <Box style={{ alignItems: "center", justifyContent: "center", paddingTop: "6rem" }}>
          <Grid container className={classes.grid_container}>
            <Grid item xs className={classes.center}>
              <img className={classes.imgCelular} src={logoCyD} alt="MiCyD" />
              <Typography className={classes.title} variant="h5">{Texto}</Typography>
              <LinearProgress color="primary" />
              
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box style={{ alignItems: "center", justifyContent: "center", paddingTop: "6rem" }}>
          <Grid container className={classes.grid_container}>
            <Grid item lg className={classes.center}>
              <img className={classes.img} src={logoCyD} alt="MiCyD" />             
              <Typography className={classes.title} variant="h5">{Texto}</Typography>
              <LinearProgress color="primary" className={classes.linearProgress} />
            </Grid>
          </Grid>
        </Box>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  grid_container: {
    alignContent: "center",
    alignItems: "center",
  },
  center: {
    textAlign: "center",
    paddingTop: theme.spacing(11),
  },
  img: {
    height: 250,
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  imgCelular: {
    height: "10rem",
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  linearProgress: {
    marginLeft: "60vh",
    marginRight: "60vh",
  },
  title: {
    margin: theme.spacing(3, 1, 3, 1),
  }
}));

export default Page;
