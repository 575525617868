import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los permisos.
 * @returns Colección de permisos.
 */
export async function Obtener() {
	try {
		let url = `${BACKEND}/${RUTA_PERMISOS}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un nuevo permiso.
 * @param {*} permiso Datos del permiso.
 * @returns Datos del nuevo permiso.
 */
export async function Agregar(permiso) {
	try {
		let url = `${BACKEND}/${RUTA_PERMISOS}`;
		let response = await Axios.post(url, permiso);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un permiso.
 * @param {*} permiso Datos actualizados del permiso.
 * @returns Datos del permiso actualizado.
 */
export async function Actualizar(permiso) {
	try {
		let url = `${BACKEND}/${RUTA_PERMISOS}/${permiso._id}`;
		let response = await Axios.put(url, permiso);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un permiso.
 * @param {*} permisoID ID del permiso.
 * @returns Respuesta.
 */
export async function Eliminar(permisoID) {
	try {
		let url = `${BACKEND}/${RUTA_PERMISOS}/${permisoID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de permisos en Mi CyD.
 */
const RUTA_PERMISOS = "permisos";