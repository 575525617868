import React, { Fragment, useContext } from "react";
import UseSWR from "swr";
import Moment from "moment";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { BorderAllRounded, Cake as CakeIcon } from "@material-ui/icons";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import UserCard from "../../../components/cardUsuario";
import { ObtenerIngresos } from "../requestIngresos";
import { isMobile } from "react-device-detect";
import { MainContext } from "../../../App";

function IngresosCards(props) {
  const { usuarioSesion } = useContext(MainContext);
  const { data } = UseSWR("contrataciones", (key) => ObtenerIngresos(usuarioSesion.gerencia), {
    revalidateOnFocus: false,
  });

  const classes = useStyles();
  let today = Moment().get("date");
  let weekday = Moment().get("isoWeekday");

  return (
    <Fragment>
      {/* TITULO E ICONO */}
      <Grid
        container
        style={{
          marginTop: "1rem",
          backgroundColor: "white",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          paddingTop: 8,
          // paddingBottom: 8,
        }}
      >
        <Grid item xs={10} style={{ alignSelf: "center" }}>
          <Typography
            variant="h6"
            align="center"
            style={{ fontWeight: "bold", paddingLeft: 10 }}
          >
            {`Nuevos Ingresos ${usuarioSesion.gerencia.sigla}`}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ width: "30%" }}>
          <PersonAddIcon style={{ fontSize: 30, color: "#731f1f" }} />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <Typography variant="caption" align="center">
            <strong>{data && usuarioSesion && data.length ? data.length : 0}</strong> en las últimas 2 semanas
          </Typography>
        </Grid>
        <Grid item xs={12}>
        <Divider variant="middle"/>
        </Grid>
      </Grid>

      {/* LISTADO */}
      <div className={classes.root}>
        {data && data.length === 0 && (
          <Grid item md={12} style={{textAlign:"center"}}>
            <label>No hay Nuevos Ingresos</label>
          </Grid>
        )}
        {data && data.length > 0 && (
          <div>
            {usuarioSesion && Array.from(data).map((d, index) => (
              <Card
                key={`ìngresosCard-${index}`}
                elevation={0}
                className={classes.card}
              >
                <CardContent style={{ padding: "5px", paddingLeft: 10 }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs={2} sm={2} md={2}>
                      <Avatar
                        alt={d.nombre}
                        src={`https://ui-avatars.com/api/?name=${d.nombre}+${d.apellido_paterno}&background=f5f5f5&color=731f1f&rounded=true`}
                        className={classes.avatar_size}
                      />
                    </Grid>
                    <Grid
                      container
                      item
                      xs={10} sm={10} md={10}
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      style={{ paddingLeft: "10px" }}
                    >
                      <Typography
                        className={classes.typography_nombre}
                      >{`${d.nombre} ${d.apellido_paterno} ${d.apellido_materno[0]}.`}</Typography>
                      <Typography className={classes.typography_email}>
                        <strong>{d.email}</strong>
                      </Typography>
                      <Typography className={classes.typography_cargo}>
                        <strong>{d.codigo_proyecto}</strong> - {d.cargo}
                      </Typography>
                        
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "17rem",
    overflowY: "scroll",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingBottom: "20px",
  },
  fecha: {
    marginLeft: theme.spacing(2),
    color: "#731f1f",
  },
  sin_cumpleañeros: {
    marginLeft: theme.spacing(3),
    color: "#731f1f",
  },

  avatar_size: {
    width: isMobile ? "40px" : "40px",
    height: "100%",
  },
  typography_nombre: {
    fontWeight: "bold",
    color: "#731f1f"
  },
  typography_email: {
    fontSize: "11px",
    color: "black"
  },
  typography_cargo: {
    fontSize: "11px",
    color: "#black"
  }
}));

export default IngresosCards;
