import React from "react";
import { Grid } from "@material-ui/core";
import Imagen from "./components/imagen";
import Video from "./components/video";
import CasosExisto from "./components/casosExito";
import Integrantes from "./components/integrantes";
import Presentacion from "./components/presentacion";
import "../../App.css";

export default function ComiteCuidado() {

	return (
		<Grid container spacing={5} style={{ paddingBottom: 50 }}>
			{/* SECCIÓN IMAGEN */}
			<Grid item xs={12}>
				<Imagen />
			</Grid>

			{/* SECCIÓN VIDEO */}
			<Grid item xs={12}>
				<Video />
			</Grid>

			{/* SECCIÓN CASOS DE ÉXITO */}
			<Grid item xs={12}>
				<CasosExisto />
			</Grid>

			{/* SECCIÓN INTEGRANTES */}
			<Grid item xs={12}>
				<Integrantes />
			</Grid>

			{/* SECCIÓN PRESENTACIÓN */}
			<Grid item xs={12}>
				<Presentacion />
			</Grid>
		</Grid>
	);
}