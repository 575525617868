import React from "react";
import { Parallax } from "react-parallax";
import { Paper, Typography } from "@material-ui/core";

export default function Imagen() {

	return (
		<Paper style={{ borderRadius: 20 }}>
			<Parallax
				bgImage={IMAGEN}
				strength={50}
				style={{ borderRadius: "20px", zIndex: 2, width: "100%" }}
			>
				<div
					variant="box"
					style={{ marginRight: "1rem", marginBottom: "1rem", height: "auto", overflow: "hidden", height: "17rem" }}
				>
					<div style={{ position: "absolute", top: "20px", marginLeft: "2rem", zIndex: 10 }}>
						<Typography variant="h3" style={{ color: "white" }}>
							<strong>COMITÉ DE CUIDADO</strong>
						</Typography>
					</div>
				</div>
			</Parallax>
		</Paper>
	);
}

const IMAGEN = "https://storage.googleapis.com/mi-cyd/ComiteCuidado/banner_comite_cuidado.png";