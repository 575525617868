import { BACKEND } from "../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener los cumpleaños de la semana.
 */
export async function ObtenerIngresos(gerencia = null) {
  try {
    let url = `${BACKEND}/contrataciones`;
    let response = await Axios.get(url);
    let ingresos = response.data;
    let ingresosFilter = [];
    if (gerencia) {
      ingresosFilter = ingresos.filter(d => d.codigo_proyecto?.includes(gerencia.sigla));
    }
    return ingresosFilter;
  } catch (error) {
    console.error("error");
    throw error;
  }
}