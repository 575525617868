import React, { useCallback, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import Autoplay from "embla-carousel-autoplay";
import { Typography } from "@material-ui/core";
import "./style.css";

/**
 * Método encargado de retornar un componente 
 * @param {*} imagenes Colección de imagenes.
 * @param {*} adicional Colección de adicionales en formato HTML.
 * @param {*} autoplay FLAG para incluir funcionalidad Autoplay.
 * @param {*} show_buttons FLAG para mostrar botones anterior y siguiente. 
 * @param {*} show_indicator FLAG para mostrar indicador de páginas. 
 * @returns Component.
 */
export default function Carousel(props) {
	const {
		imagenes,
		adicional,
		autoplay = true,
		show_buttons = false,
		show_indicator = false,
	} = props;

	const [Page, SetPage] = useState(0);
	const [emblaRef, emblaApi] = useEmblaCarousel({
		draggable: true,
		loop: true,
	}, autoplay ? [Autoplay({ stopOnInteraction: false })] : []);

	if (emblaApi) {
		//Función asignada en caso de evento "scroll".
		emblaApi.on("scroll", () => {
			let currentPage = emblaApi.selectedScrollSnap();
			SetPage(currentPage);
		});
	}

	/**
	 * Método encargado de pasar a la imagen anterior.
	 */
	const scrollPrev = useCallback(() => {
		if (emblaApi) {
			emblaApi.scrollPrev();
		}
	}, [emblaApi]);

	/**
	 * Método encargado de pasar a la imagen siguiente.
	 */
	const scrollNext = useCallback(() => {
		if (emblaApi) {
			emblaApi.scrollNext();
		}
	}, [emblaApi]);

	/**
	 * Método encargado de abrir una nueva pestaña con la URL de redirección.
	 * @param {*} imagen Datos de la imagen.
	 */
	const handleRedirect = (imagen) => {
		if (imagen.redirect) {
			window.open(imagen.redirect, "_blank");
		}
	}

	//Si no hay imágenes, no se muestra el componente.
	if (!imagenes || Array.from(imagenes).length === 0) {
		return null;
	}

	return (
		<div className="embla" ref={emblaRef} key={`banner_${Array.from(imagenes).length}_images`}>
			{/* CAROUSEL */}
			<div className="embla__container">
				{Array.from(imagenes).map((adjunto, index) => (
					<div className="embla__slide" onClick={() => handleRedirect(adjunto)} key={`banner_adjunto_${index}`}>
						{/* SI EL ADJUNTO ES IMAGEN */}
						{ACCEPTED_FILES_IMAGE.includes(adjunto.mime_type) && (
							<img src={adjunto.url} alt="Sin adjunto" width="100%" />
						)}
						{/* SI EL ADJUNTO ES VIDEO */}
						{ACCEPTED_FILES_VIDEOS.includes(adjunto.mime_type) && (
							<video style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} width="90%" controls>
								<source src={adjunto.url} type="video/mp4" />
								<source src={adjunto.url} type="video/ogg" />
							</video>
						)}

						{/* SI EL CONTENIDO ADICIONAL ES TIPO HTML */}
						{adicional && adicional[index] && (
							<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 50, marginRight: 50, marginBottom: 20 }}>
								<div dangerouslySetInnerHTML={{ __html: adicional[index] }} />
							</Typography>
						)}
					</div>
				))}
			</div>

			{/* BOTONES ANTERIOR Y SIGUIENTE */}
			{show_buttons && imagenes && Array.from(imagenes).length > 1 && (
				<div style={{ position: "relative" }}>
					<PrevButton onClick={scrollPrev} />
					<NextButton onClick={scrollNext} />
				</div>
			)}

			{/* INDICADORES DE LAS PÁGINAS */}
			{show_indicator && (
				<div className="embla__dots">
					{Array.from(imagenes).map((_, index) => (
						<DotButton
							key={index}
							selected={index === Page}
							onClick={() => emblaApi?.scrollTo(index)}
						/>
					))}
				</div>
			)}
		</div>
	);
}

/**
 * Método encargado de generar el botón indicador de páginas.
 * @param {*} selected FLAG para indicar la página actual.
 * @param {*} onClick Método encargado de ir a una página.
 * @returns Component.
 */
const DotButton = (props) => {
	const { selected, onClick } = props;
	return (
		<button
			className={"embla__dot".concat(selected ? " embla__dot--selected" : "")}
			type="button"
			onClick={onClick}
		/>
	)
}

/**
 * Método encargado de generar el botón para ir a la página anterior.
 * @param {*} onClick Método encargado de cambiar la página.
 * @returns Component.
 */
const PrevButton = (props) => {
	const { onClick } = props;
	return (
		<button
			className="embla__button embla__button--prev"
			onClick={onClick}
		>
			<svg className="embla__button__svg" viewBox="137.718 -1.001 366.563 644">
				<path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
			</svg>
		</button>
	)
}

/**
 * Método encargado de generar el botón para ir a la página siguiente.
 * @param {*} onClick Método encargado de cambiar la página.
 * @returns Component.
 */
const NextButton = (props) => {
	const { onClick } = props;
	return (
		<button
			className="embla__button embla__button--next"
			onClick={onClick}
		>
			<svg className="embla__button__svg" viewBox="0 0 238.003 238.003">
				<path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
			</svg>
		</button>
	)
}

/**
 * @link http://www.iana.org/assignments/media-types/media-types.xhtml#image
 */
export const ACCEPTED_FILES_IMAGE = "image/png, image/jpg, image/jpeg";

/**
 * @link https://www.iana.org/assignments/media-types/media-types.xhtml#video
 */
export const ACCEPTED_FILES_VIDEOS = "video/mp4, video/x-m4v, video/webm";