import React from "react";
import Carousel from "react-material-ui-carousel";
import { Button, Backdrop, Fade, Modal, Paper, Box } from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  acordion_: {
    textAlign: "left",
    marginBottom: "1rem",
    paddingLeft: "3rem",
    borderRadius: "20px",
    // padding: theme.spacing(2, 4, 3),
  },

  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",
    // cursor: "grab",
  },

  logrosypremios: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    height: "auto",
  },

  gridList: {
    width: "100%",
    height: "100%",
  },
}));

function Example(props) {
  const classes = useStyles();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);
  const [pptRuta, setPptRuta] = React.useState(null);
  var items = [
    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/1.jpg",
    },

    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/2.jpg",
    },

    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/3.jpg",
    },

    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/4.jpg",
    },

    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/5.jpg",
    },

    {
      imagen:
        "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/6.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/7.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/8.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/9.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/10.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/11.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/12.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/13.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/14.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/15.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/16.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/17.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/18.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/19.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/20.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/21.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/22.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/23.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/24.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/25.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/26.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/27.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/28.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/29.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/30.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/31.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/32.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/33.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/34.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/35.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/36.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/37.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/38.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/39.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/40.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/41.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/42.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/43.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/44.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/45.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/46.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/47.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/48.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/49.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/50.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/51.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/52.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/53.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/54.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/55.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/56.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/57.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/58.jpg",
    },

    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/59.jpg",
      
    },
    {
      imagen:
      "https://storage.googleapis.com/mi-cyd/nosotros/Galeria_Fotografica/60.jpg",
    },
  ];

  const handleCloseModal = () => {
    setOpenModalPpt(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const abrirImagen = (rutaImagen) => {
    setPptRuta(rutaImagen);
    setOpenModalPpt(true);
  };

  return (
    <React.Fragment>
      <Carousel
        autoPlay={false}
        indicators={false}
        navButtonsAlwaysVisible={true}
        animation="slide"
        fullHeightHover={true}
      >
        {items.map((item, i) => (
          
          <Box style={{ textAlign: "center" }}>
            <a href="#" onClick={() => abrirImagen(item.imagen)}>
              <img src={item.imagen} style={{ height: "480px" }}></img>
            </a>
          </Box>
        ))}
      </Carousel>
      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModalPpt}
        overflow="scroll"
        onClose={handleCloseModalPpt}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPpt} onClick={handleCloseModal}>
          <Box
            // className={classes.paper}
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              // bgcolor:"lightgreen",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={pptRuta}
              style={{ marginTop:"20px", height: "-webkit-fill-available", border: "groove", borderRadius: "2%"  }}
              onClick={handleCloseModal}
            ></img>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

export default Example;
