import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TEMPLATES,FormikInitialValues } from "./utils";
import { NoticiaAgregarContext } from "./index";

export default function Paso1(props) {
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);

	/**
	 * Método encargado de seleccionar un Template y pasar al siguiente paso.
	 * @param {*} nombreTemplate Nombre del template.
	 */
	const handleClick = (nombreTemplate) => {
		NoticiaAgregarCTX.formik.setValues({
			...NoticiaAgregarCTX.formik.values,
			contenido: [],
			template: nombreTemplate,
		});
		NoticiaAgregarCTX.handleNext();
	}

	return (
		<Grid container spacing={3}>
			{Object.values(TEMPLATES).map((template, index) => (
				<Grid item xs={4} container onClick={() => handleClick(template.nombre)} key={`grid_item_${index}`}>
					<Grid item xs={12}>
						<Typography variant="h4" align="center">{template.nombre.replace("_", " ")}</Typography>
					</Grid>
					<Grid item xs={12}>
						<img src={template.url} width="100%" />
					</Grid>
				</Grid>
			))}
		</Grid>
	);
}