import React, { useState } from "react";
import { AppBar, Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import ContratosPublicados from "./tabContratosPublicados";
import AgregarContrato from "./tabAgregarContrato";

function Page(props) {
  const [value, setValue] = useState(0);
  const { handle_publicar } =
    props;

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange}>
          <Tab label="Contratos Publicados" />
          <Tab label="Agregar Contrato" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        {value === 0 ? <ContratosPublicados /> : null}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {value === 1 ? <AgregarContrato handle_publicar={handle_publicar}  /> : null}
      </TabPanel>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default Page;