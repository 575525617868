import React, { Fragment, useContext } from "react";
import clsx from "clsx";
import { Backdrop, Box, Button, CircularProgress, Grid, Step, StepConnector, StepLabel, Stepper, Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Check } from "@material-ui/icons";
import Paso1 from "./paso1";
import Paso2 from "./paso2";
import Paso3 from "./paso3";
import { NoticiaAgregarContext } from "./index";
import "moment/locale/es";
import Paso4 from "./paso4";
import { STEPS } from "./utils";

export default function AgregarNoticia() {
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);
	const classes = useStyles();

	/**
	 * Método encargado de mandar la noticia a revisión.
	 */
	const handleRevision = () => {
		NoticiaAgregarCTX.formik.setFieldValue("en_revision", true);
		NoticiaAgregarCTX.formik.handleSubmit();
	}

	return (
		<Fragment>
			<Grid container spacing={2} style={{ width: "100%" }}>
				{/* STEPS */}
				<Grid item xs={12}>
					<Stepper
						alternativeLabel
						activeStep={NoticiaAgregarCTX.formik.values.currentStep}
						connector={<QontoConnector />}
						style={{ borderRadius: 5 }}
					>
						{STEPS.map((label) => (
							<Step key={label}>
								<StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
				</Grid>
				{/* CONTENIDO */}
				<Grid item xs={12}>
					{/* PASO 1 */}
					{NoticiaAgregarCTX.formik.values.currentStep === 0 && (
						<Paso1 />
					)}

					{/* PASO 2 */}
					{NoticiaAgregarCTX.formik.values.currentStep === 1 && (
						<Paso2 />
					)}

					{/* PASO 3 */}
					{NoticiaAgregarCTX.formik.values.currentStep === 2 && (
						<Paso3 />
					)}

					{/* PASO FINAL */}
					{NoticiaAgregarCTX.formik.values.currentStep === 3 && (
						<Paso4 />
					)}
				</Grid>

				{/* BOTONES */}
				{NoticiaAgregarCTX.formik.values.currentStep != 0 && (
					<Grid item xs={12}>
						<Box display="flex" justifyContent="center">
							<Button onClick={NoticiaAgregarCTX.handlePrevious} disabled={NoticiaAgregarCTX.formik.values.currentStep === 0} variant="outlined" className={classes.button}>
								{"Volver"}
							</Button>
							<Button onClick={NoticiaAgregarCTX.handleNext} disabled={NoticiaAgregarCTX.formik.values.currentStep === 3} variant="contained" color="primary" className={classes.button}>
								{"Siguiente"}
							</Button>
							<Button onClick={handleRevision} style={{ display: NoticiaAgregarCTX.formik.values.currentStep !== 3 ? "none" : null }} variant="contained" color="primary" className={classes.button}>
								{"Para Revisión"}
							</Button>
							<Button onClick={NoticiaAgregarCTX.formik.handleSubmit} style={{ display: NoticiaAgregarCTX.formik.values.currentStep !== 3 ? "none" : null }} variant="contained" color="primary" className={classes.button}>
								{"Publicar"}
							</Button>
						</Box>
					</Grid>
				)}
			</Grid>
			<Backdrop open={NoticiaAgregarCTX.formik.isSubmitting} className={classes.backdrop}>
				<CircularProgress color="inherit" style={{ marginRight: 20 }} />
				<Typography>Enviando la Noticia...</Typography>
			</Backdrop>
		</Fragment>
	);
}

function QontoStepIcon(props) {
	const classes = useQontoStepIconStyles();
	const { active, completed } = props;

	return (
		<div
			className={clsx(classes.root, {
				[classes.active]: active,
			})}
		>
			{completed ? (
				<Check className={classes.completed} />
			) : (
				<div className={classes.circle} />
			)}
		</div>
	);
}

const QontoConnector = withStyles({
	alternativeLabel: {
		top: 10,
		left: "calc(-50% + 16px)",
		right: "calc(50% + 16px)",
	},
	active: {
		"& $line": {
			borderColor: "#784af4",
		},
	},
	completed: {
		"& $line": {
			borderColor: "#784af4",
		},
	},
	line: {
		borderColor: "#eaeaf0",
		borderTopWidth: 3,
		borderRadius: 1,
	},
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
	root: {
		color: "#eaeaf0",
		display: "flex",
		height: 22,
		alignItems: "center",
	},
	active: {
		color: "#784af4",
	},
	circle: {
		width: 8,
		height: 8,
		borderRadius: "50%",
		backgroundColor: "currentColor",
	},
	completed: {
		color: "#784af4",
		zIndex: 1,
		fontSize: 18,
	},
});

const appbarHeight = 18;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		minHeight: `calc(100vh - ${appbarHeight}px)`,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "25ch",
	},
	content: {
		padding: theme.spacing(8, 1, 3, 1),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		minHeight: `calc(100vh - ${appbarHeight}px)`,
		width: "100vw",
	},
	cuadro: {
		display: "flex",
		marginTop: "10px",
	},
	button: {
		margin: theme.spacing(3),
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1000,
		color: '#fff',
	},
}));