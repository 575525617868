import React, { Fragment } from "react";
import UseSWR from "swr";
import Moment from "moment";
import { Box, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { Cake as CakeIcon } from "@material-ui/icons";
import UserCard from "../../../components/cardUsuario";
import { ObtenerCumpleanos } from "../requestCumpleanos";

function CumpleanosCards(props) {
  const { data } = UseSWR("cumpleanos", (key) => ObtenerCumpleanos(), {
    revalidateOnFocus: false,
  });

  const classes = useStyles();
  let today = Moment().get("date");
  let weekday = Moment().get("isoWeekday");


  return (
    <Fragment>
      {/* TITULO E ICONO */}
      <Grid
        container
        style={{
          
          marginTop: "1rem",
          backgroundColor: "white",
          borderTopRightRadius: "20px",
          borderTopLeftRadius: "20px",
          paddingTop: 8,
          // paddingBottom:8
        }}
      >
        <Grid item xs={10} style={{ alignSelf: "center" }}>
          <Typography
            variant="h6"
            align ="center"
            style={{ fontWeight: "bold", paddingLeft:15 }}
          >
            Cumpleaños del día
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ width: "30%" }}>
          <CakeIcon style={{ fontSize: 30, color: "#731f1f" }} />
        </Grid>
        <Grid item xs={12}>
        <Divider variant="middle"/>
        </Grid>
      </Grid>

      {/* LISTADO */}
      <div className={classes.root}>
        <Typography variant="body1" className={classes.fecha}>
          {Moment().format("DD/MMM")}
        </Typography>
        {/* SIN CUMPLEAÑEROS */}
        {(!data || !data[today] || Array.from(data[today]).length === 0) && (
          <Typography className={classes.sin_cumpleañeros}>
            Sin cumpleañeros
          </Typography>
        )}
        {/* CUMPLEAÑEROS DEL DIA */}
        {data &&
          data[today] &&
          Array.from(data[today]).map((d, index) => (
            <UserCard key={`userCard-${index}`} persona={d.persona} />
          ))}

        {/* SI ES VIERNES, SE INCLUYEN CUMPLEAÑOS DEL SABADO Y DOMINGO */}
        {data && weekday === 5 && (
          <Fragment>
            {/* CUMPLEAÑOS DEL SABADO */}
            <Fragment>
              <Typography variant="caption" className={classes.fecha}>
                {Moment().add(1, "day").format("DD/MMM")}
              </Typography>
              {/* SIN CUMPLEAÑOS EL SABADO */}
              {(!data[Number(Moment().add(1, "day").format("DD"))] ||
                Array.from(data[Number(Moment().add(1, "day").format("DD"))]).length === 0) && (
                <Typography className={classes.sin_cumpleañeros}>
                  Sin cumpleañeros
                </Typography>
              )}
              {/* CUMPLEAÑEROS DEL SABADO */}
              {data[Number(Moment().add(1, "day").format("DD"))] &&
                Array.from(data[Number(Moment().add(1, "day").format("DD"))]).length > 0 &&
                Array.from(data[Number(Moment().add(1, "day").format("DD"))]).map((d, index) => (
                  <UserCard persona={d.persona} />
                ))}
            </Fragment>

            {/* CUMPLEAÑOS DEL DOMINGO */}
            <Fragment>
              <Typography variant="caption" className={classes.fecha}>
                {Moment().add(2, "day").format("DD/MMM")}
              </Typography>
              {/* SIN CUMPLEAÑEROS EL DOMINGO */}
              {(!data[Number(Moment().add(2, "day").format("DD"))] ||
                Array.from(data[Number(Moment().add(2, "day").format("DD"))]).length === 0) && (
                <Typography className={classes.sin_cumpleañeros}>
                  Sin cumpleañeros
                </Typography>
              )}
              {/* CUMPLEAÑEROS DEL DOMINGO */}
              {data[Number(Moment().add(2, "day").format("DD"))] &&
                Array.from(data[Number(Moment().add(2, "day").format("DD"))]).length > 0 &&
                Array.from(data[Number(Moment().add(2, "day").format("DD"))]).map((d, index) => (
                  <UserCard persona={d.persona} />
                ))}
            </Fragment>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "17rem",
    overflowY: "scroll",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingBottom:"20px",
  },
  fecha: {
    marginLeft: theme.spacing(2),
    color: "#731f1f",
  },
  sin_cumpleañeros: {
    marginLeft: theme.spacing(3),
    color: "#731f1f",
  },
}));

export default CumpleanosCards;
