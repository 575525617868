import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";

export default function DialogTags(props) {
	const {
		formik,
		tags,
		is_open,
		handle_close,
	} = props;

	/**
	 * Método encargado de obtener el largo del arreglo.
	 * @returns Cantidad de TAGs.
	 */
	const tagsLength = () => Array.from(tags).length;

	/**
	 * Método encargado de verificar si el los TAGs de la noticia contienen un elemento.
	 * @param {*} sigla Sigla de la gerencia.
	 * @returns TRUE: Gerencia incluida en los TAGs | FALSE: Gerencia no incluida en los TAGs.
	 */
	const tagsIncludes = (sigla) => Array.from(formik.values.tags).includes(sigla);

	/**
	 * Handler para los tags de las noticias.
	 * @param {*} event Datos del evento.
	 */
	const handleChangeTags = (event) => {
		let nombre = event.target.name;
		let checked = event.target.checked;
		let tagsLocal = Array.from(formik.values.tags);

		if (nombre === "Todo CyD") {
			if (checked) {
				tagsLocal = tags.map(t => t.sigla);
			} else {
				//Si el valor al que se cambio es FALSE, se desactiva Todo CyD.
				let todoCyDIndex = tagsLocal.findIndex(t => t === "Todo CyD");
				if (todoCyDIndex !== -1) {
					tagsLocal.splice(todoCyDIndex, 1);
				}
			}
		} else {
			if (checked) {
				tagsLocal.push(nombre);
			} else {
				let tagIndex = tagsLocal.findIndex(t => t === nombre);
				tagsLocal.splice(tagIndex, 1);

				//Si el valor al que se cambio es FALSE, se desactiva Todo CyD.
				let todoCyDIndex = tagsLocal.findIndex(t => t === "Todo CyD");
				if (todoCyDIndex !== -1) {
					tagsLocal.splice(todoCyDIndex, 1);
				}
			}
		}
		formik.setFieldValue("tags", tagsLocal);
	};

	return (
		<Dialog open={is_open}>
			<DialogTitle>Tags de la Noticia</DialogTitle>
			<DialogContent>
				<DialogContentText>Seleccione los Tags correspondientes.</DialogContentText>
				{/* CARGANDO LAS GERENCIAS */}
				{!tags && (
					<Typography>Cargando...</Typography>
				)}

				{/* NO HAY GERENCIAS */}
				{tags && tagsLength() === 0 && (
					<Typography>Sin TAGs</Typography>
				)}

				{/* COLECCIÓN DE GERENCIAS */}
				{tags && tagsLength() > 0 && (
					<Grid container spacing={0}>
						{Array.from(tags).map((tag, index) => (
							<Grid item xs={6} key={`grid_${index}`}>
								<FormControlLabel
									label={tag.nombre}
									control={(<Switch name={tag.sigla} checked={tagsIncludes(tag.sigla)} onClick={handleChangeTags} />)}
								/>
							</Grid>
						))}
					</Grid>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="contained" color="primary">
					Cerrar
				</Button>
			</DialogActions>
		</Dialog>
	);
}