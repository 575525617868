import React from "react";
import { useSnackbar } from "notistack";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Moment from "moment";
import { EliminarNoticia } from "./requestComunicados";

function DialogEliminarNoticia(props) {
	const {
		noticia,
		dialog_close,
	} = props;

	const notistack = useSnackbar();

	/**
	 * Handler para cancelar eliminación.
	 */
	const handleCancelar = () => {
		dialog_close();
	}

	/**
	 * Handler para aceptar eliminación.
	 */
	const handleAceptar = () => {
		EliminarNoticia(noticia)
			.then(response => {
				notistack.enqueueSnackbar("Noticia eliminada exitosamente.", {
					variant: "success",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			})
			.catch(error => {
				console.error(error);
				notistack.enqueueSnackbar("Error al intentar eliminar la noticia.", {
					variant: "error",
					anchorOrigin: {
						horizontal: "center",
						vertical: "bottom"
					},
					action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
				});
			})
			.finally(() => dialog_close());
	}

	return (
		<Dialog
			maxWidth="xs"
			open={Boolean(noticia)}
		>
			<DialogTitle>{"Eliminar Noticia"}</DialogTitle>
			<DialogContent dividers>
				<Typography variant="h6">{"Se debe confirmar la eliminación de la noticia."}</Typography>
				<Typography>{`Título: ${noticia ? noticia.titulo : ""}`}</Typography>
				<Typography>{`Fecha de publicación: ${noticia ? Moment(noticia.fecha_publicacion).format("DD/MM/YYYY HH:mm") : ""}`}</Typography>
				<Typography variant="overline">{"No podrá ser recuperada posteriormente."}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancelar} autoFocus variant="outlined" color="primary">
					{"Cancelar"}
				</Button>
				<Button onClick={handleAceptar} variant="contained" color="primary">
					{"Aceptar"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DialogEliminarNoticia;