import { BACKEND } from "../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener los cumpleaños de la semana.
 */
export async function ObtenerCumpleanos() {
  try {
    let url = `${BACKEND}/cumpleanos`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error("error");
    throw error;
  }
}