import { date, object, string } from "yup";

export const TEMPLATES = {
	template1: {
		nombre: "TEMPLATE_1",
		url: "https://storage.googleapis.com/mi-cyd/noticias-templates/template_noticia_1.PNG",
	},
	template2: {
		nombre: "TEMPLATE_2",
		url: "https://storage.googleapis.com/mi-cyd/noticias-templates/template_noticia_2.PNG",
	},
	template3: {
		nombre: "TEMPLATE_3",
		url: "https://storage.googleapis.com/mi-cyd/noticias-templates/template_noticia_3.PNG",
	}
};
export const STEPS = ["Seleccionar Template", "Ingresar Noticia", "Publicar", "Finalizado"];

export const URL_PATTERN = new RegExp("^(https?:\\/\\/)?" + // protocolo
	"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // nombre de dominio
	"((\\d{1,3}\\.){3}\\d{1,3}))" + // dirección IP
	"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // puerto y ruta
	"(\\?[;&a-z\\d%_.~+=-]*)?" + // parámetros de consulta
	"(\\#[-a-z\\d_]*)?$", "i"); // fragmento de referencia

/**
 * @link http://www.iana.org/assignments/media-types/media-types.xhtml#image
 */
export const ACCEPTED_FILES_IMAGE = "image/png, image/jpg, image/jpeg";

/**
 * @link https://www.iana.org/assignments/media-types/media-types.xhtml#video
 */
export const ACCEPTED_FILES_VIDEOS = "video/mp4, video/x-m4v, video/webm";

export const FormikInitialValues = {
	currentStep: 0,
	template: "",
	tags: [],

	epigrafe: "",
	titulo: "",
	bajada: "",
	fecha_publicacion: new Date(),
	contenido: [],

	cuerpos: [],
	adjuntos: [],
	botonTexto: [],
	botonURL: [],

	tipo: "NOTICIA",
	_autor_ref: "",
	en_revision: false,
}

export const FormikValidationSchema = object().shape({
	epigrafe: string()
		.optional(),
	titulo: string()
		.required("El título es requerido."),
	bajada: string()
		.optional(),
	fecha_publicacion: date()
		.optional(),
});