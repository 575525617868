import { BACKEND, BACKEND_PORTAFOLIO } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener todos los revisores de noticias.
 */
export async function ObtenerRevisores() {
  try {
    let urlRevisores = `${BACKEND}/revisores/_persona_ref.nombre/_persona_ref.apellido_paterno/_persona_ref.apellido_materno/_persona_ref.contacto/_persona_ref.gerencia_ref/_persona_ref.contratos_ref`;
    let urlGerencias = `${BACKEND_PORTAFOLIO}/gerencias`;
    let responses = await Promise.all([
      Axios.get(urlRevisores),
      Axios.get(urlGerencias),
    ]);
    let revisores = responses[0].data.data;
    let gerencias = responses[1].data;

    let personas = [];
    revisores.forEach(revisor => {
      let persona = {
        _id: revisor._id,
        persona_id: revisor._persona_ref._id,
        nombre: `${revisor._persona_ref.nombre} ${revisor._persona_ref.apellido_paterno} ${revisor._persona_ref.apellido_materno}`,
        email: revisor._persona_ref.contacto.email,
        // gerencia: gerencia ? gerencia.nombre : "---",
        // contrato: revisor._persona_ref.contratos_ref && revisor._persona_ref.contratos_ref.length > 0 ? revisor._persona_ref.contratos_ref[0].nombre : "---",
      }
      let gerencia = gerencias.find(g => g._id === revisor._persona_ref.gerencia_ref);
      persona["gerencia"] = gerencia ? gerencia.nombre : "---";
      let contrato = revisor._persona_ref.contratos_ref[0];
      persona["contrato"] = contrato ? contrato.nombre : "---";
      personas.push(persona);
    });

    return personas;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de agregar un nuevo revisor.
 * @param {*} revisor Datos del revisor.
 */
export async function AgregarRevisor(revisor) {
  try {
    let url = `${BACKEND}/revisores`;
    let response = await Axios.post(url, revisor);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un revisor.
 * @param {*} revisor Datos del revisor.
 */
export async function EliminarRevisor(revisor) {
  try {
    let url = `${BACKEND}/revisores/${revisor._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}