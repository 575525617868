import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

const RUTA = "comunicados";

/**
 * Método encargado de publicar una nueva noticia.
 * @param {*} noticia Datos de la noticia.
 * @returns Response.
 */
export async function PublicarNoticia(noticia) {
	try {
		let url = `${BACKEND}/${RUTA}`;
		let response = await Axios.post(url, noticia);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todas las noticias.
 */
export async function ObtenerNoticias(tags = []) {
	try {
		let url = `${BACKEND}/${RUTA}/recientes`;
		let response = await Axios.get(url);
		let recientes = Array.from(response.data);
		let recientesFilter = recientes.filter(r => r.tags.some(t => tags.includes(t)));
		return recientesFilter;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener la única noticia que esta pineada.
 */
export async function ObtenerNoticiaPinned() {
	try {
		let url = `${BACKEND}/${RUTA}?is_pinned_eq=true`;
		let response = await Axios.get(url);
		console.log(response.data);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener una noticia, por su ID.
 * @param {*} noticiaID ID de la noticia.
 */
export async function ObtenerNoticiaPorID(noticiaID) {
	try {
		let url = `${BACKEND}/${RUTA}/${noticiaID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una noticia.
 * @param {*} noticia Noticia con los datos actualizados.
 */
export async function ActualizarNoticia(noticia) {
	try {
		let url = `${BACKEND}/${RUTA}/${noticia._id}`;
		let response = await Axios.put(url, noticia);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}