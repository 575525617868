import axios from 'axios';
import { ADMINISTRACION_USUARIOS } from '../../constants/urls'
import { BACKEND } from "../../constants/urls";
import { auth } from '../../services/firebase';

/**
 * Obtener datos de usuario
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuarioByUid(uid, cancelToken = null) {
  return axios.get(`${ADMINISTRACION_USUARIOS}/personas/uid/${uid}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Obtener datos de usuarios
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuarios(filter, cancelToken = null) {
  return axios.get(`${ADMINISTRACION_USUARIOS}/personas/`,
    {
      params: filter,
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Obtener datos de usuario
 * @param {*} id 
 * @param {*} cancelToken token para cancelacion
 */
export function getUsuario(id, cancelToken = null) {
  return axios.get(`${ADMINISTRACION_USUARIOS}/personas/${id}`,
    {
      cancelToken: cancelToken
    }).then(res => res.data);
}

/**
 * Obtener datos de usuario
 * @param {*} uid filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function obtenerDatosSesionUsuario(uid, cancelToken = null) {
  return auth.currentUser.getIdToken(true).then((idToken) => {
    return axios.post(`${BACKEND}/usuarios-plataforma/sesion`,
      {},
      {
        headers: {
          "authorization": `Bearer ${idToken}`
        },
        cancelToken: cancelToken
      }
    );
  })
    .then(res => res.data ? res.data.data : null);
}