import React from "react";

/**
 * Método encargado de generar el componente con información del mercado y proyectos.
 * @returns Component.
 */
export default function MercadosProyectos(props) {
	return (
		<img src={MERCADO_CYD_URL} alt="mapa" width="100%" />
	);
}

/****************************************************************************************
 * 																	CONSTANTES
 ***************************************************************************************/

const MERCADO_CYD_URL = "https://storage.googleapis.com/mi-cyd/nosotros/mercados_cyd_2024.png";