import React, { Fragment, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Grid, Paper, TextField } from "@material-ui/core";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Template2(props) {
	const {
		formik,
	} = props;

	const [RichTexts, SetRichTexts] = useState([]);
	const [ButtonTexts, SetButtonTexts] = useState([]);
	const [ButtonURLs, SetButtonURLs] = useState([]);

	useEffect(() => {
		if (formik.values.cuerpos) {
			let richTexts = Array.from(formik.values.cuerpos).map(cuerpo => {
				const contentBlock = htmlToDraft(cuerpo || "");
				if (contentBlock) {
					const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
					const editorState = EditorState.createWithContent(contentState);
					return editorState;
				} else {
					return "";
				}
			});
			SetRichTexts(richTexts);
		}
		if (formik.values.botonTexto) {
			SetButtonTexts(formik.values.botonTexto);
		}
		if (formik.values.botonURL) {
			SetButtonURLs(formik.values.botonURL);
		}
	}, []);

	useEffect(() => {
		if (RichTexts) {
			formik.setFieldValue("cuerpos", RichTexts.map(r => draftToHtml(convertToRaw(r.getCurrentContent()))));
		}
	}, [RichTexts]);

	/**
	 * Método encargado de actualizar el state de RichTexts.
	 * @param {*} richText RichText actualizado.
	 * @param {*} index Posición del cuerpo.
	 */
	const handleChangeRichText = (richText, index) => {
		RichTexts[index] = richText;
		SetRichTexts(Array.from(RichTexts));
	}

	/**
	 * Método encargado de actualizar el texto de un botón.
	 * @param {*} text Nuevo texto.
	 * @param {*} index Posición del texto.
	 */
	const handleChangeButtonText = (text, index) => {
		ButtonTexts[index] = text;
		formik.setFieldValue("botonTexto", ButtonTexts);
	}

	/**
	 * Método encargado de actualizar la URL de un botón.
	 * @param {*} url Nueva URL.
	 * @param {*} index Posición del texto.
	 */
	const handleChangeButtonURL = (url, index) => {
		ButtonURLs[index] = url;
		formik.setFieldValue("botonURL", ButtonURLs);
	}

	return (
		<Fragment>
			{formik.values.adjuntos && Array.from(formik.values.adjuntos).map((adjunto, index) => (
				<Paper key={`paper_${index}`} style={{ borderRadius: 20, margin: 10, padding: 10 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<img
								alt={adjunto.nombre ? adjunto.nombre : ""}
								src={adjunto.url ? adjunto.url : ""}
								width="100%"
							/>
						</Grid>

						<Grid item xs={4}>
							<TextField
								name="button_text"
								label="Texto del botón"
								value={ButtonTexts ? ButtonTexts[index] : ""}
								onChange={(event) => handleChangeButtonText(event.target.value, index)}
								variant="outlined"
								size="small"
								fullWidth
							/>
						</Grid>

						<Grid item xs={8}>
							<TextField
								name="button_url"
								label="URL del botón"
								value={ButtonURLs ? ButtonURLs[index] : ""}
								onChange={(event) => handleChangeButtonURL(event.target.value, index)}
								variant="outlined"
								size="small"
								fullWidth
							/>
						</Grid>

						<Grid item xs={12}>
							<Editor
								toolbar={{
									options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"],
									inline: {
										options: ["bold", "italic", "underline", "strikethrough"]
									}
								}}
								editorState={RichTexts ? RichTexts[index] : null}
								onEditorStateChange={(editorState) => handleChangeRichText(editorState, index)}
							/>
						</Grid>
					</Grid>
				</Paper>
			))}
		</Fragment>
	);
}