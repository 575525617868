import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de obtener todas las imágenes de banner.
 * @returns Colección de imágenes.
 */
export async function Obtener() {
	try {
		let url = `${BACKEND}/${RUTA_IMAGENES_BANNER}/nombre/link_externo/adjunto/_autor_ref.nombre/_autor_ref.apellido_paterno/_autor_ref.apellido_materno/_autor_ref.contacto/is_visible/fecha_creacion`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener las imágenes de banner visibles.
 * @returns Colección de imágenes.
 */
export async function ObtenerVisibles() {
	try {
		let url = `${BACKEND}/${RUTA_IMAGENES_BANNER}?is_visible_eq=${true}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar una nueva imagen de banner.
 * @param {*} imagen Datos de la imagen.
 * @returns Datos de la nueva imagen.
 */
export async function Agregar(imagen) {
	try {
		let url = `${BACKEND}/${RUTA_IMAGENES_BANNER}`;
		let response = await Axios.post(url, imagen);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar una imagen de banner.
 * @param {*} imagenID ID de la imagen.
 * @param {*} data Datos actualizados de la imagen.
 * @returns Datos de la imagen actualizada.
 */
export async function Actualizar(imagenID, data) {
	try {
		let url = `${BACKEND}/${RUTA_IMAGENES_BANNER}/${imagenID}`;
		let response = await Axios.put(url, data);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar una imagen.
 * @param {*} imagenID ID de la imagen.
 * @returns Respuesta.
 */
export async function Eliminar(imagenID) {
	try {
		let url = `${BACKEND}/${RUTA_IMAGENES_BANNER}/${imagenID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de imágenes de banner en Mi CyD.
 */
const RUTA_IMAGENES_BANNER = "imagenes-banner";