import axios from "axios";

/**
 * Método encargado de subir un archivo al Google Cloud Storage.
 * @param {*} file Archivo que será subido.
 * @param {*} filename Nombre del archivo.
 * @returns Datos del archivo subido.
 */
export async function Upload(file, filename) {
	try {
		var formData = new FormData();
		formData.append("folder", "noticias");
		formData.append("bucket", "mi-cyd");
		formData.append("name", filename);
		formData.append("file", file);
		let response = await axios.post("https://storage.cydocs.cl/upload", formData);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}