import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import MaterialTable from "../../../components/materialTable";
import DialogAgregar from "./_dialog_agregar";
import DialogEliminar from "./_dialog_eliminar";
import { TIPO_AGREGAR, TIPO_ELIMINAR } from "./utils";

export default function Page(props) {
	const {
		imagenes,
		mutate,
		mostrar_ocultar,
	} = props;

	const [Open, SetOpen] = useState({ tipo: "", data: null });

	useEffect(() => {
		if (imagenes) {
			for (const imagen of imagenes) {
				let autor = imagen._autor_ref;
				let nombreCompleto = `${autor.nombre} ${autor.apellido_paterno} ${autor.apellido_materno}`;
				imagen["_autor_ref"]["nombre_completo"] = nombreCompleto;
			}
		}
	}, [imagenes]);

	/**
	 * Método encargado de asignar datos al state Open.
	 * @param {*} tipo Tipo de acción AGREGAR o ELIMINAR.
	 * @param {*} row Datos de la fila.
	 */
	const ActionDialog = (tipo, row = null) => SetOpen({ tipo: tipo, data: row });

	/**
	 * Método encargado de cerrar los dialogs y recargar los datos.
	 */
	const handleClose = () => {
		SetOpen({ tipo: "", data: null });
		mutate();
	}

	const columns = [
		{
			title: "Nombre",
			field: "nombre",
		}, {
			title: "Link externo",
			field: "link_externo",
			width: "150px",
			render: (row) => row.link_externo ? <a href={row.link_externo} target="_blank">Link externo</a> : null,
		}, {
			title: "URL",
			field: "adjunto.url",
			width: "150px",
			render: (row) => <a href={row.adjunto.url} target="_blank">Imagen</a>
		}, {
			title: "Subido por",
			field: "_autor_ref.nombre_completo",
			render: (row) => <Tooltip title={row._autor_ref.contacto.email}><label>{row._autor_ref.nombre_completo}</label></Tooltip>
		}
	];

	const actions = [
		(row) => ({
			icon: row.is_visible ? "visibility" : "visibility_off",
			tooltip: row.is_visible ? "Visible (click para ocultar)" : "Oculta (click para mostrar)",
			onClick: mostrar_ocultar,
		}), {
			icon: "delete",
			tooltip: "Eliminar",
			onClick: (event, row) => ActionDialog(TIPO_ELIMINAR, row),
		}, {
			icon: "add",
			tooltip: "Agregar",
			onClick: (event, row) => ActionDialog(TIPO_AGREGAR),
			isFreeAction: true,
		}
	];

	return (
		<div style={{ marginBottom: 10 }}>
			{/* LISTADO DE IMÁGENES */}
			<MaterialTable
				title="Imágenes Banner"
				is_loading={false}
				data={imagenes}
				columns={columns}
				actions={actions}
			/>

			{/* DIALOG PARA AGREGAR */}
			{Open.tipo === TIPO_AGREGAR && (
				<DialogAgregar
					handle_close={handleClose}
				/>
			)}

			{/* DIALOG PARA ELIMINAR */}
			{Open.tipo === TIPO_ELIMINAR && (
				<DialogEliminar
					imagen={Open.data}
					handle_close={handleClose}
				/>
			)}
		</div>
	);
}