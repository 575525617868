import Axios from "axios";
import { BACKEND_PORTAFOLIO } from "../../../constants/urls";

/**
 * Método encargado de obtener todas las gerencias desde API Portafolio.
 * @returns Colección de gerencias.
 */
export async function Obtener() {
	try {
		let url = `${BACKEND_PORTAFOLIO}/${RUTA_GERENCIAS}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de gerencias en Portafolio.
 */
const RUTA_GERENCIAS = "gerencias";