import React from "react";

/**
 * Método encargado de generar el componente con el propósito de CyD.
 * @returns Component.
 */
export default function NuestroProposito(props) {
	return (
		<img src={PROPOSITO_VALORES} width={"100%"} />
	);
}

/****************************************************************************************
 * 																	CONSTANTES
 ***************************************************************************************/

const PROPOSITO_VALORES = "https://storage.googleapis.com/mi-cyd/nosotros/SER%20CyD%20Mi%20CyD.png";