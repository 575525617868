import React, { Fragment, useEffect, useState } from "react";
import Moment from "moment";
import { motion } from "framer-motion";
import { isMobile } from "react-device-detect";
import { AppBar, Backdrop, Box, Button, Grid, MobileStepper, Paper, Tab, Tabs } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import NoticiaPinned from "./components/noticiaPinner";
import NoticiaMore from "./components/noticiaMore";
import Cumpleanios from "./components/cumpleanos";
import NuevosIngresos from "./components/IngresosRecientes";
import EventosCalendario from "./components/eventosCalendario";
import ListaCorreos from "./components/listaCorreos";
import TemasInteres from "./components/temasInteres";
import ContratosAdjudicados from "./components/contratosAdjudicados";
import ListadoIconos from "./components/listadoIconos";
import BannerImages from "./components/bannerImages";
import TabPanelComponent from "../../components/tab_panel";
import "moment/locale/es";

export default function Page(props) {
	const {
		open,
		activeStep,
		handleClose,
		handleNext,
		handleBack,
		calendario,
		correos,
	} = props;

	const classes = useStyles();
	const maxSteps = 2;
	const [showNoticia, setShowNoticia] = useState(false);

	const [TabIndex, SetTabIndex] = useState(1);

	useEffect(() => {
		window.scrollTo(0, 0);
		const delay = async () => {
			setTimeout(() => {
				setShowNoticia(true);
			}, 1800);
		};
		delay();
	}, []);

	return (
    <Fragment>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{ paddingLeft: 10, paddingRight: 10 }}
        spacing={3}
      >
        {/* COLUMNA NOTICIAS */}
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          container
          style={{ paddingLeft: 24 }}
        >
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 9 }}>
              <BannerImages />
            </Grid>
            <Grid item xs={12}>
              {/* NOTICIA PINNED */}
              <motion.div
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: {
                    scale: 0.8,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      delay: 0.5,
                    },
                  },
                }}
              >
                <NoticiaPinned />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              {/* NOTICIA MÁS RECIENTE Y MÁS NOTICIAS */}
              <motion.div
                initial="hidden"
                animate="visible"
                variants={{
                  hidden: {
                    scale: 0.8,
                    opacity: 0,
                  },
                  visible: {
                    scale: 1,
                    opacity: 1,
                    transition: {
                      delay: 0.9,
                    },
                  },
                }}
              >
                <NoticiaMore />
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
        {/* COLUMNA CUMPLEAÑOS/EVENTOS */}
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          style={{
            paddingLeft: isMobile ? 15 : 20,
            paddingTop: isMobile ? 20 : 20,
            paddingBottom: 0,
            paddingRight: isMobile ? 20 : 10,
            style: "block",
          }}
        >
          <Box>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  scale: 0.3,
                  opacity: 0,
                  x: 100,
                },
                visible: {
                  scale: 1,
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.3,
                  },
                },
              }}
            >
              <AppBar
                position="static"
                style={{
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                  width: "100%",
                  backgroundColor: "white",
                  boxShadow: "none",
                }}
              >
                <Tabs
                  value={TabIndex}
                  onChange={(event, value) => SetTabIndex(value)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    value={1}
                    label={
                      <Box style={{ alignSelf: "center" }}>
                        <img
                          src={`https://ssl.gstatic.com/calendar/images/dynamiclogo_2020q4/calendar_${Moment().format(
                            "D"
                          )}_2x.png`}
                          width="30"
                          style={{ paddingTop: "5px" }}
                        />
                      </Box>
                    }
                    style={{ fontSize: "0.8rem" }}
                    wrapped
                    classes={{ root: classes.tab }}
                  />
                  <Tab
                    value={2}
                    label={
                      <Box style={{ alignSelf: "center" }}>
                        <img
                          src={
                            "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1200px-Gmail_icon_%282020%29.svg.png"
                          }
                          width="30"
                          style={{ paddingTop: "5px" }}
                        />
                      </Box>
                    }
                    style={{ fontSize: "0.8rem" }}
                    wrapped
                    classes={{ root: classes.tab }}
                  />
                </Tabs>
              </AppBar>

              <TabPanelComponent
                value={TabIndex}
                index={1}
                style={{
                  backgroundColor: "white",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <EventosCalendario calendario={calendario} />
              </TabPanelComponent>

              <TabPanelComponent
                value={TabIndex}
                index={2}
                style={{
                  backgroundColor: "white",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                }}
              >
                <ListaCorreos correos={correos} />
              </TabPanelComponent>
            </motion.div>

            {/* LISTADO ÍCONOS */}
            <ListadoIconos open={open} />

            {/* CUMPLEAÑOS */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  scale: 0.3,
                  opacity: 0,
                  x: 100,
                },
                visible: {
                  scale: 1,
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.6,
                  },
                },
              }}
            >
              <Cumpleanios />
            </motion.div>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  scale: 0.3,
                  opacity: 0,
                  x: 100,
                },
                visible: {
                  scale: 1,
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.9,
                  },
                },
              }}
            >
              <NuevosIngresos />
            </motion.div>
            {/* CONTRATOS ADJUDICADOS */}
            <motion.div
              initial="hidden"
              animate="visible"
              variants={{
                hidden: {
                  scale: 0.3,
                  opacity: 0,
                  x: 100,
                },
                visible: {
                  scale: 1,
                  opacity: 1,
                  x: 0,
                  transition: {
                    delay: 0.9,
                  },
                },
              }}
            >
              <ContratosAdjudicados />
            </motion.div>

            {/* <motion.div
							initial="hidden"
							animate="visible"
							variants={{
								hidden: {
									scale: 0.3,
									opacity: 0,
									x: 100,
								},
								visible: {
									scale: 1,
									opacity: 1,
									x: 0,
									transition: {
										delay: 0.9,
									},
								},
							}}
						>
							<TemasInteres />
						</motion.div> */}
          </Box>
        </Grid>
      </Grid>

      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Paper elevation={3} style={{ borderRadius: 18 }}>
          <Grid style={{ padding: 18 }}>
            <iframe
              src="https://drive.google.com/file/d/1mKdV9_J3mlCk2s-gzN_GfdJ6NvmPRORO/preview"
              width="854"
              height="480"
            ></iframe>
            <MobileStepper
              steps={3}
              position="static"
              variant="text"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Siguiente
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                  Anterior
                </Button>
              }
            />
          </Grid>
        </Paper>
      </Backdrop>
    </Fragment>
  );
}

const appbarHeight = 18;

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	content: {
		padding: theme.spacing(8, 1, 3, 1),
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		minHeight: `calc(100vh - ${appbarHeight}px)`,
		// width: "100vw",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 4,
		color: "#fff",
	},
	tab: {
		minWidth: 100, // a number of your choice
		width: 100, // a number of your choice
	},
}));