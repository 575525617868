import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de obtener un rol, por su ID.
 * @param {*} rolID ID del rol.
 * @returns Datos del rol.
 */
export async function ObtenerPorID(rolID) {
	try {
		let url = `${BACKEND}/${RUTA_ROLES}/${rolID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todos los roles.
 * @returns Colección de roles.
 */
export async function Obtener() {
	try {
		let url = `${BACKEND}/${RUTA_ROLES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un nuevo rol.
 * @param {*} rol Datos del rol.
 * @returns Datos del nuevo rol.
 */
export async function Agregar(rol) {
	try {
		let url = `${BACKEND}/${RUTA_ROLES}`;
		let response = await Axios.post(url, rol);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un rol.
 * @param {*} rol Datos actualizados del rol.
 * @returns Datos del rol actualizado.
 */
export async function Actualizar(rol) {
	try {
		let url = `${BACKEND}/${RUTA_ROLES}/${rol._id}`;
		let response = await Axios.put(url, rol);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un rol.
 * @param {*} rolID ID del rol.
 * @returns Respuesta.
 */
export async function Eliminar(rolID) {
	try {
		let url = `${BACKEND}/${RUTA_ROLES}/${rolID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de roles en Mi CyD.
 */
const RUTA_ROLES = "roles";