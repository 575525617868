import React, { Fragment } from "react";
import UseSWR from "swr";
import Moment from "moment";
import "moment/locale/es";
import _ from "lodash";

import {
  Avatar,
  Badge,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  withStyles,
} from "@material-ui/core";
import { Box, Grid, makeStyles, Typography, Link } from "@material-ui/core";
import { Cake as CakeIcon } from "@material-ui/icons";
import EventNoteIcon from "@material-ui/icons/EventNote";
import UserCard from "../../../components/cardUsuario";
import { ObtenerCumpleanos } from "../requestCumpleanos";
import { isMobile } from "react-device-detect";
import Loading from '../../../components/google_loading';

function CorreosCards(props) {
  const { correos } = props;
  const [errorCarga, setErrorCarga] = React.useState(false);

  React.useEffect(() => {
    console.log(correos);
  }, []);

  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

  //   console.log(calendario && calendario);

  setTimeout(function() { setErrorCarga(true) }, 8000); // <-- time in milliseconds

  console.log(correos);
  const classes = useStyles();

  if (correos) {
    return (
      <Fragment>
        {/* TITULO E ICONO */}

       
        {/* LISTADO */}
        <div className={classes.root}>
          {correos && correos.length === 0 && (
            <Grid item md={12} style={{ textAlign: "center" }}>
              <label>No tiene correos</label>
            </Grid>
          )}
          {correos &&
            correos.map((correo, index_1) => (
              <Grid container key={index_1}>
                <Grid item md={12} style={{ paddingLeft: "5px" }}>
                  <div key={`correo_${index_1}`}>
                    <Card
                      key={`correoCard-${index_1}`}
                      elevation={0}
                    //   variant="outlined"
                      className={classes.card}
                    >
                      <CardActionArea
                        href={`https://mail.google.com/mail/u/0/#inbox/${correo.id}`}
                        target="_blank"
                      >
                        <CardContent
                          style={{ padding: "5px", paddingLeft: 15 }}
                        >
                          
                          <Typography className={classes.typography_nombre}>
                            {correo.asunto}
                          </Typography>
                          <Typography className={classes.typography_email}>
                            <strong>
                              {" "}
                              {Moment(correo.fechaEnvio).format(
                                "DD/MM/YY HH:mm"
                              )}
                            </strong>
                          </Typography>
                          <Typography className={classes.typography_cargo}>
                            {correo.remitente}
                          </Typography>

                          {/* <Typography className={classes.typography_cargo}>
                            Autor: {evento.creator.displayName}
                          </Typography>
                          <Typography className={classes.typography_cargo}>
                            Correo: {evento.creator.email}
                          </Typography> */}

                          {/* <Link>{evento.htmlLink}</Link> */}
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                </Grid>
              </Grid>
            ))}
        </div>
      </Fragment>
    );
  } else {
    if(errorCarga){
      return (
        <Typography align="center" style={{color:"#731f1f", padding:"10px"}}> 
        <img src="https://storage.googleapis.com/mi-cyd/home/oops2.png" width="25%" /><br/>
        Para visualizar tu calendario y correo, elimina las <strong>cookies</strong> y el <strong>caché</strong> de tu navegador. <br/>
        ¡Es muy fácil! Copia y pega este link en Google Chrome:<br/>
          <strong>chrome://settings/clearBrowserData</strong><br/>
          marca las casillas correspondientes y presiona el botón.
  
  
        </Typography>
        );
    }else{
      return <Loading />;
    }
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "20rem",
    overflowY: "scroll",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingBottom: "20px",
  },
  card: {
    margin: "5px",
  },
  fecha: {
    marginLeft: theme.spacing(2),
    color: "#731f1f",
  },
  sin_cumpleañeros: {
    marginLeft: theme.spacing(3),
    color: "#731f1f",
  },

  avatar_size: {
    width: isMobile ? "100%" : "40px",
    height: "100%",
  },
  typography_nombre: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#731f1f",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  typography_email: {
    fontSize: "11px",
    color: "black",
  },
  typography_cargo: {
    fontSize: "12px",
    color: "black",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export default CorreosCards;
