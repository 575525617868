import Axios from "axios";
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de obtener todas las noticias, filtradas por TAGs del usuario.
 */
export async function ObtenerNoticias(tags = []) {
  try {
    let url = `${BACKEND}/comunicados/recientes`;
    let response = await Axios.get(url);
    let recientes = Array.from(response.data);
    let recientesFilter = recientes.filter(r => r.tags.some(t => tags.includes(t)));
    return recientesFilter;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener la única noticia que esta pineada.
 */
export async function ObtenerNoticiaPinned() {
  try {
    let url = `${BACKEND}/comunicados?is_pinned_eq=true`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}