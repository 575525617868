import React, { useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { Actualizar, Obtener } from "../../../services/requests/mi_cyd/imagenes_banner";
import { MainContext } from "../../../App";

export default function Index(props) {
	const ImagenesBannerSWR = useSWR("imagenes_banner", () => Obtener());
	const { ShowSnackbar } = useContext(MainContext);

	useEffect(() => {
		if (ImagenesBannerSWR.error) {
			ShowSnackbar("Error al intentar obtener las imágenes de banner", ImagenesBannerSWR.error);
		}
	}, [ImagenesBannerSWR.error]);

	/**
	 * Método encargado de actualizar la visibilidad de una imagen.
	 * @param {*} event Evento.
	 * @param {*} row Datos de la imagen.
	 */
	const MostrarOcultar = async (event, row) => {
		try {
			//Nnuevo estado que será asignado.
			let isVisible = !row.is_visible;
			//Se muestra u oculta la imagen.
			await Actualizar(row._id, { is_visible: isVisible });
			ShowSnackbar("Acción realizada exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar mostrar/ocultar la imagen.", error);
		} finally {
			ImagenesBannerSWR.mutate();
		}
	}

	return (
		<Page
			imagenes={ImagenesBannerSWR.data ? ImagenesBannerSWR.data.data : []}
			mutate={ImagenesBannerSWR.mutate}
			mostrar_ocultar={MostrarOcultar}
		/>
	);
}