import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { object, string, date, mixed } from "yup";
import { getCroppedImg } from "../../../components/CropImagen/canvasUtils";
import CropArea from "../../../components/CropImagen/index";

export default function Page(props) {
  const { handle_publicar, img_foto, set_img_foto, temp_foto, set_temp_foto } =
    props;

  const formik = useFormik({
    initialValues: {
      fecha_publicacion: null,
      titulo: "",
      tema_interes: "",
      imagen: null,
      link_externo: "",
    },
    validationSchema: object().shape({
      fecha_publicacion: date().nullable().required("La fecha es requerida."),
      titulo: string()
        .min(3, "El título debe tener al menos ${min} caracteres.")
        .max(100, "El título debe tener a lo más ${max} caracteres.")
        .required("El título es requerido."),
      tema_interes: string()
        .min(3, "El tema de interés debe tener al menos ${min} caracteres.")
        .max(350, "El tema de interés debe tener a lo más ${max} caracteres.")
        .required("El tema de interés es requerido."),
      imagen: mixed()
        // .required("La imagen es requerida."),
        .optional(),
      link_externo: string()
        .min(3, "El link externo debe tener al menos ${min} caracteres.")
        .max(300, "El link externo debe tener a lo más ${max} caracteres.")
        .url("El link externo debe tener la estructura de URL correcta.")
        .required("El link externo es requerido."),
    }),
    onSubmit: (values, helper) => {
      try {
        handle_publicar(values);
      } catch (error) {
        console.error(error);
      } finally {
        handleCancel();
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm({
      values: {
        fecha_publicacion: null,
        titulo: "",
        tema_interes: "",
        imagen: null,
        link_externo: "",
      },
    });
  };

  const showCroppedImageDos = async (imageSrc, croppedAreaPixels) => {
    let img = await getCroppedImg(imageSrc, croppedAreaPixels);
    set_img_foto(img);
    set_temp_foto(URL.createObjectURL(img));
  };

  return (
    <React.Fragment>
      <Paper style={{ padding: "30px", margin: "20px" }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "2rem",
                color: "#731f1f",
                textAlign: "center",
              }}
            >
              Ingresar Tema de Interés
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
            

          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Fecha:</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <DateTimePicker
                  label="Fecha de Publicación"
                  value={formik.values.fecha_publicacion}
                  onChange={(date) =>
                    formik.setFieldValue("fecha_publicacion", date)
                  }
                  error={Boolean(formik.errors.fecha_publicacion)}
                  helperText={formik.errors.fecha_publicacion}
                  okLabel="Aceptar"
                  cancelLabel="Cancelar"
                  inputVariant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Título:</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name="titulo"
                  label="Título"
                  value={formik.values.titulo}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.titulo)}
                  helperText={formik.errors.titulo}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Contenido:</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name="tema_interes"
                  label="Tema de interés"
                  value={formik.values.tema_interes}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.tema_interes)}
                  helperText={formik.errors.tema_interes}
                  variant="outlined"
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={2}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Link:</strong>
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name="link_externo"
                  label="Link Externo"
                  value={formik.values.link_externo}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.link_externo)}
                  helperText={formik.errors.link_externo}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          
            </Grid>
            <Grid item xs={6} style={{ alignSelf: "center", textAlign: "center", padding:"30px"}}>
            
            <CropArea showCroppedImageDos={showCroppedImageDos} />
          
            </Grid>
          </Grid>
          

          <Grid
            item
            xs={12}
            component={Box}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              onClick={formik.submitForm}
              variant="contained"
              color="primary"
            >
              Agregar
            </Button>
            <Button onClick={handleCancel} color="primary">
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
