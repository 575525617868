import React, { Fragment, useEffect, useState } from "react";
import useSWR from "swr";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, LinearProgress, TextField } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "../../../components/materialTable";
import { ObtenerRevisores, AgregarRevisor, EliminarRevisor } from "./requestRevisores";
import { ObtenerPersonas } from "./requestPersonas";
import { Autocomplete } from "@material-ui/lab";

function TabRoles(props) {
  const { data, error, mutate } = useSWR("revisores", (key) => ObtenerRevisores(), { revalidateOnFocus: false });
  const { data: dataPersonas, error: errorPersonas } = useSWR("personas", (key) => ObtenerPersonas(), { revalidateOnFocus: false });
  const [Registro, SetRegistro] = useState();
  const [IsOpenAgregar, SetIsOpenAgregar] = useState(false);
  const [IsOpenEliminar, SetIsOpenEliminar] = useState(false);

  const history = useHistory();
  const notistack = useSnackbar();

  useEffect(() => {
    if (error) {
      notistack.enqueueSnackbar("Error al intentar obtener los perfiles.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }
    if (errorPersonas) {
      notistack.enqueueSnackbar("Error al intentar obtener las personas.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    }
  }, [error, errorPersonas]);

  const handleAgregar = () => {
    SetIsOpenAgregar(true);
  }

  const handleEliminar = (revisor) => {
    SetRegistro(revisor);
    SetIsOpenEliminar(true);
  }

  const handleAceptar = async (tipoDialog) => {
    try {
      switch (tipoDialog) {
        case "agregar":
          await AgregarRevisor({ _persona_ref: Registro._id, email: Registro.contacto.email });
          break;
        case "eliminar":
          await EliminarRevisor(Registro);
          break;
        default:
          throw new Error("Acción no especificada.")
      }
      notistack.enqueueSnackbar("Acción realizada exitosamente.", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    } catch (error) {
      console.error(error);
      notistack.enqueueSnackbar("Error al intentar realizar la acción.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
      });
    } finally {
      mutate();
      SetRegistro();
      SetIsOpenAgregar(false);
      SetIsOpenEliminar(false);
    }
  }

  const handleCancelar = () => {
    SetRegistro();
    SetIsOpenAgregar(false);
    SetIsOpenEliminar(false);
  }

  let columns = [
    {
      title: "Nombre",
      field: "nombre",
      defaultSort: 'asc'
    }, {
      title: "Correo",
      field: "email",
    }, {
      title: "Gerencia",
      field: "gerencia",
    }, {
      title: "Contrato",
      field: "contrato",
    }
  ];

  let actions = [
    {
      tooltip: "Agregar",
      icon: "add",
      onClick: () => handleAgregar(),
      isFreeAction: true,
    }, {
      tooltip: "Eliminar",
      icon: "delete",
      onClick: (event, row) => handleEliminar(row),
    }
  ];

  return (
    <Fragment>
      {/* LISTADO DE REVISORES */}
      <MaterialTable
        title="Revisores"
        is_loading={!data}
        data={data}
        columns={columns}
        actions={actions}
      />

      {/* DIALOG PARA GREGAR UN NUEVO REVISOR */}
      <Dialog open={IsOpenAgregar} fullWidth>
        <DialogTitle>{"Agregar Revisor"}</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>Formulario con información del Revisor.</DialogContentText>
          <Grid container spacing={2}>
            {/* CARGANDO LISTADO DE PERSONAS */}
            {!dataPersonas && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}

            {/* LISTADO DE PERSONAS CARGADO */}
            {dataPersonas && (
              <Grid item xs={12}>
                <Autocomplete
                  options={dataPersonas ? dataPersonas.filter(p => !data.map(p => p._id).includes(p._id)) : []}
                  getOptionLabel={(persona) => `${persona.nombre_completo} (${persona.contacto.email})`}
                  onChange={(event, value) => { SetRegistro(value) }}
                  noOptionsText={"Sin personas"}
                  renderInput={(params) => (
                    <TextField
                      label="Revisor"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelar} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleAceptar("agregar")} variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>

      {/* DIALOG PARA ELIMINAR UN REVISOR */}
      <Dialog open={IsOpenEliminar} fullWidth>
        <DialogTitle>Eliminar Revisor</DialogTitle>
        <DialogContent dividers>
          <DialogContentText>La persona será eliminada de la lista de revisores.</DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12}>{`Nombre: ${Registro ? Registro.nombre : ""}`}</Grid>
            <Grid item xs={12}>{`Correo: ${Registro ? Registro.email : ""}`}</Grid>
            <Grid item xs={12}>{`Gerencia: ${Registro ? Registro.gerencia : ""}`}</Grid>
            <Grid item xs={12}>{`Contrato: ${Registro ? Registro.contrato : ""}`}</Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelar} variant="outlined" color="primary">
            Cancelar
          </Button>
          <Button onClick={() => handleAceptar("eliminar")} variant="contained" color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default TabRoles;