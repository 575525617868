import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "../../components/materialTable";
import DialogEliminarContrato from "./dialogEliminarContrato";
import { ObtenerContratosAdjudicados } from "./requestContratosAdjudicados";

function TabPublicadas(props) {
  const { data, isValidating, mutate } = useSWR("publicadas", (key) => ObtenerContratosAdjudicados(), { revalidateOnFocus: false });
  const [NoticiaSelect, SetNoticiaSelect] = useState();
  const history = useHistory();
  const notistack = useSnackbar();



  /**
   * Handler para abrir el popup para confirmar eliminación de noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleDialogOpen = (event, row) => {
    SetNoticiaSelect(row);
  }

  /**
   * Handler para cerrar el popup para confirmar eliminación de noticia.
   */
  const handleDialogClose = () => {
    SetNoticiaSelect();
    mutate();
  }

  let columns = [
    {
      title: "Nombre",
      field: "nombre",
    }, {
      title: "Cliente",
      field: "cliente"
    }, {
      title: "Gerencia",
      field: "gerencia",
    }, {
      title: "Fecha Adjudicación",
      field: "fecha",
      defaultSort: "desc",
      render: (row) => Moment(row.fecha).format("DD/MM/YYYY"),
    }
  ];

  let actions = [
     {
      icon: "delete",
      tooltip: "Eliminar",
      onClick: handleDialogOpen,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Contratos Adjudicados Publicados"
        is_loading={!data || isValidating}
        data={data ? data.data : []}
        columns={columns}
        actions={actions}

      />
      <DialogEliminarContrato
        noticia={NoticiaSelect}
        dialog_close={handleDialogClose}
      />
    </Fragment>
  );
}

export default TabPublicadas;