export const LANDING = '/';
export const HOME = "/"

export const NOTICIA_AGREGAR = '/noticia-agregar';
export const NOTICIA_TEMA_INTERES = "/noticia-tema-interes";
export const NOTICIA_VER = "/noticia-ver/:id";
export const NOTICIA_EDITAR = "/noticia-editar/:id";
export const MIS_NOTICIAS = "/mis-noticias";
export const IMAGENES_BANNER = "/imagenes-banner";

export const BIENVENIDA_CYD = "/bienvenida-cyd";
export const NOSOTROS = "/nosotros";
export const PDFPRUEBA = "/pdfprueba"
export const BENEFICIOS = "/beneficios"
export const FORMACIONDESARROLLO = "/formacion-y-desarrollo"
export const NOTICIAS = "/noticias"
export const COMITECUIDADO = "/comite-de-cuidado"
export const RSE = "/rse"
export const GERENTES = "/gerentes"
export const CONTRATOS_ADJUDICADOS = "/contratos-adjudicados"
// Noticia de prueba
export const NOTICIA_1 = '/noticia-1'
// export const HOME = "/"
// Noticia de prueba

export const AJUSTES_CONFIGURACION = "/ajustes-configuracion";
export const AJUSTES_ADMINISTRACION = "/ajustes-administracion";

export const ACCESS_ERROR = '/accessError';