import React, { Fragment } from "react";
import UseSWR from "swr";
import Moment from "moment";
import "moment/locale/es";
import _ from "lodash";

import {
  Avatar,
  Badge,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  withStyles,
} from "@material-ui/core";
import { Box, Grid, makeStyles, Typography, Link } from "@material-ui/core";
import { Cake as CakeIcon } from "@material-ui/icons";
import EventNoteIcon from "@material-ui/icons/EventNote";
import UserCard from "../../../components/cardUsuario";
import { ObtenerCumpleanos } from "../requestCumpleanos";
import { isMobile } from "react-device-detect";

import Loading from "../../../components/google_loading";

function EventosCards(props) {
  const { calendario } = props;

  const [Dato, SetDato] = React.useState();
  const [errorCarga, setErrorCarga] = React.useState(false);
  const [primerDia, setPrimerDia] = React.useState();

  React.useEffect(() => {
    if (calendario) {
      let dato = funcionx();
      SetDato(dato);
      console.log(dato);
    }
  }, [calendario]);

  const { data } = UseSWR("cumpleanos", (key) => ObtenerCumpleanos(), {
    revalidateOnFocus: false,
  });

  const SmallAvatar = withStyles((theme) => ({
    root: {
      width: 22,
      height: 22,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }))(Avatar);

  const funcionx = () => {
    const diaEventoF = (item) => Moment(item.start.dateTime).format("DD-MM");    

    let newArrayAPI = Array.from(calendario).map((d, index) => {
      return {
        ...d,
        diaEvento: Moment(d.start.dateTime).format("D"),
      };
    }); 

    const resultadoOrden = _(newArrayAPI)
      .groupBy(diaEventoF)
      .mapValues((items) =>
        _.map(items, function (o) {
          return { ...o };
        })
      )
      .value();
    return resultadoOrden;
  };

  setTimeout(function() { setErrorCarga(true) }, 8000); // <-- time in milliseconds

  const classes = useStyles();
  let today = Moment().get("date");
  let weekday = Moment().get("isoWeekday");

  if (calendario) {
    return (
      <Fragment>
        {/* TITULO E ICONO */}

        {/* LISTADO */}
        <div className={classes.root}>
          {calendario && calendario.length === 0 && (
            <Grid item md={12} style={{ textAlign: "center" }}>
              <label>No hay Próximos Eventos</label>
            </Grid>
          )}
          {Dato &&
            Object.keys(Dato).map((day, index_1) => (
              <Grid container key={index_1}>
                <Grid
                  item
                  md={2}
                  style={{ paddingLeft: "0.4rem", paddingTop: "0.5rem" }}
                >
                  <Badge
                    overlap="circular"
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    badgeContent={
                      <SmallAvatar
                        style={{
                          fontSize: "10px",
                          backgroundColor: "#731f1f",
                        }}
                      >
                        {Dato[day].map(
                          (dia_1, index_3) =>
                            index_3 == 0 &&
                            Moment(dia_1.start.dateTime)
                              .locale("es")
                              .format("dd")
                        )}
                        {/* {Moment(Dato[index_1]).locale("es").format("dd")} */}
                      </SmallAvatar>
                    }
                  >
                    <Avatar
                      style={{
                        width: "45px",
                        height: "45px",
                        color: "black",
                        backgroundColor: "#eeeeee",
                      }}
                    >
                      <strong>{`${day.substr(0,2)}`}</strong>
                    </Avatar>
                  </Badge>
                  {/* <img src={`https://ui-avatars.com/api/?size=40&background=E7E7E7&color=000000&rounded=true&bold=true&name=${day}`}/> */}
                  {/* <label>{`Día ${day}`}</label> */}
                </Grid>
                <Grid item md={10} style={{ paddingLeft: "5px" }}>
                  <div key={`eventosDia_${index_1}`}>
                    {Dato[day].map((evento, index_2) => (
                      <Card
                        key={`eventoCard-${index_2}`}
                        elevation={0}
                        //   variant="outlined"
                        className={classes.card}
                      >
                        <CardActionArea
                          href={
                            evento.hangoutLink
                              ? evento.hangoutLink
                              : evento.htmlLink
                          }
                          target="_blank"
                        >
                          <CardContent
                            style={{ padding: "5px", paddingLeft: 15 }}
                          >
                            <Typography className={classes.typography_nombre}>
                              {evento.summary}
                            </Typography>
                            <Grid container>
                              <Grid item xs={9}>
                                <Typography
                                  className={classes.typography_email}
                                >
                                  <strong>
                                    {" "}
                                    {Moment(evento.start.dateTime).format(
                                      "HH:mm"
                                    )}
                                    -
                                    {Moment(evento.end.dateTime).format(
                                      "HH:mm"
                                    )}
                                  </strong>
                                </Typography>
                              </Grid>
                              <Grid item xs={3} style={{ textAlign: "right" }}>
                                {evento.hangoutLink && (
                                  <img
                                    src="https://fonts.gstatic.com/s/i/productlogos/meet_2020q4/v1/web-96dp/logo_meet_2020q4_color_2x_web_96dp.png"
                                    height="15px"
                                  />
                                )}
                              </Grid>
                            </Grid>

                            {/* <Typography className={classes.typography_cargo}>
                                Autor: {evento.creator.displayName}
                              </Typography>
                              <Typography className={classes.typography_cargo}>
                                Correo: {evento.creator.email}
                              </Typography> */}

                            {/* <Link>{evento.htmlLink}</Link> */}
                          </CardContent>
                        </CardActionArea>
                      </Card>
                    ))}
                  </div>
                </Grid>
              </Grid>
            ))}
        </div>
      </Fragment>
    );
  } else {
    if(errorCarga){
      return (
        <Typography align="center" style={{color:"#731f1f", padding:"10px"}}> 
        <img src="https://storage.googleapis.com/mi-cyd/home/oops2.png" width="25%" /><br/>
        Para visualizar tu calendario y correo, elimina las <strong>cookies</strong> y el <strong>caché</strong> de tu navegador. <br/>
        ¡Es muy fácil! Copia y pega este link en Google Chrome:<br/>
          <strong>chrome://settings/clearBrowserData</strong><br/>
          marca las casillas correspondientes y presiona el botón.
  
  
        </Typography>
        );
    }else{
      return <Loading />;
    }

    
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "20rem",
    overflowY: "scroll",
    backgroundColor: "#FFFFFF",
    borderBottomLeftRadius: "20px",
    borderBottomRightRadius: "20px",
    paddingBottom: "20px",
  },
  card: {
    margin: "5px",
  },
  fecha: {
    marginLeft: theme.spacing(2),
    color: "#731f1f",
  },
  sin_cumpleañeros: {
    marginLeft: theme.spacing(3),
    color: "#731f1f",
  },

  avatar_size: {
    width: isMobile ? "100%" : "40px",
    height: "100%",
  },
  typography_nombre: {
    fontWeight: "bold",
    color: "#731f1f",
  },
  typography_email: {
    fontSize: "14px",
    color: "black",
  },
  typography_cargo: {
    fontSize: "11px",
    color: "#black",
  },
}));
export default EventosCards;
