import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

export default function Video() {

	return (
		<Paper style={{ borderRadius: 20, padding: 30 }}>
			<Box display="flex" justifyContent="center">
				<img src={LOGO_COMITE_CUIDADO} height={80} style={{ marginTop: 20, marginBottom: 20 }} />
			</Box>

			<Box display="flex" justifyContent="center">
				<Typography variant="h6" align="center" style={{ fontWeight: "bold", marginLeft: 180, marginRight: 180 }}>
					Equipo que se encarga de implementar y coordinar acciones que contribuyan a la superación
					de necesidades físicas, emocionales y espirituales de los Colaboradores de CyD.
				</Typography>
			</Box>

			<Box display="flex" justifyContent="center">
				<video controls style={{ width: "50%", border: "1px solid #000", display: "block", marginTop: 50, marginBottom: 20, marginLeft: "auto", marginRight: "auto" }}>
					<source src={VIDEO_COMITE_CUIDADO} type="video/mp4" />
					<source src={VIDEO_COMITE_CUIDADO} type="video/ogg" />
				</video>
			</Box>
		</Paper>
	);
}

const LOGO_COMITE_CUIDADO = "https://storage.googleapis.com/mi-cyd/ComiteCuidado/Logo%20Comit%C3%A9%20de%20Cuidado%20CyD.png";
const VIDEO_COMITE_CUIDADO = "https://storage.googleapis.com/mi-cyd/ComiteCuidado/Video%20Comit%C3%A9%20de%20Cuidado.mp4";