import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";

export default function CasosExito() {

	return (
		<Paper style={{ borderRadius: 20, padding: 30 }}>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				{/* TÍTULO */}
				<Grid item xs={12}>
					<Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
						Algunos casos que hemos apoyado
					</Typography>
				</Grid>
				{/* IMAGEN */}
				<Grid item xs={12}>
					<Box display="flex" justifyContent="center">
						<img src={CASOS_EXITO} height={400} />
					</Box>
				</Grid>
			</Grid>
			{/* TÍTULO */}
		</Paper>
	);
}

const CASOS_EXITO = "https://storage.googleapis.com/mi-cyd/ComiteCuidado/Casos%20Comit%C3%A9%20de%20Cuidado.png";