import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { object, string, date, array } from "yup";
import Moment from "moment";
import Page from "./page";
import * as ComunicadosRequests from "../../../services/requests/mi_cyd/comunicados";
import * as GerenciasRequests from "../../../services/requests/portafolio/gerencias";
import useSWR from "swr";
import { MainContext } from "../../../App";

export default function Index(props) {
	const [Tags, SetTags] = useState([]);
	const { id: noticiaID } = useParams();
	const history = useHistory();
	const MainCTX = useContext(MainContext);
	const NoticiaSWR = useSWR("noticia", (key) => ComunicadosRequests.ObtenerNoticiaPorID(noticiaID));
	const GerenciasSWR = useSWR("gerencias", (key) => GerenciasRequests.Obtener());

	useEffect(() => {
		if (GerenciasSWR.data && NoticiaSWR.data) {
			let tags = Array.from(GerenciasSWR.data).map(g => ({
				nombre: g.nombre,
				sigla: g.sigla,
				nombre_sigla: `${g.sigla} ${g.nombre}`,
				checked: false,
			}));
			tags.unshift({
				nombre: "Todo CyD",
				sigla: "Todo CyD",
				nombre_sigla: "Todo CyD",
				checked: false,
			});
			SetTags(tags);
		}
	}, [GerenciasSWR.data, NoticiaSWR.data]);

	const initials = {
		_id: NoticiaSWR.data ? NoticiaSWR.data._id : null,
		epigrafe: NoticiaSWR.data ? NoticiaSWR.data.epigrafe : "",
		titulo: NoticiaSWR.data ? NoticiaSWR.data.titulo : "",
		bajada: NoticiaSWR.data ? NoticiaSWR.data.bajada : "",
		adjuntos: NoticiaSWR.data ? NoticiaSWR.data.adjuntos : [],
		cuerpos: NoticiaSWR.data ? NoticiaSWR.data.cuerpos : [],
		rich_texts: [],
		botonTexto: NoticiaSWR.data ? NoticiaSWR.data.botonTexto : [],
		botonURL: NoticiaSWR.data ? NoticiaSWR.data.botonURL : [],
		tags: NoticiaSWR.data ? NoticiaSWR.data.tags : [],
		fecha_publicacion: NoticiaSWR.data ? Moment(NoticiaSWR.data.fecha_publicacion) : Moment(),
		template: NoticiaSWR.data ? NoticiaSWR.data.template : "",
	}

	const validator = object().shape({
		epigrafe: string()
			.min(3, "El epígrafe debe tener al menos ${min} caracteres.")
			.max(200, "El epígrafe debe tener a lo más ${max} caracteres.")
			.optional(),
		titulo: string()
			.min(3, "El título debe tener al menos ${min} caracteres.")
			.max(200, "EL título debe tener a lo más ${max} caracteres.")
			.required("El título es requerido."),
		bajada: string()
			.min(3, "$La bajada debe tener al menos ${min} caracteres.")
			.max(200, "La bajada debe tener a lo más ${max} caracteres.")
			.optional(),
		adjuntos: array()
			.of(object())
			.optional(),
		cuerpos: array()
			.of(string()
				.min(3, "$El cuerpo debe tener al menos ${min} caracteres.")
				.max(10000, "$El cuerpo debe tener a lo más ${max} caracteres.")
				.required("El cuerpo es requerido.")
			)
			.required("El cuerpo es requerido."),
		tags: array()
			.of(string())
			.min(1, "Los Tags deben tener al menos ${min} elemento.")
			.required("Los Tags son requeridos."),
		fecha_publicacion: date()
			.required("La fecha de publicación es requerida."),
	});

	const formik = useFormik({
		initialValues: initials,
		validationSchema: validator,
		onSubmit: async (values, helper) => {
			try {
				if (Moment().isSameOrAfter(values.fecha_publicacion)) {
					//Si la fecha de publicación es anterior a la fecha actual, se actualiza la fecha de publicación.
					values.fecha_publicacion = Moment();
				}
				await ComunicadosRequests.ActualizarNoticia(values);
				MainCTX.ShowSnackbar("Noticia actualizada exitosamente.");
			} catch (error) {
				MainCTX.ShowSnackbar("Error al intentar actualizar la noticia.", error);
			} finally {
				helper.resetForm();
				NoticiaSWR.mutate();
				history.push("../mis-noticias");
			}
		},
		enableReinitialize: true,
	});

	return (
		<Page
			formik={formik}
			tags={Tags}
			handle_cancelar={() => history.push("../mis-noticias")}
		/>
	);
}