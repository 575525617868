import React, { useEffect, useState } from "react";
import Page from "./page";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { ObtenerNoticiaPorID } from "../../../services/requests/mi_cyd/comunicados";

export default function VerNoticia() {
	const { id: noticiaID } = useParams();
	const NoticiaSWR = useSWR("noticia", (key) => ObtenerNoticiaPorID(noticiaID));

	return (
		<Page
			noticia={NoticiaSWR.data}
			is_loading={NoticiaSWR.isValidating}
		/>
	);
}
