import React from "react";

/**
 * Método encargado de generar el componente con el propósito de CyD.
 * @returns Component.
 */
export default function OficinasCyD(props) {
  return <img src={IMAGEN_OFICINAS_CYD} width={"100%"} />;
}

/****************************************************************************************
 * 																	CONSTANTES
 ***************************************************************************************/

const IMAGEN_OFICINAS_CYD =
  "https://storage.googleapis.com/mi-cyd/nosotros/OficinasCyD.png";
