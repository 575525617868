import React, { useContext, useEffect } from "react";
import useSWR from "swr";
import Carousel from "../../../components/carousel";
import { ObtenerVisibles } from "../../../services/requests/mi_cyd/imagenes_banner";
import { MainContext } from "../../../App";

export default function BannerImages(props) {
	const ImagenesBannerSWR = useSWR("imagenes_banner_home", (key) => ObtenerVisibles());
	const { ShowSnackbar } = useContext(MainContext);

	useEffect(() => {
		if (ImagenesBannerSWR.error) {
			ShowSnackbar("Error al intentar obtener las imágenes del banner", ImagenesBannerSWR.error);
		}
	}, [ImagenesBannerSWR.error]);

	/**
	 * Método encargado de obtener las imágenes y formaterarlas para el componente.
	 * @returns Colección de imágenes.
	 */
	const Imagenes = () => {
		if (!ImagenesBannerSWR || !ImagenesBannerSWR.data) {
			return [];
		}
		let imagenesURLs = Array.from(ImagenesBannerSWR.data.data).map(d => ({
			mime_type: d.adjunto.mime_type,
			url: d.adjunto.url,
			redirect: d.link_externo,
		}));
		return imagenesURLs;
	}

	return (
		<Carousel
			imagenes={Imagenes()}
			show_buttons={true}
			show_indicator={true}
		/>
	);
}