import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de obtener un usuario plataforma, por su ID.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 * @returns Datos del usuario plataforma.
 */
export async function ObtenerPorID(usuarioPlataformaID) {
	try {
		let url = `${BACKEND}/${RUTA_USUARIOS_PLATAFORMA}/${usuarioPlataformaID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todos los usuarios plataforma.
 * @returns Colección de usuarios plataforma.
 */
export async function Obtener() {
	try {
		let url = `${BACKEND}/${RUTA_USUARIOS_PLATAFORMA}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un nuevo usuario plataforma.
 * @param {*} usuarioPlataforma Datos del usuario plataforma.
 * @returns Datos del nuevo usuario plataforma.
 */
export async function Agregar(usuarioPlataforma) {
	try {
		let url = `${BACKEND}/${RUTA_USUARIOS_PLATAFORMA}`;
		let response = await Axios.post(url, usuarioPlataforma);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un usuario plataforma.
 * @param {*} usuarioPlataforma Datos actualizados del usuario plataforma.
 * @returns Datos del usuario plataforma actualizado.
 */
export async function Actualizar(usuarioPlataforma) {
	try {
		let url = `${BACKEND}/${RUTA_USUARIOS_PLATAFORMA}/${usuarioPlataforma._id}`;
		let response = await Axios.put(url, usuarioPlataforma);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un usuario plataforma.
 * @param {*} usuarioPlataformaID ID del usuario plataforma.
 */
export async function Eliminar(usuarioPlataformaID) {
	try {
		let url = `${BACKEND}/${RUTA_USUARIOS_PLATAFORMA}/${usuarioPlataformaID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de usuarios plataforma en Mi CyD.
 */
const RUTA_USUARIOS_PLATAFORMA = "usuarios-plataforma";