import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import DisplayPDF from "../../../components/displayPDF";
import {
  ANALYTICS,
  AddAnalytics,
  POLITICAS_CORPORATIVAS,
  POLITICAS_CORPORATIVAS_PERU,
  RedirectURL,
} from "../utils";

/**
 * Método encargado de generar el componente para mostrar las políticas corporaticas.
 * @param {*} usuario Datos del usuario logeado.
 * @param {*} expanded FLAG para mostrar el accordion abierto o cerrado.
 * @param {*} handle_change Método encargado de abrir o cerrar el accordion.
 * @returns Component.
 */
export default function PoliticasCorporativas(props) {
  const { usuario, expanded, handle_change } = props;
  const [selectedCountry, setSelectedCountry] = useState("CHILE");

  const handleCountryChange = (country) => {
    setSelectedCountry(country);
  };

  return (
    <Accordion
      id="politicascorporativas"
      expanded={expanded === "panel_politicas"}
      onChange={handle_change("panel_politicas")}
      style={{ borderRadius: 25 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore style={{ color: "#731f1f" }} />}
      >
        <Typography
          variant="h5"
          style={{ flexBasis: "100%", color: "#731f1f", fontWeight: "bold" }}
        >
          Políticas Corporativas
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Paper
          id="politicas"
          elevation={5}
          variant="outlined"
          style={{
            marginTop: "1rem",
            textAlign: "-webkit-center",
            paddingTop: "1rem",
            marginBottom: "1rem",
            borderRadius: "20px",
            width: "100%",
          }}
        >
          <div
            style={{
              marginBottom: "1rem",
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <Button
              variant={selectedCountry === "CHILE" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleCountryChange("CHILE")}
            >
              Políticas Corporativas Chile
            </Button>
            <Button
              variant={selectedCountry === "PERU" ? "contained" : "outlined"}
              color="primary"
              onClick={() => handleCountryChange("PERU")}
            >
              Políticas Corporativas Perú
            </Button>
          </div>
          <Grid
            container
            justifyContent="center"
            spacing={2}
            style={{
              paddingBottom: "2rem",
              width: "100%",
              position: "relative",
            }}
          >
            {DOCUMENTOS[selectedCountry].map((documento) =>
              GridComponent(documento, usuario)
            )}
          </Grid>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
}

/**
 * Método encargado de generar un componente reutilizable para las políticas corporativas.
 * @param {*} politica_corporativa Datos de la política corporativa.
 * @param {*} analytics Datos de la métrica en Analytics.
 * @param {*} usuario Datos del usuario logeado.
 * @returns Component.
 */
function GridComponent(politica_corporativa, analytics, usuario) {
  /**
   * Método encargado de redireccionar a una pestaña nueva y contabilizar en Analytics.
   */
  const handleClick = () => {
    RedirectURL(politica_corporativa.PDF_URL);
    AddAnalytics(analytics, usuario);
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} style={{ marginTop: "2rem" }}>
      <div style={{ width: "fit-content" }}>
        <DisplayPDF
          url={politica_corporativa.PDF_URL}
          scale={0.4}
          onClick={handleClick}
        />
      </div>
      <Typography
        onClick={handleClick}
        gutterBottom
        align="center"
        variant="h6"
        style={{ color: "#731f1f", cursor: "pointer" }}
      >
        {politica_corporativa.NAME}
      </Typography>
    </Grid>
  );
}

const DOCUMENTOS = {
  CHILE: [
    {
      NAME: "Política de Personas",
      PDF_URL: POLITICAS_CORPORATIVAS.PERSONAS.PDF_URL,
      ANALYTICS: ANALYTICS.PERSONAS,
    },
    {
      NAME: "Política de SGI",
      PDF_URL: POLITICAS_CORPORATIVAS.SGI.PDF_URL,
      ANALYTICS: ANALYTICS.SGI,
    },
    {
      NAME: "Política de Diversidad, Equidad e Inclusión",
      PDF_URL: POLITICAS_CORPORATIVAS.INCLUSION.PDF_URL,
      ANALYTICS: ANALYTICS.INCLUSION,
    },
    {
      NAME: "Política de Alcohol y Drogas",
      PDF_URL: POLITICAS_CORPORATIVAS.ALCOHOL_DROGAS.PDF_URL,
      ANALYTICS: ANALYTICS.ALCOHOL_DROGAS,
    },
    {
      NAME: "Política de Cumplimiento y Prevención de Delitos",
      PDF_URL: POLITICAS_CORPORATIVAS.DELITOS.PDF_URL,
      ANALYTICS: ANALYTICS.DELITOS,
    },
  ],
  PERU: [
    {
      NAME: "Política de Personas",
      PDF_URL: POLITICAS_CORPORATIVAS_PERU.PERSONAS.PDF_URL,
      ANALYTICS: ANALYTICS.PERSONAS,
    },
    {
      NAME: "Política de SGI",
      PDF_URL: POLITICAS_CORPORATIVAS_PERU.SGI.PDF_URL,
      ANALYTICS: ANALYTICS.SGI,
    },
    {
      NAME: "Política de Diversidad, Equidad e Inclusión",
      PDF_URL: POLITICAS_CORPORATIVAS_PERU.INCLUSION.PDF_URL,
      ANALYTICS: ANALYTICS.INCLUSION,
    },
    {
      NAME: "Política de Alcohol y Drogas",
      PDF_URL: POLITICAS_CORPORATIVAS_PERU.ALCOHOL_DROGAS.PDF_URL,
      ANALYTICS: ANALYTICS.ALCOHOL_DROGAS,
    },
    {
      NAME: "Política de Cumplimiento y Prevención de Delitos",
      PDF_URL: POLITICAS_CORPORATIVAS_PERU.DELITOS.PDF_URL,
      ANALYTICS: ANALYTICS.DELITOS,
    },
  ],
};

// // GRID CON POLÍTICA DE PERSONAS */}
//             {GridComponent(
//               POLITICAS_CORPORATIVAS.PERSONAS,
//               ANALYTICS.PERSONAS,
//               usuario
//             )}

//             {/* GRID CON POLÍTICA DE SUSTENTABILIDAD - Eliminado a solicitud de Gonzalo Rodriguez (2023-10-17)*/}
//             {/* {GridComponent(POLITICAS_CORPORATIVAS.SUSTENTABILIDAD, ANALYTICS.SUSTENTABILIDAD, usuario)} */}

//             {/* GRID CON POLÍTICA DE SGI */}
//             {GridComponent(POLITICAS_CORPORATIVAS.SGI, ANALYTICS.SGI, usuario)}

//             {/* GRID CON POLÍTICA DE INCLUSIÓN */}
//             {GridComponent(
//               POLITICAS_CORPORATIVAS.INCLUSION,
//               ANALYTICS.INCLUSION,
//               usuario
//             )}

//             {/* GRID CON POLÍTICA DE ALCOHOL Y DROGAS */}
//             {GridComponent(
//               POLITICAS_CORPORATIVAS.ALCOHOL_DROGAS,
//               ANALYTICS.ALCOHOL_DROGAS,
//               usuario
//             )}

//             {/* GRID CON POLÍTICA DE DELITOS */}
//             {GridComponent(
//               POLITICAS_CORPORATIVAS.DELITOS,
//               ANALYTICS.DELITOS,
//               usuario
//             )}

//             {/* GRID CON POLÍTICA DE REDES SOCIALES - Eliminado a solicitud de Gonzalo Rodriguez (2023-10-17) */}
//             {/* {GridComponent(POLITICAS_CORPORATIVAS.REDES_SOCIALES, ANALYTICS.REDES_SOCIALES, usuario)}
