import React from "react";

/**
 * Método encargado de retornar el icono PIN.
 * @param {*} width Ancho del icono.
 * @param {*} height Alto del icono.
 */
function PinIcon(props) {
  const {
    width = 24,
    height = 24,
  } = props;

  return (
    <svg style={{ width: width, height: height }} viewBox="0 0 24 24">
      <path fill="#888888" d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
    </svg>
  );
}

export default PinIcon;