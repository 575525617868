import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Grid, Paper, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DisplayPDF from "../../../components/displayPDF";
import { PRESENTACIONES_CORPORATIVAS } from "../utils";

export default function PresentacionCorporativa(props) {
	const {
		usuario,
		expanded,
		handle_change,
	} = props;

	return (
		<Accordion
			id="presentacioncorporativa"
			expanded={expanded === "panel_presentacion"}
			onChange={handle_change("panel_presentacion")}
			style={{ borderRadius: 25 }}
		>
			<AccordionSummary expandIcon={<ExpandMoreIcon style={{ color: "#731f1f" }} />}>
				<Typography variant="h5" style={{ flexBasis: "100%", color: "#731f1f", fontWeight: "bold" }}>
					Presentación Corporativa
				</Typography>
			</AccordionSummary>
			<AccordionDetails >
				<Paper
					id="reconocimientos"
					elevation={5}
					variant="outlined"
					style={{
						marginTop: "1rem",
						textAlign: "-webkit-center",
						paddingTop: "1rem",
						marginBottom: "1rem",
						borderRadius: "20px",
						width: "100%",
					}}
				>
					<Grid container justifyContent="center" spacing={2} style={{ paddingBottom: "2rem", width: "100%" }}>
						{GridComponent(PRESENTACIONES_CORPORATIVAS.CYD_INGENIERIA.ESPAÑOL, 0.48)}

						{GridComponent(PRESENTACIONES_CORPORATIVAS.CYD_TECNOLOGIA.ESPAÑOL, 0.64)}

						{GridComponent(PRESENTACIONES_CORPORATIVAS.CYD_INGENIERIA.INGLES, 0.48)}

						{GridComponent(PRESENTACIONES_CORPORATIVAS.CYD_TECNOLOGIA.INGLES, 0.64)}
					</Grid>
				</Paper>
			</AccordionDetails>
		</Accordion>
	);
}

/**
 * Método encargado de generar un componente reutilizable para las presentaciones corporativas.
 * @param {*} url URL del PDF.
 * @param {*} scale Escala del documento.
 * @returns Component.
 */
function GridComponent(url, scale = 0.5) {
	return (
		<Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: "2rem" }}>
			<div style={{ width: "fit-content" }}>
				<DisplayPDF url={url} scale={scale} showActions={true} />
			</div>
		</Grid>
	);
}