import React, { useContext } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { Eliminar } from "../../../services/requests/mi_cyd/imagenes_banner";
import { MainContext } from "../../../App";

export default function DialogEliminar(props) {
	const {
		imagen,
		handle_close,
	} = props;

	const { ShowSnackbar } = useContext(MainContext);

	/**
	 * Método encargado de eliminar una imagen.
	 */
	const handleEliminar = async () => {
		try {
			await Eliminar(imagen._id);
			ShowSnackbar("Imagen del banner eliminada exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar eliminar la imagen del banner.", error);
		} finally {
			handle_close();
		}
	}

	return (
		<Dialog open={true} onClose={handle_close}>
			<DialogTitle>Eliminar imagen</DialogTitle>
			<DialogContent dividers>
				<Typography>{`Se debe confirmar la eliminación de la imagen ${imagen.nombre} del banner.`}</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="outlined" color="primary">
					Cancelar
				</Button>
				<Button onClick={handleEliminar} variant="contained" color="primary">
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);
}