import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Popover } from "@material-ui/core";
import { ExpandLess, ExpandMore, OpenInNew } from "@material-ui/icons";
import * as Routes from "../../../constants/routes";
import * as Permissions from "../../../constants/permissions";
import { analytics } from "../../../services/firebase";
import "../style.css";
import "./../../../App.css";

export default function MasAccesos(props) {
  const {
    anchorAccesos,
    handleClickAccesos,
    openPopoverAccesos,
    handleCloseAccesos,
    setAnchorAccesos,
    handleClickCydocs,
    openPopoverCydocs,
    handleCloseCydocs,
    setAnchorCydocs,
    handleClickReclutamiento,
    openPopoverReclutamiento,
    handleCloseReclutamiento,
    setAnchorReclutamiento,
    anchorReclutamiento,
    idCydocs,
    idAccesos,
    idReclutamiento,
    permisos,
    claimUser,
    anchorCydocs,
  } = props;

  return (
    <Fragment>
      <a
        onClick={handleClickAccesos}
        style={{ color: "#731f1f", textDecoration: "none", cursor: "pointer" }}
      >
        <strong>+ Accesos</strong>
      </a>
      <Popover
        id={idAccesos}
        open={openPopoverAccesos}
        anchorEl={anchorAccesos}
        onClose={handleCloseAccesos}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div style={{ margin: 10 }}>
          {/* PARA GERENTES */}
          {permisos && permisos[Permissions.VER_ACCESOS_GERENTES] && (
            <Button
              component={Link}
              to={Routes.GERENTES}
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
              }}
              onClick={() => {
                analytics.logEvent(
                  "Home_link_superior_Para_Gerentes_Ingeniería",
                  {
                    usuarioCorreo: claimUser.email,
                    usuarioNombre: claimUser.name,
                    usuarioContrato: claimUser.proyecto,
                  }
                );
                setAnchorAccesos(null);
              }}
            >
              <strong>Para Gerentes</strong>
            </Button>
          )}

          <br />

          {/* NOTICIAS */}
          <Button
            component="a"
            href="https://micyd.cydocs.cl/noticias"
            target="_blank"
            style={{
              color: "#731f1f",
              textDecoration: "none",
              textTransform: "none",
            }}
            onClick={() => {
              analytics.logEvent("Home_link_superior_WebCyD", {
                usuarioCorreo: claimUser.email,
                usuarioNombre: claimUser.name,
                usuarioContrato: claimUser.proyecto,
              });
            }}
          >
            <strong>Noticias</strong>
          </Button>

          {/* CYDOCS ADMINISTRACIÓN */}
          <div>
            <Button
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
              }}
              onClick={handleClickCydocs}
            >
              <strong>CyDocs Administración</strong>
              {openPopoverCydocs ? (
                <ExpandLess color="primary" />
              ) : (
                <ExpandMore color="primary" />
              )}
            </Button>
            <Popover
              id={idCydocs}
              open={openPopoverCydocs}
              anchorEl={anchorCydocs}
              onClose={handleCloseCydocs}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography style={{ padding: "0.5rem" }}>
                {/* Portafolio */}
                <Button
                  component="a"
                  href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://portafolio.cydocs.cl/"
                  target="_blank"
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                    width: "89%",
                    justifyContent: "left",
                  }}
                  onClick={() => {
                    analytics.logEvent("Home_link_superior_Portafolio", {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    });
                    setAnchorCydocs(null);
                    setAnchorAccesos(null);
                    setAnchorReclutamiento(null);
                  }}
                >
                  <strong>Portafolio</strong>
                </Button>
                <OpenInNew
                  style={{ color: "lightgray", verticalAlign: "middle" }}
                />

                <br />
                {/* Administración CyD */}
                <Button
                  component="a"
                  href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://administracion.cydocs.cl/"
                  target="_blank"
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                    width: "89%",
                    justifyContent: "left",
                  }}
                  onClick={() => {
                    analytics.logEvent(
                      "Home_link_superior_Administración_CyD",
                      {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      }
                    );
                    setAnchorCydocs(null);
                    setAnchorAccesos(null);
                  }}
                >
                  <strong>Administración CyD</strong>
                </Button>
                <OpenInNew
                  style={{ color: "lightgray", verticalAlign: "middle" }}
                />
                <br />
                {/* Administración CDZ */}
                <Button
                  component="a"
                  href="https://script.google.com/a/macros/cydingenieria.cl/s/AKfycbzBb4gDIQl7OgbTvHgzFLL_62o6RJYmlitD3blsKgJ-oOI6ILnO/exec"
                  target="_blank"
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                    width: "89%",
                    justifyContent: "left",
                  }}
                  onClick={() => {
                    analytics.logEvent(
                      "Home_link_superior_Administracion_CDZ",
                      {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      }
                    );
                    setAnchorCydocs(null);
                    setAnchorAccesos(null);
                  }}
                >
                  <strong>Administración CDZ</strong>
                </Button>
                <OpenInNew
                  style={{ color: "lightgray", verticalAlign: "middle" }}
                />

                <br />
                {/* Sistema de adquisiciones */}
                <Button
                  component="a"
                  href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://sda.cydocs.cl/"
                  target="_blank"
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    analytics.logEvent(
                      "Home_link_superior_Sistema_de_adquisiciones",
                      {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      }
                    );
                    setAnchorCydocs(null);
                    setAnchorAccesos(null);
                  }}
                >
                  <strong>Sistema de Adquisiciones</strong>
                </Button>
                <OpenInNew
                  style={{ color: "lightgray", verticalAlign: "middle" }}
                />

                <br />
                {/* Sistema gestor de pagos */}
                <Button
                  component="a"
                  href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://gestor-pagos.cydocs.cl/"
                  target="_blank"
                  style={{
                    color: "#731f1f",
                    textDecoration: "none",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    analytics.logEvent(
                      "Home_link_superior_Sistema_gestor_de_pagos",
                      {
                        usuarioCorreo: claimUser.email,
                        usuarioNombre: claimUser.name,
                        usuarioContrato: claimUser.proyecto,
                      }
                    );
                    setAnchorCydocs(null);
                    setAnchorAccesos(null);
                  }}
                >
                  <strong>Sistema Gestor de Pagos</strong>
                </Button>
                <OpenInNew
                  style={{ color: "lightgray", verticalAlign: "middle" }}
                />
              </Typography>
            </Popover>
          </div>

          {/* ACCESO PROYECTOS */}
          <div>
            <Button
              component="a"
              href="http://201.238.203.174:5501/"
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
                width: "85%",
                justifyContent: "left",
              }}
              onClick={() => {
                analytics.logEvent("Home_link_superior_Acceso_Proyectos", {
                  usuarioCorreo: claimUser.email,
                  usuarioNombre: claimUser.name,
                  usuarioContrato: claimUser.proyecto,
                });
                setAnchorAccesos(null);
              }}
            >
              <strong>Acceso Proyectos</strong>
            </Button>
            <OpenInNew
              style={{ color: "lightgray", verticalAlign: "middle" }}
            />
          </div>

          {/* CONSULTA DIGITAL */}
          <div>
            <Button
              component="a"
              href="https://sites.google.com/cydingenieria.cl/recomendacionesdigitales"
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
                width: "85%",
                justifyContent: "left",
              }}
              onClick={() => {
                analytics.logEvent("Home_link_superior_Consulta_digital", {
                  usuarioCorreo: claimUser.email,
                  usuarioNombre: claimUser.name,
                  usuarioContrato: claimUser.proyecto,
                });
                setAnchorAccesos(null);
              }}
            >
              <strong>Consulta Digital</strong>
            </Button>
            <OpenInNew
              style={{ color: "lightgray", verticalAlign: "middle" }}
            />
          </div>

          {/* RECLUTADORES */}
          {permisos && permisos[Permissions.VER_ACCESOS_RECLUTADORES] && (
            <Fragment>
              <Button
                style={{
                  color: "#731f1f",
                  textDecoration: "none",
                  textTransform: "none",
                }}
                onClick={handleClickReclutamiento}
              >
                <strong>Reclutadores</strong>
                {openPopoverReclutamiento ? (
                  <ExpandLess color="primary" />
                ) : (
                  <ExpandMore color="primary" />
                )}
              </Button>

              <Popover
                id={idReclutamiento}
                open={openPopoverReclutamiento}
                anchorEl={anchorReclutamiento}
                onClose={handleCloseReclutamiento}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography style={{ padding: "0.5rem" }}>
                  {/* Reclutamiento */}
                  <Button
                    component="a"
                    href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://reclutamiento.cydocs.cl/"
                    target="_blank"
                    style={{
                      color: "#731f1f",
                      textDecoration: "none",
                      textTransform: "none",
                      width: "89%",
                      justifyContent: "left",
                    }}
                    onClick={() => {
                      analytics.logEvent(
                        "Home_link_superior_Reclutamiento_Gestor-de-Reclutamiento",
                        {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        }
                      );
                      setAnchorCydocs(null);
                      setAnchorAccesos(null);
                      setAnchorReclutamiento(null);
                    }}
                  >
                    <strong>Gestor de Reclutamiento</strong>
                  </Button>
                  <OpenInNew
                    style={{ color: "lightgray", verticalAlign: "middle" }}
                  />

                  <br />
                  {/* Administración CyD */}
                  <Button
                    component="a"
                    href="https://sites.google.com/cydingenieria.cl/gestordereclutamiento"
                    target="_blank"
                    style={{
                      color: "#731f1f",
                      textDecoration: "none",
                      textTransform: "none",
                      width: "89%",
                      justifyContent: "left",
                    }}
                    onClick={() => {
                      analytics.logEvent(
                        "Home_link_superior_Reclutamiento_Ayuda",
                        {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        }
                      );
                      setAnchorCydocs(null);
                      setAnchorAccesos(null);
                      setAnchorReclutamiento(null);
                    }}
                  >
                    <strong>Ayuda Gestor de Reclutamiento</strong>
                  </Button>
                  <OpenInNew
                    style={{ color: "lightgray", verticalAlign: "middle" }}
                  />
                  <br />
                  {/* Administración CDZ */}
                  <Button
                    component="a"
                    href="https://reclutamiento.cydingenieria.cl/in2/"
                    target="_blank"
                    style={{
                      color: "#731f1f",
                      textDecoration: "none",
                      textTransform: "none",
                      width: "89%",
                      justifyContent: "left",
                    }}
                    onClick={() => {
                      analytics.logEvent(
                        "Home_link_superior_Reclutamiento_Trabaja-con-nosotros",
                        {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        }
                      );
                      setAnchorCydocs(null);
                      setAnchorAccesos(null);
                      setAnchorReclutamiento(null);
                    }}
                  >
                    <strong>Trabaja con Nosotros</strong>
                  </Button>
                  <OpenInNew
                    style={{ color: "lightgray", verticalAlign: "middle" }}
                  />
                  <br />
                  {/* Registro Alumno en práctica */}
                  <Button
                    component="a"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSc2_otUm95E2UNiXdiV3ApqnHqmRRh8Zy3dV1AeskIsZWT7Sg/viewform"
                    target="_blank"
                    style={{
                      color: "#731f1f",
                      textDecoration: "none",
                      textTransform: "none",
                      width: "89%",
                      justifyContent: "left",
                    }}
                    onClick={() => {
                      analytics.logEvent(
                        "Home_link_superior_Reclutamiento_Registro-alumno-en-práctica",
                        {
                          usuarioCorreo: claimUser.email,
                          usuarioNombre: claimUser.name,
                          usuarioContrato: claimUser.proyecto,
                        }
                      );
                      setAnchorCydocs(null);
                      setAnchorAccesos(null);
                      setAnchorReclutamiento(null);
                    }}
                  >
                    <strong>Registro Alumno en Práctica</strong>
                  </Button>
                  <OpenInNew
                    style={{ color: "lightgray", verticalAlign: "middle" }}
                  />
                </Typography>
              </Popover>
            </Fragment>
          )}

          {/* GESTOR DE CONTRATACIONES */}
          {permisos && permisos[Permissions.VER_CONTRATACIONES] && (
            <div>
              <Button
                component="a"
                href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://contrataciones.cydocs.cl/"
                target="_blank"
                style={{
                  color: "#731f1f",
                  textDecoration: "none",
                  textTransform: "none",
                  width: "85%",
                  justifyContent: "left",
                }}
                onClick={() => {
                  analytics.logEvent(
                    "Home_link_superior_Gestor_Contrataciones",
                    {
                      usuarioCorreo: claimUser.email,
                      usuarioNombre: claimUser.name,
                      usuarioContrato: claimUser.proyecto,
                    }
                  );
                  setAnchorAccesos(null);
                }}
              >
                <strong>Gestor de Contrataciones</strong>
              </Button>
              <OpenInNew
                style={{ color: "lightgray", verticalAlign: "middle" }}
              />
            </div>
          )}

          {/* SISTEMA DE GESTIÓN DE ACTIVOS */}
          <div>
            <Button
              component="a"
              href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://activos.cydocs.cl/"
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
                width: "85%",
                justifyContent: "left",
              }}
              onClick={() => {
                analytics.logEvent("Home_link_superior_gestion_activos", {
                  usuarioCorreo: claimUser.email,
                  usuarioNombre: claimUser.name,
                  usuarioContrato: claimUser.proyecto,
                });
                setAnchorAccesos(null);
              }}
            >
              <strong>Sistema de Gestión de Activos</strong>
            </Button>
            <OpenInNew
              style={{ color: "lightgray", verticalAlign: "middle" }}
            />
          </div>

          {/* SISTEMA DE Gestion de Desempeño */}
          <div>
            <Button
              component="a"
              href="https://api-usuarios.cydocs.cl/login/redirectToApp?redirect-url=https://evaluacion.cydocs.cl/"
              target="_blank"
              style={{
                color: "#731f1f",
                textDecoration: "none",
                textTransform: "none",
                width: "85%",
                justifyContent: "left",
              }}
              onClick={() => {
                analytics.logEvent("Home_link_superior_gestion_desempeño", {
                  usuarioCorreo: claimUser.email,
                  usuarioNombre: claimUser.name,
                  usuarioContrato: claimUser.proyecto,
                });
                setAnchorAccesos(null);
              }}
            >
              <strong>Sistema de Gestión de Desempeño</strong>
            </Button>
            <OpenInNew
              style={{ color: "lightgray", verticalAlign: "middle" }}
            />
          </div>
        </div>
      </Popover>
    </Fragment>
  );
}
