import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import { useFormik } from "formik";
import { object, string } from "yup";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
import MaterialTable from "../../../components/materialTable";
import * as PermisosRequest from "../../../services/requests/mi_cyd/permisos";
import { MainContext } from "../../../App";

export default function TabPermisos(props) {
	const PermisosSWR = useSWR("permisos", (key) => PermisosRequest.Obtener(), { revalidateOnFocus: false });
	const { ShowSnackbar } = useContext(MainContext);

	useEffect(() => {
		if (PermisosSWR.error) {
			ShowSnackbar("Error al intentar obtener los permisos.", PermisosSWR.error);
		}
	}, [PermisosSWR.error]);

	const formik = useFormik({
		initialValues: {
			nombre: "",
			descripcion: "",
		},
		validationSchema: object().shape({
			nombre: string()
				.min(3, "El nombre debe tener al menos ${min} caracteres.")
				.max(50, "El nombre debe tener a los más ${max} caracteres.")
				.required("El nombre es requerido."),
			descripcion: string()
				.min(3, "La descripción debe tener al menos ${min} caracteres.")
				.max(50, "La descripción debe tener a los más ${max} caracteres.")
				.required("La descripción es requerida."),
		}),
		onSubmit: (values, helper) => handleAceptar(values),
		enableReinitialize: true,
	});

	/**
	 * Handler para agregar un nuevo rol.
	 */
	const handleAgregar = () => {
		formik.setFieldValue("tipo", "agregar");
	}

	/**
	 * Handler para actualizar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleActualizar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "actualizar");
	}

	/**
	 * Handler para eliminar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleEliminar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "eliminar");
	}

	/**
	 * Handler para aceptar la acción.
	 * @param {*} values 
	 */
	const handleAceptar = async (values) => {
		try {
			switch (values.tipo) {
				case "agregar":
					await PermisosRequest.Agregar(values);
					break;
				case "actualizar":
					await PermisosRequest.Actualizar(values);
					break;
				case "eliminar":
					await PermisosRequest.Eliminar(values);
					break;
				default:
					throw new Error("Acción no especificada.")
			}
			ShowSnackbar("Acción realizada exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar realizar la acción.", error);
		} finally {
			handleCancelar();
		}
	}

	const handleCancelar = () => {
		PermisosSWR.mutate();
		formik.resetForm();
	}

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: "asc",
		}, {
			title: "Descripción",
			field: "descripcion",
		}
	];

	let actions = [
		{
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregar(),
			isFreeAction: true,
		}, {
			tooltip: "Editar",
			icon: "edit",
			onClick: handleActualizar,
		}, {
			tooltip: "Eliminar",
			icon: "delete",
			onClick: handleEliminar,
		}
	];

	return (
		<Fragment>
			<MaterialTable
				title="Permisos"
				is_loading={PermisosSWR.isValidating}
				data={PermisosSWR && PermisosSWR.data?.data}
				columns={columns}
				actions={actions}
			/>
			<Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
				<DialogTitle>
					{formik.values.tipo === "actualizar" && "Actualizar Permiso"}
					{formik.values.tipo === "agregar" && "Agregar Permiso"}
					{formik.values.tipo === "eliminar" && "Eliminar Permiso"}
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>Formulario con información del Permiso.</DialogContentText>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<TextField
								label="Nombre"
								name="nombre"
								value={formik.values.nombre}
								onChange={formik.handleChange}
								variant="outlined"
								fullWidth
								disabled={formik.values.tipo === "eliminar"}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								label="Descripción"
								name="descripcion"
								value={formik.values.descripcion}
								onChange={formik.handleChange}
								variant="outlined"
								fullWidth
								disabled={formik.values.tipo === "eliminar"}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelar} variant="outlined" color="primary">
						Cancelar
					</Button>
					<Button onClick={formik.submitForm} variant="contained" color="primary">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}