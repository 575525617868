import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "../../../components/materialTable";
import DialogEliminarTemaInteres from "./dialogEliminarTemaInteres";
import { ObtenerTemasInteres } from "./requestTemasInteres";

function TabPublicadas(props) {
  const { data, isValidating, mutate } = useSWR("publicadas", (key) => ObtenerTemasInteres(), { revalidateOnFocus: false });
  const [NoticiaSelect, SetNoticiaSelect] = useState();
  const history = useHistory();
  const notistack = useSnackbar();



  /**
   * Handler para abrir el popup para confirmar eliminación de noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleDialogOpen = (event, row) => {
    SetNoticiaSelect(row);
  }

  /**
   * Handler para cerrar el popup para confirmar eliminación de noticia.
   */
  const handleDialogClose = () => {
    SetNoticiaSelect();
    mutate();
  }

  let columns = [
    {
      title: "Título",
      field: "titulo",
    }, {
      title: "Cuerpo",
      field: "cuerpo"
    },{
      title: "Fecha de Publicación",
      field: "fecha_publicacion",
      defaultSort: "desc",
      render: (row) => Moment(row.fecha_publicacion).format("DD/MM/YYYY"),
    }
  ];

  let actions = [
     {
      icon: "delete",
      tooltip: "Eliminar",
      onClick: handleDialogOpen,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Temas de Interés Publicados"
        is_loading={!data || isValidating}
        data={data ? data.data : []}
        columns={columns}
        actions={actions}

      />
      <DialogEliminarTemaInteres
        noticia={NoticiaSelect}
        dialog_close={handleDialogClose}
      />
    </Fragment>
  );
}

export default TabPublicadas;