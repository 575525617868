import React, { useEffect } from "react";
import Page from "./page";
import { analytics } from "../../services/firebase";

export default function ComiteCuidado() {

	useEffect(() => {
		analytics.setCurrentScreen("/comite_cuidado", {
			idUsuario: "PENDIENTE",
		});
	}, []);

	return <Page />;
}