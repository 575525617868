import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import useSWR from "swr";
import DialogEliminarNoticia from "./dialogEliminarNoticia";
import MaterialTable from "../../../components/materialTable";
import { ObtenerProgramadas } from "./requestComunicados";

function TabPublicadas(props) {
  const { data, isValidating, mutate } = useSWR("programadas", (key) => ObtenerProgramadas(), { revalidateOnFocus: false });
  const [NoticiaSelect, SetNoticiaSelect] = useState();
  const history = useHistory();

  /**
   * Handler para redireccionar y editar una noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleEditar = (event, row) => {
    history.push(`noticia-editar/${row._id}`);
  }

  /**
   * Handler para abrir el popup para confirmar eliminación de noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleDialogOpen = (event, row) => {
    SetNoticiaSelect(row);
  }

  /**
   * Handler para cerrar el popup para confirmar eliminación de noticia.
   */
  const handleDialogClose = () => {
    SetNoticiaSelect();
    mutate();
  }

  let columns = [
    {
      title: "Título",
      field: "titulo",
    }, {
      title: "Epígrafe",
      field: "epigrafe"
    }, {
      title: "Bajada",
      field: "bajada",
    }, {
      title: "Fecha Publicación",
      field: "fecha_publicacion",
      defaultSort: "desc",
      render: (row) => Moment(row.fecha_publicacion).format("DD/MM/YYYY HH:mm"),
    }
  ];

  let actions = [
    {
      icon: "edit",
      tooltip: "Editar",
      onClick: handleEditar,
    }, {
      icon: "delete",
      tooltip: "Eliminar",
      onClick: handleDialogOpen,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Noticias Programadas"
        is_loading={!data || isValidating}
        data={data ? data.data : []}
        columns={columns}
        actions={actions}
      />
      <DialogEliminarNoticia
        noticia={NoticiaSelect}
        dialog_close={handleDialogClose}
      />
    </Fragment>
  );
}

export default TabPublicadas;