import React, { useContext, useState } from "react";
import Page from "./page";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { PublicarContratoAdjudicado } from "./requestContratosAdjudicados";
import { MainContext } from "../../App";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function Index(props) {
  const { usuarioSesion } = useContext(MainContext);
  const history = useHistory();
  const notistack = useSnackbar();

  const handlePublicar = async (values) => {
    try {
      notistack.enqueueSnackbar("Publicando el contrato adjudicado.", { variant: "default" });
     

      let contrato = {
        fecha: values.fecha_adjudicacion,
        nombre: values.nombre,
        cliente: values.cliente,
        gerencia: values.gerencia,        
      };


      await PublicarContratoAdjudicado(contrato);

      notistack.closeSnackbar();
      notistack.enqueueSnackbar("Contrato Adjudicado Publicado Exitosamente.", {
        variant: "success",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
      });
    } catch (error) {
      console.error(error);
      notistack.closeSnackbar();
      notistack.enqueueSnackbar("Error la intentar publicar un contrato adjudicado.", {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom"
        },
        action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
      });
    } finally {
      // history.replace("/");
    }
  }


  return (
    <Page
      handle_publicar={handlePublicar}
    />
  );
}