import React, { useState, createContext, useEffect } from "react";
import { Route, Switch, Redirect, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { IconButton, SvgIcon } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import * as ROUTES from "./constants/routes";
import * as URLS from "./constants/urls";
import * as Permissions from "./constants/permissions";
import { obtenerDatosSesionUsuario, getUsuario } from "./container/usuarios/requestUsuarios";
import { ObtenerUsuarioPlataforma } from "./container/usuarios/requestUsuarioPlataforma";
import { getGerencia } from "./container/portafolio/requestPortafolio";
import { auth, onAuthStateChange } from "./services/firebase";
import Error401 from "./container/401";
import AddNoticia from "./container/noticias/agregar";
import ContratoAdjudicado from "./container/ContratosAdjudicados";
import MisNoticias from "./container/noticias/misNoticias";
import VerNoticia from "./container/noticias/ver";
import EditarNoticia from "./container/noticias/editar";
import TemasInteres from "./container/noticias/temaInteres";
import HomeConteiner from "./container/home";
import BienvenidaCyD from "./container/BienvenidaCyD";
import Nosotros from "./container/Nosotros"
import AppBar from "./components/appBar";
import PDFReader from "./container/Nosotros/PDFReader";
import FormacionDesarrollo from "./container/FormacionDesarrollo";
import ComiteCuidado from "./container/ComiteCuidado";
import Rse from "./container/RSE";
import NoticiasLinkSuperior from "./container/noticias/ver/noticiaLinkSuperior";
import AjustesAdministración from "./container/ajustes/administracion";
import AjustesConfiguracion from "./container/ajustes/configuracion";
import { redirectToAccount } from "./services/auth";
import AccesoGerentes from "./container/gerentes";
import Loading from './components/page_loading';
import ImagenesBanner from "./container/noticias/imagenes_banner";
import { ReactComponent as WhatsappIconSVG } from "./img/whatsapp_icon_svg.svg";

const useStyles = makeStyles((theme) => ({
	root: {
		// flexGrow: 1,
		display: "flex",
	},
	paper: {
		padding: theme.spacing(),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	footer: {
		marginTop: "1rem",
		padding: "1rem",
		position: "fixed",
		color: theme.palette.text.secondary,
		bottom: 0,
		left: 0,
		width: "100%",
	},
	appBar: {
		top: "auto",
		bottom: 0,
	},
}));

const login = async () => {
	let currenToken = getQueryVariable("csrfToken");
	if (!currenToken) {
		currenToken = localStorage.getItem("csrfToken");
	}
	localStorage.setItem("csrfToken", currenToken);
	if (currenToken && currenToken !== "null" && currenToken !== "undefined") {
		localStorage.setItem("contrato", "");
		localStorage.setItem("proyecto", "");
		await axios
			.post(
				`${URLS.REDIRECT_BASE}/verifySessionCookie`,
				{},
				{
					withCredentials: true,
					crossDomain: true,
					params: {
						_csrf: currenToken,
					},
				}
			)
			.then((response) => {
				console.log(response);
				if (response.data.token) {
					auth.signInWithCustomToken(response.data.token);
				}
			})
			.catch((error) => {
				console.log(error);
				redirectToAccount()
				// window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split("?")[0]
				//   }`;
			});
	} else {
		redirectToAccount()
		// window.location.href = `${URLS.LOGIN}/?origin=${window.location.href.split("?")[0]
		//   }`;
	}
};

//TODO ver react-router-dom los hooks
const getQueryVariable = function (variable) {
	const query = window.location.search.substring(1);
	const vars = query.split("&");
	for (let i = 0; i < vars.length; i++) {
		let pair = vars[i].split("=");
		if (pair[0] === variable) {
			return pair[1];
		}
	}
	return false;
};

/**
 * Método encargado de retornar un objeto con la estructura de datos del usuario.
 * @returns Objeto usuario.
 */
const ObjetoUsuario = () => ({
	photoUrl: "",
	nombre: "",
	nombre_abreviado: "",
	email: "",
	ref: "",
	uid: "",
	gerencia: {
		_id: "",
		nombre: "",
		sigla: "",
	},
	tags: [],
});

export const MainContext = createContext({
	usuarioSesion: ObjetoUsuario(),
	accessError: false,
	setAccessError: () => { },
	permisos: {},
	setPermisos: () => { },
	ShowSnackbar: (message, error = undefined) => { },
});

export default function App() {
	const classes = useStyles();
	const notistack = useSnackbar();
	const location = useLocation();
	const [usuarioSesion, setUsuarioSesion] = useState(null);
	const [permisos, setPermisos] = useState({});
	const [isLoading, setIsLoading] = useState(true);
	const [accessError, setAccessError] = useState(true);
	const [HideWhatsappButton, SetHideWhatsappButton] = useState(true);

	/**
	 * Método encargado de mostrar un mensaje al usuario.
	 * @param {*} error Datos del error.
	 * @param {*} message Mensaje de error.
	 */
	function ShowSnackbar(message, error = undefined) {
		//Si existe un error, se imprime en consola.
		if (error) {
			console.error(error);
		}
		notistack.enqueueSnackbar(message, {
			variant: error ? "error" : "success",
			anchorOrigin: {
				horizontal: "center",
				vertical: "bottom"
			},
			action: (key) => <IconButton onClick={() => notistack.closeSnackbar()}><CloseIcon /></IconButton>
		});
	}

	const checkSession = (isLogged) => {
		try {
			if (isLogged && auth.currentUser) {
				const user = auth.currentUser;
				ObtenerDatosUsuario(user);
			} else {
				login();
			}
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * Método encargado de cargar la información del usuario, TAGs, gerencia.
	 * @param {*} user 
	 */
	async function ObtenerDatosUsuario(user) {
		try {
			let response = await obtenerDatosSesionUsuario();
			let persona = await getUsuario(response.ref);
			let gerencia = await getGerencia(persona.gerencia_ref);
			let usuarioPlataforma = await ObtenerUsuarioPlataforma(persona.auth_id);

			let data = {
				photoUrl: user.photoURL,
				nombre: user.displayName,
				nombre_abreviado: persona.nombre_abreviado,
				email: user.email,
				ref: response.ref,
				uid: persona.auth_id,
				gerencia: {
					_id: gerencia._id,
					nombre: gerencia.nombre,
					sigla: gerencia.sigla,
				},
				tags: usuarioPlataforma ? usuarioPlataforma.tags : [gerencia.sigla],
			}
			setUsuarioSesion(data);
			if (response.permisos) {
				setPermisos(response.permisos);
			} else {
				setPermisos({});
			}
			setIsLoading(false);
			setAccessError(false);
		} catch (error) {
			console.error(error);
			throw error;
		}
	}

	/**
	 * Método encargado de redireccionar al usuario con el botón de Whatsapp.
	 */
	const handleWhatsapp = () => {
		const url = "https://wa.me/56946263994";
		window.open(url, "_blank");
	}

	useEffect(() => {
		const unsubscribe = onAuthStateChange(checkSession);
		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (location && location.pathname) {
			const RUTAS_HIDE = [
				"/noticia-agregar"
			];
			let check = RUTAS_HIDE.includes(location.pathname);
			SetHideWhatsappButton(check);
		}
	}, [location]);

	const privateRoute = ({
		component: Component,
		permission,
		path,
		...rest
	}) => {
		return (
			<Route
				path={path}
				{...rest}
				render={(props) => {
					if (accessError) {
						return <Redirect to="/accessError" />;
					}
					if (!accessError && path === ROUTES.ACCESS_ERROR) {
						return <Redirect to="/" />;
					}
					if (permission && usuarioSesion && !permisos[permission]) {
						return <Redirect to="/" />;
					}
					return <Component {...props} />;
				}}
			/>
		);
	};

	const mainAppNew = (
		<React.Fragment>
			<MainContext.Provider
				value={{ usuarioSesion, accessError, setAccessError, permisos, setPermisos, ShowSnackbar }}
			>
				<div style={{ display: "flex", paddingTop: "80px", backgroundColor: "#E9E9E9", overflow: "hidden" }} className={classes.root}>
					<AppBar nombreModulo=" Mi CyD" />
					<div style={{ marginLeft: "10px", width: "-webkit-fill-available", marginRight: "10px" }}>
						<Switch>
							<Route
								exact={true}
								path={ROUTES.HOME}
								component={() => <HomeConteiner />}
							/>
							{privateRoute({
								component: AddNoticia,
								permission: Permissions.AGREGAR_NOTICIA,
								path: ROUTES.NOTICIA_AGREGAR,
								exact: true,
							})}
							{privateRoute({
								component: ContratoAdjudicado,
								permission: Permissions.AGREGAR_CONTRATO_ADJUDICADO,
								path: ROUTES.CONTRATOS_ADJUDICADOS,
								exact: true,
							})}
							<Route
								exact={true}
								path={ROUTES.MIS_NOTICIAS}
								component={() => <MisNoticias />}
							/>
							<Route
								exact={true}
								path={ROUTES.NOTICIA_VER}
								component={() => <VerNoticia />}
							/>
							{privateRoute({
								component: EditarNoticia,
								permission: Permissions.EDITAR_NOTICIA,
								path: ROUTES.NOTICIA_EDITAR,
								exact: true,
							})}
							{privateRoute({
								component: TemasInteres,
								// permission: Permissions.AGREGAR_TEMAS_INTERES,
								path: ROUTES.NOTICIA_TEMA_INTERES,
								exact: true,
							})}
							{privateRoute({
								component: ImagenesBanner,
								permission: Permissions.AGREGAR_IMAGEN_BANNER,
								path: ROUTES.IMAGENES_BANNER,
								exact: true,
							})}
							<Route
								exact={true}
								path={ROUTES.BIENVENIDA_CYD}
								component={() => <BienvenidaCyD />}
							/>
							<Route
								exact={true}
								path={ROUTES.NOSOTROS}
								component={() => <Nosotros />}
							/>
							<Route
								exact={true}
								path={ROUTES.PDFPRUEBA}
								component={() => <PDFReader />}
							/>
							<Route
								exact={true}
								path={ROUTES.BENEFICIOS}
								component={() => <HomeConteiner />}
							/>
							<Route
								exact={true}
								path={ROUTES.FORMACIONDESARROLLO}
								component={() => <FormacionDesarrollo />}
							/>
							<Route
								exact={true}
								path={ROUTES.COMITECUIDADO}
								component={() => <ComiteCuidado />}
							/>
							<Route
								exact={true}
								path={ROUTES.NOTICIAS}
								component={() => <NoticiasLinkSuperior />}
							/>
							<Route
								exact={true}
								path={ROUTES.RSE}
								component={() => <Rse />}
							/>
							{privateRoute({
								component: AccesoGerentes,
								permission: Permissions.VER_ACCESOS_GERENTES,
								exact: true,
								path: ROUTES.GERENTES
							})}
							{privateRoute({
								component: AjustesAdministración,
								permission: Permissions.VER_AJUSTES,
								path: ROUTES.AJUSTES_ADMINISTRACION,
								exact: true,
							})}
							<Route
								exact={true}
								path={ROUTES.AJUSTES_CONFIGURACION}
								component={() => <AjustesConfiguracion />}
							/>
							<Route
								exact={true}
								path={ROUTES.ACCESS_ERROR}
								component={() => <Error401 />}
							/>
						</Switch>
					</div>
				</div>

				{/* BOTÓN WHATSAPP */}
				{!HideWhatsappButton && (
					<IconButton onClick={handleWhatsapp}>
						<SvgIcon style={{ fontSize: 100, backgroundColor: "transparent", position: "fixed", bottom: 20, right: 25, zIndex: 1000 }}>
							<WhatsappIconSVG />
						</SvgIcon>
					</IconButton>
				)}
			</MainContext.Provider>
		</React.Fragment >
	);

	if (usuarioSesion && permisos && !isLoading && !accessError) {
		return mainAppNew;
	} else {
		return <Loading />
	}
}