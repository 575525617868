import React, { Fragment, useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Moment from "moment";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ObtenerNoticias } from "../../../services/requests/mi_cyd/comunicados";
import { MainContext } from "../../../App";
import "moment/locale/es";

export default function NoticiasHistorial(props) {
	const { usuarioSesion } = useContext(MainContext);
	const [Dato, SetDato] = useState();
	const [expanded, setExpanded] = useState(false);
	const NoticiaSWR = useSWR("noticias_recientes", (key) => ObtenerNoticias(usuarioSesion.tags));
	const classes = useStyles();
	const history = useHistory();

	const OrdenarNoticias = () => {
		const anioNoticia = (item) => Moment(item.fecha_publicacion).format("YY");
		const resultadoOrden = _(NoticiaSWR.data).groupBy(anioNoticia).value();

		for (const key in resultadoOrden) {
			if (Object.hasOwnProperty.call(resultadoOrden, key)) {
				const values = resultadoOrden[key];
				const mesNoticia = (item) =>
					Moment(item.fecha_publicacion).format("MMMM");
				let ordered = _(values).groupBy(mesNoticia).value();
				resultadoOrden[key] = ordered;
			}
		}
		return resultadoOrden;
	};

	useEffect(() => {
		if (NoticiaSWR.data) {
			let dato = OrdenarNoticias();
			SetDato(dato);
		}
	}, [NoticiaSWR.data]);

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};

	return (
		<Fragment>
			{!NoticiaSWR.data && (
				<Grid item md={6}>
					<Typography>Cargando...</Typography>
					<LinearProgress />
				</Grid>
			)}
			{NoticiaSWR.data && NoticiaSWR.data.length === 0 && (
				<Grid item md={6}>
					<Typography>Sin noticias</Typography>
				</Grid>
			)}
			{NoticiaSWR.data && NoticiaSWR.data.length > 0 && (
				<Grid container spacing={1}>
					<Grid item md={12}>
						{Dato &&
							Object.keys(Dato)
								.reverse()
								.map((day, index_1) => (
									<div key={`div_${index_1}`}>
										<Accordion
											expanded={expanded === `panel_${index_1}`}
											onChange={handleChange(`panel_${index_1}`)}
											style={{ marginBottom: "5px", borderRadius: "20px" }}
										>
											<AccordionSummary
												expandIcon={<ExpandMoreIcon />}
												aria-controls="panel1a-content"
												id="panel1a-header"
											>
												<Typography className={classes.heading}>
													<Typography
														gutterBottom
														variant="body2"
														color="#731f1f"
													>
														<strong>20{day}</strong>
													</Typography>
												</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<label>
													{Dato &&
														Object.keys(Dato[day]).map((mes, index_1) => (
															<div key={`div_${index_1}`}>
																<Box style={{ textAlign: "center", color: "#731f1f" }}>
																	<Typography style={{ fontSize: "16px" }}>
																		<strong>{`${mes[0].toUpperCase()}${mes.slice(
																			1
																		)}`}
																		</strong>
																	</Typography>

																</Box>

																{Dato[day][mes].map((d, index_3) => (
																	<div key={`div_${index_3}`}>
																		<Card
																			elevation={0}
																			variant="outlined"
																			className={classes.root}
																			style={{
																				borderRadius: 15,
																				paddingTop: 0,
																				borderColor: "gray",
																			}}
																		>
																			<CardActionArea
																				onClick={() =>
																					history.push(`/noticia-ver/${d._id}`)
																				}
																			>
																				<CardContent>
																					<Typography
																						variant="caption"
																						display="block"
																						gutterBottom
																						color="textSecondary"
																					>
																						{Moment(d.fecha_publicacion).format(
																							"DD/MM/YYYY HH:mm"
																						)}
																					</Typography>
																					<Typography
																						gutterBottom
																						variant="body2"
																						color="textPrimary"
																					>
																						<strong>{d.titulo}</strong>
																					</Typography>
																					<Typography
																						variant="body2"
																						color="textSecondary"
																						component="p"
																					>
																						{d.bajada}
																					</Typography>
																				</CardContent>
																			</CardActionArea>
																		</Card>
																	</div>

																))}
																<Divider style={{ marginBottom: "10px" }}></Divider>
															</div>
														))}
												</label>
											</AccordionDetails>
										</Accordion>
									</div>
								))}
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing(2),
		marginBottom: "5px",
	},
}));