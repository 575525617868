import React, { Fragment, useContext } from "react";
import useSWR from "swr";
import Moment from "moment";
import { Link } from "react-router-dom";
import { Box, Button, Chip, Grid, LinearProgress, Paper, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import NoticiasHistorial from "./noticiasHistorial";
import { ObtenerNoticias } from "../../../services/requests/mi_cyd/comunicados";
import { MainContext } from "../../../App";

export default function NoticiasMore(props) {
	const { usuarioSesion } = useContext(MainContext);
	const { data: dataNoticias, error: errorNoticias } = useSWR("noticias_recientes", (key) => ObtenerNoticias(usuarioSesion.tags), { revalidateOnFocus: false });

	return (
		<Fragment>
			{!dataNoticias && (
				<Grid item md={6}>
					<Typography>Cargando...</Typography>
					<LinearProgress />
				</Grid>
			)}
			{dataNoticias && dataNoticias.length === 0 && (
				<Grid item md={6}>
					<Typography>Sin noticias</Typography>
				</Grid>
			)}
			{dataNoticias && dataNoticias.length > 0 && (
				<div>

					<Grid container alignItems="stretch" style={{ width: "100%" }}>
						<Grid item xs={9} style={{ overflowY: "auto", height: "89vh" }}>
							<Box style={{ marginLeft: "10px" }}>
								<Button component={Link} to="/" variant="contained" color="primary">
									<HomeIcon style={{ fontSize: 20 }} />
								</Button>
							</Box>

							<div style={{ margin: "10px" }}>
								<Box align="center">
									<Paper variant="outlined" style={{ width: "100%", borderRadius: 15 }}>
										{Boolean(dataNoticias[0].epigrafe) && (
											<Box m={1} align="center">
												<Typography
													variant="h6"
													color="textSecondary"
													component="p"
													gutterBottom
												>
													{dataNoticias[0].epigrafe}
												</Typography>
											</Box>
										)}

										<Box m={1}>
											<Typography
												align="center"
												variant="h4"
												style={{ color: "#731f1f" }}
											>
												{dataNoticias[0].titulo}
											</Typography>
										</Box>
										{Boolean(dataNoticias[0].bajada) && (
											<Box m={1}>
												<Typography
													variant="h6"
													color="textSecondary"
													component="p"
													gutterBottom
												>
													{dataNoticias[0].bajada}
												</Typography>
											</Box>
										)}
										<Box m={1} align="left">
											<Grid container xs={12} style={{ display: "flex" }}>
												<Grid item xs={12}>
													<Typography
														variant="caption"
														display="block"
														gutterBottom
														color="textSecondary"
													>
														{Moment(dataNoticias[0].fecha_publicacion).format(
															"DD/MM/YYYY HH:mm"
														)}
													</Typography>
												</Grid>
											</Grid>
										</Box>

										<div>
											<Grid
												container
												direction="column"
												justifyContent="center"
												alignItems="stretch"
												style={{ display: "contents" }}
											>
												<Grid item>
													<img
														src={dataNoticias[0].adjuntos && dataNoticias[0].adjuntos.length > 0 ? dataNoticias[0].adjuntos[0].url : ""}
														style={{ width: "100%" }}
													></img>
												</Grid>
											</Grid>
										</div>

										<Box m={1} align="left">
											<Grid container xs={12} style={{ display: "flex" }}>
												{/* <Grid item xs ={4}>
                        <Typography variant="caption"> Fecha Publicacion: {Moment(FechaPublicacion).format("DD/MM/YYYY HH:mm")}</Typography>
                    </Grid> */}
												<Grid item xs={12}>
													{/* <Typography> tags: {Array.from(Tags).filter(t => t.checked === true).map(t => t.nombre).join(", ")}</Typography> */}
													<Box display="flex" width="100%" justifyContent="flex-end">
														{/* SI TODO CYD EXISTE */}
														{Array.from(dataNoticias[0].tags).find(t => t === "Todo CyD") && (
															<Chip
																label={"Todo CyD"}
																size="small"
																variant="outlined"
																color="primary"
																style={{ margin: 2 }}
															/>
														)}
														{/* SI TODO CYD NO EXISTE */}
														{!Array.from(dataNoticias[0].tags).find(t => t === "Todo CyD") && Array.from(dataNoticias[0].tags).map((t) => (
															<Chip
																label={t}
																size="small"
																variant="outlined"
																color="primary"
																style={{ margin: 2 }}
															/>
														))}
													</Box>
												</Grid>
											</Grid>
										</Box>

										<Box m={2}>
											<Typography variant="body1" gutterBottom align="justify">
												{dataNoticias[0] && dataNoticias[0].cuerpos && (
													<div
														dangerouslySetInnerHTML={{ __html: dataNoticias[0].cuerpos }}
													/>
												)}
											</Typography>
										</Box>
									</Paper>
								</Box>
							</div>
							<Button component={Link} to="/" variant="contained" color="primary" style={{ marginLeft: "10px" }}>
								<HomeIcon style={{ fontSize: 20 }} />
							</Button>
						</Grid>
						<Grid item xs={3} style={{ overflowY: "auto", height: "89vh", paddingRight: "5px", paddingLeft: "15px" }}>
							<NoticiasHistorial />
						</Grid>
					</Grid>
				</div>
			)}
		</Fragment>
	);
}