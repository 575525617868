import React, { createContext, useContext, useEffect } from "react";
import Page from "./page";
import useSWR from "swr";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import * as ComunicadosRequests from "../../../services/requests/mi_cyd/comunicados";
import * as GerenciasRequests from "../../../services/requests/portafolio/gerencias";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { MainContext } from "../../../App";

export const NoticiaAgregarContext = createContext({
	formik: null,
	handleNext: () => { },
	handlePrevious: () => { },
	handleChangeTags: () => { },
});

export default function Index() {
	const MainCTX = useContext(MainContext);
	const history = useHistory();
	const GerenciasSWR = useSWR("gerencias", (key) => GerenciasRequests.Obtener());
	const Formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				let tagsMaps = values.tags.filter((t) => t.checked === true).map((t) => t.sigla);
				
				values.tags = tagsMaps;
				values.adjuntos = values.contenido.map(c => c.adjunto);
				values.cuerpos = values.contenido.map(c => draftToHtml(convertToRaw(c.cuerpo.getCurrentContent())));
				values.botonTexto = values.contenido.map(c => c.botonTexto);
				values.botonURL = values.contenido.map(c => c.botonURL);

				delete values.contenido;
				delete values.currentStep;

				console.log(values);

				await ComunicadosRequests.PublicarNoticia(values);
				MainCTX.ShowSnackbar("Noticia enviada exitosamente.");
			} catch (error) {
				MainCTX.ShowSnackbar("Error al intentar enviar la noticia.", error);
			} finally {
				history.push("/");
			}
		}
	});

	useEffect(() => {
		if (MainCTX.usuarioSesion && MainCTX.usuarioSesion.ref) {
			Formik.setFieldValue("_autor_ref", MainCTX.usuarioSesion.ref);
		}
	}, [MainCTX.usuarioSesion]);

	useEffect(() => {
		if (GerenciasSWR.data) {
			let tags = Array.from(GerenciasSWR.data).map(g => ({
				nombre: g.nombre,
				sigla: g.sigla,
				nombre_sigla: `${g.sigla} ${g.nombre}`,
				checked: false,
			}));
			tags.unshift({
				nombre: "Todo CyD",
				sigla: "Todo CyD",
				nombre_sigla: "Todo CyD",
				checked: false,
			});

			Formik.setFieldValue("tags", tags);
		}
	}, [GerenciasSWR.data]);

	/**
	 * Handler para pasar al siguiente paso.
	 */
	const handleNext = () => {
		let currentStep = Formik.values.currentStep;
		let titulo = Formik.values.titulo;
		let contenido = Formik.values.contenido;
		if (currentStep === 1 && !(titulo && contenido && contenido.length > 0)) {
			MainCTX.ShowSnackbar("Falta información.", new Error("Falta información."));
		} else if (currentStep === 2 && Formik.values.tags.filter((t) => t.checked === true).length < 1) {
			MainCTX.ShowSnackbar("Falta información.", new Error("Falta información."));
		} else if (currentStep < 3) {
			Formik.setFieldValue("currentStep", currentStep + 1);
		}
	};

	/**
	 * Handler para regresar al paso anterior.
	 */
	const handlePrevious = () => {
		let currentStep = Formik.values.currentStep;
		Formik.setFieldValue("currentStep", currentStep - 1);
	};

	/**
	 * Handler para los tags de las noticias.
	 * @param {*} nombre Nombre del tag que se selecciona.
	 */
	const handleChangeTags = (nombre) => {
		let tags = Array.from(Formik.values.tags);
		if (nombre === "Todo CyD") {
			let check = tags.find((t) => t.nombre === "Todo CyD").checked;
			tags = tags.map(t => ({ ...t, checked: !check }));
		} else {
			let tagIndex = tags.findIndex(t => t.nombre === nombre);
			tags[tagIndex].checked = !tags[tagIndex].checked;
			if (tags[tagIndex].checked === false) {
				//Si el valor al que se cambio es FALSE, se desactiva Todo CyD.
				let todoCyD = tags.findIndex(t => t.nombre === "Todo CyD");
				tags[todoCyD].checked = false;
			}
		}
		Formik.setFieldValue("tags", tags);
	};

	return (
		<NoticiaAgregarContext.Provider value={{
			formik: Formik,
			handleNext,
			handlePrevious,
			handleChangeTags,
		}}>
			<Page />
		</NoticiaAgregarContext.Provider>
	);
}