import { mixed, object, string } from "yup";

export const TIPO_AGREGAR = "AGREGAR";
export const TIPO_ELIMINAR = "ELIMINAR";

export const FormikInitialValues = {
	nombre: "",
	link_externo: "",
	file: null,
	adjunto: null,
	_autor_ref: "",
	is_visible: false,
}

export const FormikValidationSchema = object().shape({
	nombre: string()
		.min(3, ({ min }) => `El nombre debe tener al menos ${min} caracteres.`)
		.max(300, ({ max }) => `El nombre debe tener a lo más ${max} caracteres.`)
		.required("El nombre es requerido."),
	link_externo: string()
		.min(3, ({ min }) => `El link externo debe tener al menos ${min} caracteres.`)
		.max(300, ({ max }) => `El link externo debe tener a lo más ${max} caracteres.`)
		.url("El link externo debe tener la estructura de URL correcta.")
		.optional(),
	file: mixed()
		.required("El adjunto es requerido."),
});