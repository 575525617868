import React, { Fragment, useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Grid, Paper } from "@material-ui/core";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Template3(props) {
	const {
		formik,
	} = props;

	const [RichTexts, SetRichTexts] = useState();

	useEffect(() => {
		if (formik.values.cuerpos) {
			let richTexts = Array.from(formik.values.cuerpos).map(cuerpo => {
				const contentBlock = htmlToDraft(cuerpo || "");
				if (contentBlock) {
					const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
					const editorState = EditorState.createWithContent(contentState);
					return editorState;
				} else {
					return "";
				}
			});
			SetRichTexts(richTexts);
		}
	}, []);

	useEffect(() => {
		if (RichTexts) {
			formik.setFieldValue("cuerpos", RichTexts.map(r => draftToHtml(convertToRaw(r.getCurrentContent()))));
		}
	}, [RichTexts]);

	/**
	 * Método encargado de actualizar el state de RichTexts.
	 * @param {*} richText RichText actualizado.
	 * @param {*} index Posición del cuerpo.
	 */
	const handleChangeRichText = (richText, index) => {
		RichTexts[index] = richText;
		SetRichTexts(Array.from(RichTexts));
	}

	return (
		<Fragment>
			{formik.values.adjuntos && Array.from(formik.values.adjuntos).map((adjunto, index) => (
				<Paper key={`paper_${index}`} style={{ borderRadius: 20, margin: 10, padding: 10 }}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<img
								alt={adjunto.nombre ? adjunto.nombre : ""}
								src={adjunto.url ? adjunto.url : ""}
								width="100%"
							/>
						</Grid>
						<Grid item xs={12}>
							<Editor
								toolbar={{
									options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"],
									inline: {
										options: ["bold", "italic", "underline", "strikethrough"]
									}
								}}
								editorState={RichTexts ? RichTexts[index] : null}
								onEditorStateChange={(editorState) => handleChangeRichText(editorState, index)}
							/>
						</Grid>
					</Grid>
				</Paper>
			))}
		</Fragment>
	);
}

const ACCEPTED_FILES_IMAGE = "image/png, image/jpg, image/jpeg";
const ACCEPTED_FILES_VIDEOS = "video/mp4, video/x-m4v, video/webm";