import React, { Fragment, useEffect, useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import NosotrosImagen from "./components/nosotrosImagen";
import NuestroProposito from "./components/nuestroProposito";
import MercadosProyectos from "./components/mercadosProyectos";
import Organigrama from "./components/organigrama";
import PoliticasCorporativas from "./components/politicasCorporativas";
import CriteriosESG from "./components/criteriosESG";
import HistoriaCyD from "./components/historiaCyD";
import PresentacionCorporativa from "./components/presentacionCorporativa";
import GalleryCarousel from "./GalleryCarousel";
import LogrosCarousel from "./LogrosCarousel";
import PDFReader from "./PDFReader";
import { analytics, auth } from "../../services/firebase";
import {
  ANALYTICS,
  AddAnalytics,
  MERCADOS_PROYECTOS_URL,
  ORGANIGRAMA_PPT_URL,
  ORGANIGRAMA_PDF_URL,
  RedirectURL,
  ORGANIGRAMA_PERU_PDF_URL,
} from "./utils";
import "../../App.css";
import OficinasCyD from "./components/oficinasCyD";

export default function Bienvenida(props) {
  const classes = useStyles();
  const [claimUser, setClaimUser] = useState(null);
  const [descargaBoton, setDescargaBoton] = useState(false);
  const [pdfRuta, setPDFRuta] = useState(null);
  const [pptRuta, setPptRuta] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalPpt, setOpenModalPpt] = useState(false);
  const [expanded, setExpanded] = useState(false);

  /**
   * Método encargado de abrir o cerrar paneles con contenido.
   * @param {*} panel Nombre del panel.
   */
  const handleChange = (panel) => (event, isExpanded) => {
    switch (panel) {
      case "panel_politicas":
        AddAnalytics(ANALYTICS.ACCORDION_POLITICAS_CYD, claimUser);
        break;
      case "panel_criterios":
        AddAnalytics(ANALYTICS.ACCORDION_PRESENTACION_CYD, claimUser);
        break;
      case "panel_presentacion":
        AddAnalytics(ANALYTICS.ACCORDION_PRESENTACION_CYD, claimUser);
        break;
      case "panel_premios":
        AddAnalytics(ANALYTICS.ACCORDION_PREMIOS, claimUser);
        break;
      case "panel_galeria":
        AddAnalytics(ANALYTICS.ACCORDION_GALERIA, claimUser);
        break;
    }
    setExpanded(isExpanded ? panel : false);
  };

  const loadUsuarioClaims = async () => {
    let resultToken = auth.currentUser
      .getIdTokenResult()
      .then((getIdTokenResult) => {
        // console.log(getIdTokenResult);
        setClaimUser(getIdTokenResult.claims);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Modal para abrir archivos desde Google Drive
  const handleOpenModalPpt = () => {
    setOpenModalPpt(true);
  };

  const handleCloseModalPpt = () => {
    setDescargaBoton(false);
    setOpenModalPpt(false);
  };

  // Apertura de PDFs
  // PDFS BOTONES ORGANIGRAMA
  const handlePdfOrganigrama = () => {
    AddAnalytics(ANALYTICS.ORGANIGRAMA, claimUser);
    setPptRuta(ORGANIGRAMA_PDF_URL);
    handleOpenModalPpt();
  };

  const handlePdfOrganigramaPeru = () => {
    AddAnalytics(ANALYTICS.ORGANIGRAMA_PERU, claimUser);
    setPptRuta(ORGANIGRAMA_PERU_PDF_URL);
    handleOpenModalPpt();
  };

  const analyticsMercadosyProyectos = () => {
    RedirectURL(MERCADOS_PROYECTOS_URL);
    AddAnalytics(ANALYTICS.MERCADOS_PROYECTOS, claimUser);
  };

  useEffect(() => {
    analytics.setCurrentScreen("/nosotros", { idUsuario: "PENDIENTE" });
    loadUsuarioClaims();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Grid container spacing={2} style={{ padding: 10 }}>
        <Grid item xs={12}>
          <NosotrosImagen />
        </Grid>

        <Grid item xs={12}>
          <NuestroProposito />
        </Grid>

        <Grid item xs={12}>
          <HistoriaCyD />
        </Grid>

        <Grid item xs={12}>
          <MercadosProyectos />
        </Grid>

        <Grid item xs={12}>
          <Organigrama
            handle_organigrama={handlePdfOrganigrama}
            handle_organigrama_Peru={handlePdfOrganigramaPeru}
            handle_analytics={analyticsMercadosyProyectos}
          />
        </Grid>

        {/* POLITICAS */}
        <Grid item xs={12}>
          <PoliticasCorporativas
            usuario={claimUser}
            expanded={expanded}
            handle_change={handleChange}
          />
        </Grid>

        {/* CRITERIOS ESG */}
        <Grid item xs={12}>
          <CriteriosESG
            usuario={claimUser}
            expanded={expanded}
            handle_change={handleChange}
          />
        </Grid>

        {/* PRESENTACIÓN CORPORATIVA */}
        <Grid item xs={12}>
          <PresentacionCorporativa
            usuario={claimUser}
            expanded={expanded}
            handle_change={handleChange}
          />
        </Grid>

        {/* ACORDIÓN DE LOGROS   */}
        <Grid item xs={12}>
          <Accordion
            id="premioslogros"
            expanded={expanded === "panel_premios"}
            onChange={handleChange("panel_premios")}
            style={{ borderRadius: 25 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#731f1f" }} />}
            >
              <Typography
                variant="h5"
                style={{
                  flexBasis: "100%",
                  color: "#731f1f",
                  fontWeight: "bold",
                }}
              >
                Reconocimientos y Certificaciones
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ textAlign: "-webkit-center", display: "block" }}
            >
              <Box
                style={{
                  display: "-ms-flexbox",
                  maxWidth: "50.5vw",
                  marginRight: "1rem",
                }}
              >
                <LogrosCarousel />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* </GALERIA FOTOGRÁFICA> */}
        <Grid item xs={12}>
          <Accordion
            id="galeriafotografica"
            expanded={expanded === "panel_galeria"}
            onChange={handleChange("panel_galeria")}
            style={{ borderRadius: 25 }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon style={{ color: "#731f1f" }} />}
            >
              <Typography
                variant="h5"
                style={{
                  flexBasis: "100%",
                  color: "#731f1f",
                  borderRadius: "20px",
                  fontWeight: "bold",
                }}
              >
                Galería Fotográfica
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{
                textAlign: "-webkit-center",
                display: "block",
                borderRadius: "20px",
              }}
            >
              <Box
                style={{
                  display: "-ms-flexbox",
                  maxWidth: "55.5vw",
                  height: "530px",
                }}
              >
                <GalleryCarousel />
              </Box>
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12}>
          <OficinasCyD />
        </Grid>

        {/* Modals de PDF */}
        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModal}
          overflow="scroll"
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classes.paper}>
              <Paper>
                <PDFReader direccionPDF={pdfRuta}></PDFReader>
              </Paper>
              <Box
                zIndex="tooltip"
                style={{
                  position: "fixed",
                  width: "69%",
                  left: "15%",
                  top: "90%",
                  height: "3rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModalPpt}
          overflow="scroll"
          onClose={handleCloseModalPpt}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModalPpt}>
            <div
              className={classes.paper}
              style={{ minHeight: "-webkit-fill-available" }}
            >
              <Paper
                style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
              >
                <ScrollContainer
                  className="container"
                  style={{ minWidth: "-webkit-fill-available" }}
                >
                  <iframe
                    style={{ align: "middle" }}
                    src={pptRuta}
                    frameborder="0"
                    width="100%"
                    height="100%"
                    allowfullscreen="true"
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"                   
                  ></iframe>

                  {/* </Box> */}
                </ScrollContainer>
              </Paper>
              <Box
                zIndex="tooltip"
                display="flex"
                style={{
                  position: "fixed",
                  width: "20%",
                  left: "70%",
                  top: "90%",
                  height: "2.5rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModalPpt}
                >
                  Cerrar
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </Fragment>
  );
}

/*************************************************************************************
 * 																				STYLE
 ************************************************************************************/

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
  modalStyle1: {
    position: "absolute",
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",
    cursor: "grab",
  },
}));
