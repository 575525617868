import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Parallax } from "react-parallax";
import TabGaf from "./gaf/tabGaf";
import TabGpo from "./gpo/tabGpo";
import TabGgral from "./ggral/tabGgral";
import TabComercial from "./comercial/comercial";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "lighgray",
    marginBottom: "2rem",
  },
  tab: {
    // minWidth: 150, // a number of your choice
    width: 300, // a number of your choice
  },
}));

export default function TabsWrappedLabel() {
  const classes = useStyles();
  const [value, setValue] = React.useState("cuatro");
  const image1 =
    "https://storage.googleapis.com/mi-cyd/Gerentes/BANNER_GERENCIA.png";

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Parallax
          bgImage={image1}
          strength={150}
          style={{
            borderRadius: "20px",
            zIndex: 2,
            marginRight: "2rem",
            marginBottom: "2rem",
            width: "100%",
          }}
        >
          <div
            variant="box"
            style={{
              marginRight: "2rem",
              marginBottom: "1rem",
              height: "auto",
              overflow: "hidden",
              height: "17rem",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "20px",
                marginLeft: "2rem",
                zIndex: 10,
              }}
            >
              <Typography
                variant="caption"
                display="block"
                gutterBottom
                style={{
                  color: "white",
                }}
              ></Typography>
              <Typography variant="h3" style={{ color: "white" }}>
                <strong>GERENTES</strong>
              </Typography>
            </div>
          </div>
        </Parallax>
        <AppBar
          position="static"
          style={{
            borderTopLeftRadius: "20px",
            borderTopRightRadius: "20px",
            width: "100%",
            backgroundColor: "white",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            // textColor="white"
            indicatorColor="primary"
            textColor="primary"
            // variant="fullWidth"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              value="cuatro"
              label="Gerencia General"
              style={{fontSize:"1rem"}}
              wrapped
              {...a11yProps("cuatro")}
              classes={{ root: classes.tab }}
            />
            <Tab
              value="one"
              label="Gerencia de Administración y Finanzas"
              style={{fontSize:"1rem"}}
              wrapped
              {...a11yProps("one")}
              classes={{ root: classes.tab }}
            />
            <Tab
              value="two"
              label="Gerencia de Personas y Organización"
              style={{fontSize:"1rem"}}
              wrapped
              {...a11yProps("two")}
              classes={{ root: classes.tab }}
            />
            <Tab
              value="three"
              label="Gerencia de Desarrollo de Negocios"
              style={{fontSize:"1rem"}}
              wrapped
              {...a11yProps("three")}
              classes={{ root: classes.tab }}
            />
            
          </Tabs>
        </AppBar>
        <TabPanel
          value={value}
          index="one"
          style={{ backgroundColor: "white" }}
        >
          <TabGaf />
        </TabPanel>
        <TabPanel
          value={value}
          index="two"
          style={{ backgroundColor: "white" }}
        >
          <TabGpo />
        </TabPanel>
        <TabPanel
          value={value}
          index="three"
          style={{ backgroundColor: "white" }}
        >
          <TabComercial />
        </TabPanel>
        <TabPanel
          value={value}
          index="cuatro"
          style={{ backgroundColor: "white" }}
        >
          <TabGgral />
        </TabPanel>
      </div>
    </React.Fragment>
  );
}
