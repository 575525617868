import React from "react";
import Moment from "moment";
import { motion } from "framer-motion";
import { Button, Grid, Paper, Tooltip } from "@material-ui/core";

export default function ListadoIconos(props) {

	return (
		<motion.div
			initial="hidden"
			animate="visible"
			variants={{
				hidden: { scale: 0.3, opacity: 0, x: 100 },
				visible: { scale: 1, opacity: 1, x: 0, transition: { delay: 0.6 } }
			}}
		>
			<Paper elevation={0} style={{ marginTop: "1rem", borderRadius: 25, paddingBottom: "5px" }}>
				<Grid container justifyContent="center">
					{Object.values(Iconos).map((icono, index) => (
						<Grid item xs={2} style={{ textAlign: "center" }} key={`grid_${index}`}>
							{IconoComponent(icono.tooltip, icono.redirect, icono.image)}
						</Grid>
					))}
				</Grid>
			</Paper>
		</motion.div>
	);
}

/**
 * Método encargado de generar el componente reutilizable para los íconos de Google.
 * @param {*} tooltip Texto para mostrar.
 * @param {*} redirect URL para redireccionar al una nueva ventana.
 * @param {*} image URL del ícono.
 * @returns Component.
 */
const IconoComponent = (tooltip, redirect, image) => (
	<Tooltip
		title={tooltip}
		placement="bottom"
	>
		<Button
			component="a"
			href={redirect}
			target="_blank"
			style={{
				justifyContent: "center",
				padding: 0,
				paddingTop: "5px",
				minWidth: 0,
			}}
		>
			<img
				src={image}
				alt={tooltip}
				style={{ width: "30px", height: "30px" }}
			/>
		</Button>
	</Tooltip>
);

const Iconos = {
	calendar: {
		tooltip: "Calendario",
		image: `https://ssl.gstatic.com/calendar/images/dynamiclogo_2020q4/calendar_${Moment().format("D")}_2x.png`,
		redirect: "https://calendar.google.com/calendar/u/0/r",
	},
	gmail: {
		tooltip: "Gmail",
		image: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1200px-Gmail_icon_%282020%29.svg.png",
		redirect: "https://mail.google.com/mail/u/0/#inbox",
	},
	drive: {
		tooltip: "Drive",
		image: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/Google_Drive_icon_%282020%29.svg/1200px-Google_Drive_icon_%282020%29.svg.png",
		redirect: "https://drive.google.com/drive/my-drive",
	},
	chat: {
		tooltip: "Chat",
		image: "https://play-lh.googleusercontent.com/cF_oWC9Io_I9smEBhjhUHkOO6vX5wMbZJgFpGny4MkMMtz25iIJEh2wASdbbEN7jseAx",
		redirect: "https://chat.google.com/",
	},
	contactos: {
		tooltip: "Contactos",
		image: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/93/Google_Contacts_icon.svg/1200px-Google_Contacts_icon.svg.png",
		redirect: "https://contacts.google.com/directory",
	},
}