import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment, useState, useContext, createContext } from "react";
import { pdfjs } from "react-pdf";
import PDFReader from "../../Nosotros/PDFReader";
import * as Permissions from "../../../constants/permissions";
import { MainContext } from "../../../App";
import { isBrowser, isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const imagenCapacitacion =
  "https://storage.googleapis.com/mi-cyd/Gerentes/GAF/fotoGAF2.png";

// miniBanners

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },

  accesos: {
    color: "#731f1f",
    paddingRight: "1rem",
  },

  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",

    cursor: "grab",
  },
}));

export default function TagGaf() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pdfRuta, setPDFRuta] = React.useState(null);
  const [pptRuta, setPptRuta] = React.useState(null);
  const { usuarioSesion, permisos } = useContext(MainContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModalPpt = () => {
    setOpenModalPpt(true);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* Repositorio de informes */}
      <div
        style={{
          textAlign: "-webkit-center",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        <Paper
          variant="outlined"
          style={{
            width: "80%",
            borderRadius: "20px",
            overflow: "hidden",
            textAlign: "-webkit-center",
            marginBottom: "1rem",
          }}
        >
          {isMobile ? (
            <Grid container style={{ height: "auto" }}>
              <Grid
                item
                md={12}
                lg={8}
                width="-webkit-fill-available"
                style={{
                  paddingTop: "0.5rem",
                  paddingRight: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                {/* REPOSITORIO DE INFORMES */}
                {permisos && permisos[Permissions.VER_GAF_GGN] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Repositorio de Informes GGN</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/kb7HQlJEcrQ"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* GEI */}
                {permisos && permisos[Permissions.VER_GAF_GEI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Edificación e Industrial</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/n_-ZPCIzSlY"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GMI */}
                {permisos && permisos[Permissions.VER_GAF_GMI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Minería</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/oajf0idxtIE"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GIN */}
                {permisos && permisos[Permissions.VER_GAF_GIN] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Ingeniería</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/qvQOHin619w"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GZS */}
                {permisos && permisos[Permissions.VER_GAF_GZS] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia Zona Sur</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/tZ5JOpl-_Zg"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GOP */}
                {permisos && permisos[Permissions.VER_GAF_GOP] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Obras Públicas</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/oGzCC-jZHNM"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GPT */}
                {permisos && permisos[Permissions.VER_GAF_GPT] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Proyectos de Transporte</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/pngxizbRF8I"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* PSI */}
                {permisos && permisos[Permissions.VER_GAF_PSI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>PSI Consultores</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/jI0OSy2mqVo"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
              </Grid>
              {/* </ThemeProvider>             */}
            </Grid>
          ) : (
            <Grid container style={{ height: "250px" }}>
              <Grid
                container
                item
                md={12}
                lg={4}
                alignItems="left"
                width="-webkit-fill-available"
                position="absolute"
                style={{ top: "50px" }}
              >
                <img src={imagenCapacitacion} alt="mapa" height="100%" />
              </Grid>

              <Grid
                item
                md={12}
                lg={8}
                width="-webkit-fill-available"
                style={{
                  paddingTop: "0.5rem",
                  paddingRight: "1rem",
                  paddingBottom: "1rem",
                }}
              >
                <Box style={{overflow: "scroll",height: "250px"}}> 
                {/* REPOSITORIO DE INFORMES */}
                {permisos && permisos[Permissions.VER_GAF_GGN] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Repositorio de Informes GGN</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/kb7HQlJEcrQ"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* GEI */}
                {permisos && permisos[Permissions.VER_GAF_GEI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Edificación e Industrial</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/n_-ZPCIzSlY"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GMI */}
                {permisos && permisos[Permissions.VER_GAF_GMI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Minería</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/oajf0idxtIE"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GIN */}
                {permisos && permisos[Permissions.VER_GAF_GIN] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Ingeniería</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/qvQOHin619w"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GZS */}
                {permisos && permisos[Permissions.VER_GAF_GZS] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia Zona Sur</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/tZ5JOpl-_Zg"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GOP */}
                {permisos && permisos[Permissions.VER_GAF_GOP] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Obras Públicas</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/oGzCC-jZHNM"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* GPT */}
                {permisos && permisos[Permissions.VER_GAF_GPT] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Gerencia de Proyectos de Transporte</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/pngxizbRF8I"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                {/* PSI */}
                {permisos && permisos[Permissions.VER_GAF_PSI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>PSI Consultores</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/jI0OSy2mqVo"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                </Box>
              </Grid>
              {/* </ThemeProvider>             */}
            </Grid>
          )}
        </Paper>
      </div>
      {/* Modals de PDF */}
      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModal}
        overflow="scroll"
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Paper>
              <PDFReader direccionPDF={pdfRuta}></PDFReader>
            </Paper>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "69%",
                left: "15%",
                top: "90%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModalPpt}
        overflow="scroll"
        onClose={handleCloseModalPpt}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPpt}>
          <div
            className={classes.paper}
            style={{ minHeight: "-webkit-fill-available" }}
          >
            <Paper
              style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
            >
              {/* <Box style={{paddingTop:"4rem"}}> */}
              <iframe
                style={{ align: "middle" }}
                src={pptRuta}
                // position="fixed"
                frameborder="0"
                width="100%"
                height="100%"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              ></iframe>
              {/* </Box> */}
            </Paper>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "10%",
                left: "80%",
                top: "90%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModalPpt}
              >
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

// export default Bienvenida;
