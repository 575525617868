import React, { useState } from "react";
import { Box, Fab, Paper, Tooltip, Typography } from "@material-ui/core";
import { Launch, SkipNext, SkipPrevious } from "@material-ui/icons";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * Método encargado de retornar un componente para mostrar archivos PDF.
 * @param {*} url URL del PDF.
 * @param {*} scale Escala del documento.
 * @param {*} onClick Método adicional encargado de ejecutarse al hacer click (OPCIONAL).
 * @param {*} showActions TRUE|FALSE Flag para mostrar/ocultar íconos de acciones.
 * @returns Componente para mostrar PDFs.
 */
export default function DisplayPDF(props) {
	const {
		url,
		scale = 1,
		onClick = null,
		showActions = false,
	} = props;

	const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

	/**
	 * Método encargado de redireccionar al PDF de Criterios ESG.
	 */
	const handleRedirect = () => {
		if (onClick) {
			onClick();
		} else {
			window.open(url, "_blank");
		}
	};

	/**
	 * Método encargado de cambiar la página actual.
	 * @param {*} offset 1|-1 dependiendo de si avanza o retrocede la página.
	 */
	const handleChangePage = (offset) => setPageNumber(pageNumber + offset);

	/**
	 * Método encargado de asignar la cantidad total de páginas.
	 * @param {*} numPages Número de páginas.
	 */
	const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

	return (
		<Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
			<Paper elevation={20} onClick={() => !showActions && handleRedirect()}>
				<Page pageNumber={pageNumber} scale={scale} />
			</Paper>
			{showActions && (
				<Box display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ marginTop: 20 }}>
					<Tooltip title="Anterior">
						<Fab onClick={() => handleChangePage(-1)} disabled={pageNumber <= 1} size="small" color="primary">
							<SkipPrevious />
						</Fab>
					</Tooltip>
					<Typography style={{ marginLeft: 20, marginRight: 20 }}>
						{`Página ${pageNumber || (numPages ? 1 : "--")} de ${numPages || "--"}`}
					</Typography>
					<Tooltip title="Siguiente">
						<Fab onClick={() => handleChangePage(1)} disabled={pageNumber >= numPages} size="small" color="primary">
							<SkipNext />
						</Fab>
					</Tooltip>
					<Tooltip title="Abrir en pestaña" style={{ marginLeft: 10, marginRight: 10 }}>
						<Fab onClick={handleRedirect} size="small" color="primary">
							<Launch />
						</Fab>
					</Tooltip>
				</Box>
			)}
		</Document>
	);
}