import React from 'react';
// import Page from "./page";
import { Grid } from '@material-ui/core';

const maqueta =
  "https://storage.googleapis.com/mi-cyd/Voluntariado/voluntariado3.png";

export default function rse() {
      
  // return <Page />;
  return (
    <>
      <Grid container spacing={2} style={{ paddingBottom: 20 }}>
        <img src={maqueta} alt="Maqueta" width="98%" />
      </Grid>
    </>
  );
  }
  