import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { Fragment,useContext, useState } from "react";
import { pdfjs } from "react-pdf";
import PDFReader from "../../Nosotros/PDFReader";
import { isBrowser, isMobile } from "react-device-detect";
import * as Permissions from "../../../constants/permissions";
import { MainContext } from "../../../App";


import { makeStyles } from "@material-ui/core/styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const imagenCapacitacion =
  "https://storage.googleapis.com/mi-cyd/Gerentes/GPO/foto16.png";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  accesos: {
    color: "#731f1f",
    paddingRight: "1rem",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",

    cursor: "grab",
  },
}));

export default function TagGpo() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pdfRuta, setPDFRuta] = React.useState(null);
  const [pptRuta, setPptRuta] = React.useState(null);
  const { usuarioSesion, permisos } = useContext(MainContext);

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModalPpt = () => {
    setOpenModalPpt(true);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <React.Fragment>
      {/* Repositorio de informes */}
      <div style={{
        textAlign: "-webkit-center", paddingTop: "1rem",
        paddingBottom: "1rem",
      }}>
        
          {isMobile ? (
            <Paper
            variant="outlined"
            style={{
              width: "80%",
              borderRadius: "20px",
              overflow: "scroll",
              textAlign: "-webkit-center",
              marginBottom: "1rem",
            }}
          >

            <Grid container style={{ height: "800px" }}>

              <Grid
                item
                md={12}
                lg={8}
                width="-webkit-fill-available"
                style={{
                  paddingTop: "0.5rem",
                  paddingRight: "1rem",
                }}
              >
                {/* Informe Remuneraciones GGN (DS1) */}
                {permisos && permisos[Permissions.VER_REMUN_GGN] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GGN (DS1)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/pKGZR-kgdOQ"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GOP (DS2) */}
                {permisos && permisos[Permissions.VER_REMUN_GOP] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GOP (DS2)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/tX1m_-Bcmnc"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GMI (DS3) */}
                {permisos && permisos[Permissions.VER_REMUN_GMI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GMI (DS3)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/s1jL0rLcFc4"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GIT (DS4) */}
                {permisos && permisos[Permissions.VER_REMUN_GIT] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GIT (DS4)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/mMK0VxQOuds"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GEI (DS5) */}
                {permisos && permisos[Permissions.VER_REMUN_GEI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GEI (DS5)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/v78nG8-S_gg"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GAF (DS6) */}
                {permisos && permisos[Permissions.VER_REMUN_GAF] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GAF (DS6)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/sj3o1vUdphY"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
                

                {/* Dashboard Capacitaciones */}
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{ color: "#731f1f", paddingRight: "1rem" }}
                >
                  <strong>Dashboard Capacitaciones</strong>
                </Typography>
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{
                    color: "black",
                    paddingLeft: "3rem",
                    paddingRight: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Button
                    color="primary"
                    style={{ width: "20%", textAlign: "center" }}
                    variant="contained"
                    href="https://datastudio.google.com/s/kjsa7OYyHKM"
                    target="_blank"
                  >
                    <Typography variant="h7" style={{ flexBasis: "100%" }}>
                      <strong>Ver</strong>
                    </Typography>
                  </Button>
                </Typography>

                {/* Dashboard Desempeño */}
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{ color: "#731f1f", paddingRight: "1rem" }}
                >
                  <strong>Dashboard Desempeño</strong>
                </Typography>
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{
                    color: "black",
                    paddingLeft: "3rem",
                    paddingRight: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Button
                    color="primary"
                    style={{ width: "20%", textAlign: "center" }}
                    variant="contained"
                    href="https://datastudio.google.com/s/oEejpz_0dSA"
                    target="_blank"
                  >
                    <Typography variant="h7" style={{ flexBasis: "100%" }}>
                      <strong>Ver</strong>
                    </Typography>
                  </Button>
                </Typography>
                {/* Reclutamiento */}
                {/* <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{ color: "#731f1f", paddingRight: "1rem" }}
                >
                  <strong>Reclutamiento</strong>
                </Typography>
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{
                    color: "black",
                    paddingLeft: "3rem",
                    paddingRight: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Button
                    color="primary"
                    style={{ width: "20%", textAlign: "center" }}
                    variant="contained"
                    href="https://datastudio.google.com/s/vF8oJvjAJiE"
                    target="_blank"
                  >
                    <Typography variant="h7" style={{ flexBasis: "100%" }}>
                      <strong>Ver</strong>
                    </Typography>
                  </Button>
                </Typography> */}
                {/* Solicitud Modificación de Remuneraciones */}
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{ color: "#731f1f", paddingRight: "1rem" }}
                >
                  <strong>Solicitud Modificación de Remuneraciones</strong>
                </Typography>
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{
                    color: "black",
                    paddingLeft: "3rem",
                    paddingRight: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Button
                    color="primary"
                    style={{ width: "20%", textAlign: "center" }}
                    variant="contained"
                    href="https://forms.gle/oRzz4JZUXADDb9pb9"
                    target="_blank"
                  >
                    <Typography variant="h7" style={{ flexBasis: "100%" }}>
                      <strong>Ver</strong>
                    </Typography>
                  </Button>
                </Typography>
                {/* Clima */}
                {/* <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Clima</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="/"
                  target="_blank"
                  disabled
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography> */}
                {/* Formulario Préstamos Personales */}
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{ color: "#731f1f", paddingRight: "1rem" }}
                >
                  <strong>Formulario Préstamos Personales</strong>
                </Typography>
                <Typography
                  gutterBottom
                  align="right"
                  variant="h6"
                  style={{
                    color: "black",
                    paddingLeft: "3rem",
                    paddingRight: "1rem",
                    marginBottom: "2rem",
                  }}
                >
                  <Button
                    color="primary"
                    style={{ width: "20%", textAlign: "center" }}
                    variant="contained"
                    href="https://forms.gle/zHPEjBKgPsF93aMRA"
                    target="_blank"
                  >
                    <Typography variant="h7" style={{ flexBasis: "100%" }}>
                      <strong>Ver</strong>
                    </Typography>
                  </Button>
                </Typography>
              </Grid>
              {/* </ThemeProvider>             */}
            </Grid>
            </Paper>
          ) : (
            <Paper
            variant="outlined"
            style={{
              width: "80%",
              borderRadius: "20px",
              overflow: "hidden",
              textAlign: "-webkit-center",
              marginBottom: "1rem",
            }}
          >
          <Grid container style={{ height: "600px" }}>
            <Grid
              container
              item
              md={12}
              lg={4}
              alignItems="left"
              width="-webkit-fill-available"
            >
              <img src={imagenCapacitacion} alt="GPO" height="80%" style={{borderRadius: "20px"}} />
            </Grid>

            <Grid
              item
              md={12}
              lg={8}
              width="-webkit-fill-available"
              style={{
                paddingTop: "0.5rem",
                paddingRight: "1rem",
                
                // paddingBottom: "1rem",
              }}
            >
              <Box style={{overflow: "scroll",height: "600px"}}> 
              {/* Informe Remuneraciones GGN (DS1) */}
              {permisos && permisos[Permissions.VER_REMUN_GGN] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GGN (DS1)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/pKGZR-kgdOQ"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GOP (DS2) */}
                {permisos && permisos[Permissions.VER_REMUN_GOP] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GOP (DS2)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/tX1m_-Bcmnc"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GMI (DS3) */}
                {permisos && permisos[Permissions.VER_REMUN_GMI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GMI (DS3)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/s1jL0rLcFc4"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GIT (DS4) */}
                {permisos && permisos[Permissions.VER_REMUN_GIT] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GIT (DS4)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/mMK0VxQOuds"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GEI (DS5) */}
                {permisos && permisos[Permissions.VER_REMUN_GEI] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GEI (DS5)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/v78nG8-S_gg"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}

                {/* Informe Remuneraciones GAF (DS6) */}
                {permisos && permisos[Permissions.VER_REMUN_GAF] && (
                  <Fragment>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      className={classes.accesos}
                    >
                      <strong>Informe Remuneraciones GAF (DS6)</strong>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="right"
                      variant="h6"
                      style={{
                        color: "black",
                        paddingLeft: "3rem",
                        paddingRight: "1rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "20%", textAlign: "center" }}
                        variant="contained"
                        href="https://datastudio.google.com/s/sj3o1vUdphY"
                        target="_blank"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ver</strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Fragment>
                )}
              

              {/* Dashboard Capacitaciones */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Dashboard Capacitaciones</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://datastudio.google.com/s/kjsa7OYyHKM"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>

              {/* Dashboard Desempeño */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Dashboard Desempeño</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://datastudio.google.com/s/oEejpz_0dSA"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>
              {/* Reclutamiento */}
              {/* <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Reclutamiento</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://datastudio.google.com/s/vF8oJvjAJiE"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography> */}
              {/* Solicitud Modificación de Remuneraciones */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Solicitud Modificación<br /> de Remuneraciones</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://forms.gle/oRzz4JZUXADDb9pb9"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>
              {/* Clima */}
              {/* <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Clima</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="/"
                  target="_blank"
                  disabled
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography> */}

              {/* Formulario Préstamo Personales */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Formularios<br /> Préstamos Personales</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://forms.gle/zHPEjBKgPsF93aMRA"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>
              </Box>
            </Grid>
            {/* </ThemeProvider>             */}
          </Grid>
          </Paper>
          )}
      </div>
      {/* Modals de PDF */}
      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModal}
        overflow="scroll"
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Paper>
              <PDFReader direccionPDF={pdfRuta}></PDFReader>
            </Paper>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "69%",
                left: "15%",
                top: "90%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModalPpt}
        overflow="scroll"
        onClose={handleCloseModalPpt}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPpt}>
          <div
            className={classes.paper}
            style={{ minHeight: "-webkit-fill-available" }}
          >
            <Paper
              style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
            >
              {/* <Box style={{paddingTop:"4rem"}}> */}
              <iframe
                style={{ align: "middle" }}
                src={pptRuta}
                // position="fixed"
                frameborder="0"
                width="100%"
                height="100%"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              ></iframe>
              {/* </Box> */}
            </Paper>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "10%",
                left: "80%",
                top: "90%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModalPpt}
              >
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

// export default Bienvenida;
