import React, { Fragment, useEffect, useState } from "react";
import Moment from "moment";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography  
} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { Home } from "@material-ui/icons";
import Carousel from "../../../components/carousel";
import NoticiasHistorial from "./noticiasHistorial";
import "moment/locale/es";

export default function Page(props) {	 	
	const {
		noticia,
		is_loading,
	} = props;

	const [Contenido, SetContenido] = useState([]);

	useEffect(() => {
		if (noticia) {
			let contenido = Array.from(noticia.adjuntos).map((item, index) => ({
				adjunto: noticia.adjuntos[index],
				cuerpo: noticia.cuerpos[index],
				botonTexto: noticia.botonTexto[index],
				botonURL: noticia.botonURL[index],
			}));
			SetContenido(contenido);
		}
	}, [noticia]);

	/**
	 * Método encargado de retornar la fecha de publicación formateada.
	 * @returns Fecha de publicación.
	 */
	const fechaPublicacion = () => Moment(noticia.fecha_publicacion).format("DD/MM/YYYY HH:mm");

	/**
	 * Método encargado de verificar si el TAG Todo CyD está activo.
	 * @returns 
	 */
	const isTodoCyD = () => Array.from(noticia.tags).includes("Todo CyD");

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (!noticia || is_loading) {
		return (
			<Box display="flex" justifyContent="center">
				<CircularProgress />
			</Box>
		);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Button
					component={Link}
					to="/"
					variant="contained"
					color="primary"
					style={{ marginLeft: "10px" }}
				>
					<Home style={{ fontSize: 20 }} />
				</Button>

			</Grid>
			<Grid item xs={12} md={9}>
				<Paper variant="outlined" style={{ borderRadius: 20, marginBottom: 50 }}>
					{/* FECHA Y TAGS */}
					<div style={{ width: "100%" }}>
						<Box display="flex" p={1}>
							{/* FECHA DE PUBLICACIÓN */}
							<Box flexGrow={1}>
								<Typography variant="caption">
									{`Fecha Publicación: ${fechaPublicacion()}`}
								</Typography>
							</Box>

							{/* SI ES PARA TODO CYD */}
							{isTodoCyD() && (
								<Box>
									<Chip label="Todo CyD" size="small" variant="outlined" color="primary" style={{ margin: 2 }} />
								</Box>
							)}

							{/* SI NO ES PARA TODO CYD */}
							{!isTodoCyD() && noticia.tags.map((tag, index) => (
								<Box key={`box_${index}`}>
									<Chip label={tag} size="small" variant="outlined" color="primary" style={{ margin: 2 }} />
								</Box>
							))}
						</Box>
					</div>

					{/* EPÍGRAFE */}
					<Box m={1} align="left">
						<Typography>{noticia.epigrafe}</Typography>
					</Box>

					{/* TÍTULO */}
					<Box m={1}>
						<Typography align="center" variant="h3" style={{ color: "#731f1f" }}>
							{noticia.titulo}
						</Typography>
					</Box>

					{/* BAJADA */}
					{noticia.bajada && (
						<Box m={1}>
							<Typography variant="h6">
								{noticia.bajada}
							</Typography>
						</Box>
					)}

					{/* CONTENIDO CON TEMPLATE 1 */}
					{String(noticia.template).toUpperCase() === TEMPLATE_1 && (
						NoticiaTemplate1(Contenido)
					)}

					{/* CONTENIDO CON TEMPLATE 2 */}
					{String(noticia.template).toUpperCase() === TEMPLATE_2 && (
						NoticiaTemplate2(Contenido)
					)}

					{/* CONTENIDO CON TEMPLATE 3 */}
					{String(noticia.template).toUpperCase() === TEMPLATE_3 && (
						NoticiaTemplate3(Contenido)
					)}
				</Paper>
			</Grid>
			<Hidden xsDown>
			<Grid item xs={3}>
				<NoticiasHistorial />
			</Grid>
			</Hidden>
		</Grid>
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function NoticiaTemplate1(contenido) {
	return (
		Array.from(contenido).map((item, index) => (
			<Grid container spacing={2} key={`grid_${index}`}>
				{/* SI EL CONTENIDO TIENE ADJUNTO */}
				{item && item.adjunto && (
					<Grid item xs={12}>
						{/* SI EL ADJUNTO ES IMAGEN */}
						{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
							<img src={item.adjunto.url} alt="Sin adjunto" style={{ width: "100%" }} />
						)}
						{/* SI EL ADJUNTO ES VIDEO */}
						{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
							<video controls style={{ width: "100%" }}>
								<source src={item.adjunto.url} type="video/mp4" />
								<source src={item.adjunto.url} type="video/ogg" />
							</video>
						)}
					</Grid>
				)}
				{/* SI EL CONTENIDO TIENE CUERPO */}
				{item && item.cuerpo && (
					<Grid item xs={12}>
						<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 10, marginRight: 10 }}>
							<div dangerouslySetInnerHTML={{ __html: item.cuerpo }} />
						</Typography>
					</Grid>
				)}
			</Grid>
		))
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function NoticiaTemplate2(contenido) {
	return (
		<Grid container spacing={2} style={{ paddingBottom: 20 }}>
			{Array.from(contenido).map((item, index) => (
				<Fragment key={`fragment_${index}`}>
					{/* SI EL CONTENIDO TIENE CUERPO */}
					{item.cuerpo && (
						<Grid item xs={8}>
							<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 10, marginRight: 10 }}>
								<div dangerouslySetInnerHTML={{ __html: item.cuerpo }} />
							</Typography>
						</Grid>
					)}

					{/* SI EL CONTENIDO TIENE ADJUNTO */}
					{item.adjunto && (
						<Grid item xs={4}>
							<Box display="flex" alignItems="center" style={{ height: "100%" }}>
								{/* SI EL ADJUNTO ES IMAGEN */}
								{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
									<img src={item.adjunto.url} alt="Sin adjunto" style={{ width: "100%" }} />
								)}
								{/* SI EL ADJUNTO ES VIDEO */}
								{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
									<video controls style={{ width: "100%" }}>
										<source src={item.adjunto.url} type="video/mp4" />
										<source src={item.adjunto.url} type="video/ogg" />
									</video>
								)}
							</Box>
						</Grid>
					)}

					{/* SI EL CONTENIDO INCLUYE BOTÓN PARA REDIRECCIONAR */}
					{item.botonURL && (
						<Grid item xs={12}>
							<Box display="flex" justifyContent="center">
								<Button onClick={() => window.open(item.botonURL, "_blank")} variant="contained" color="primary">
									{item.botonTexto || "Link"}
								</Button>
							</Box>
						</Grid>
					)}

					{/* SEPARADOR */}
					{index !== Array.from(contenido).length - 1 && (
						<Grid item xs={12}>
							<Divider />
						</Grid>
					)}
				</Fragment>
			))}
		</Grid>
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function NoticiaTemplate3(contenido) {
	return (
		<Carousel
			imagenes={Array.from(contenido).map(c => c.adjunto)}
			adicional={Array.from(contenido).map(c => c.cuerpo)}
			autoplay={false}
			show_buttons={true}
			show_indicator={true}
		/>
	);
}

const TEMPLATE_1 = "TEMPLATE_1";
const TEMPLATE_2 = "TEMPLATE_2";
const TEMPLATE_3 = "TEMPLATE_3";
const ACCEPTED_FILES_IMAGE = "image/png, image/jpg, image/jpeg";
const ACCEPTED_FILES_VIDEOS = "video/mp4, video/x-m4v, video/webm";