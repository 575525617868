import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { Card, CardActionArea, CardContent, Grid, Paper, Typography } from "@material-ui/core";
import { analytics } from "../../../services/firebase";

export default function Accesos(props) {
	const {
		claimUser,
		handlePdfOrganigrama,
		handlePptContactosGPO,
	} = props;

	return (
		<Paper elevation={8} variant="outlined" align="center" style={{ borderRadius: "20px", background: "white", height: "auto", paddingTop: "1rem", paddingBottom: "2rem" }}>
			<Typography gutterBottom align="center" variant="h4" style={{ fontWeight: "bold", color: "#731f1f", paddingTop: "1rem", marginBottom: "2rem" }}>
				Explora los siguientes accesos para conocer más sobre CyD
			</Typography>

			<Grid container spacing={1} justifyContent="center" style={{ padding: 10 }}>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemInside(
						"Políticas Corporativas",
						"https://drive.google.com/uc?export=view&id=1Ld2nVIOjZM1QrGHYTMQAVgV6EXvK0YkR",
						"/nosotros#politicas",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemOutside(
						"Introducción a Talana",
						"https://drive.google.com/uc?export=view&id=1zUiQ3xRD3KEf-VAgxgFEwgZbapJpkcNB",
						"https://sites.google.com/cydingenieria.cl/talana",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemOutside(
						"Sistema de Gestión Integrado",
						"https://drive.google.com/uc?export=view&id=1IxFl6iFPutGGEJ6O8YyxxMR_CbgBbFRx",
						"https://sites.google.com/cydingenieria.cl/sgi",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemOutside(
						"Beneficios",
						"https://drive.google.com/uc?export=view&id=12umP1vAtAgyE7yfzSYr_SbuCZ75D-azC",
						"https://sites.google.com/cydingenieria.cl/site-de-beneficios",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemInside(
						"Reconocimientos",
						"https://drive.google.com/uc?export=view&id=1bHPlwswcwCMEWafdipAichnN1lGfoEfQ",
						"/nosotros#reconocimientos",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemFunction(
						"Organigrama",
						"https://drive.google.com/uc?export=view&id=1ooxsmg9JKiZnHU0pJEYVWlEU5XCTO96n",
						handlePdfOrganigrama
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemInside(
						"Galería Fotográfica",
						"https://drive.google.com/uc?export=view&id=13_snBajHjS4FqEUaotwymY4ZmCwNW8xQ",
						"/nosotros#galeriafotografica",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemInside(
						"RSE",
						"https://drive.google.com/uc?export=view&id=1K_YcEKRvl036myDGsXGhj0RT6Yckwzzx",
						"/rse",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemFunction(
						"Contactos",
						"https://storage.googleapis.com/mi-cyd/Bienvenida/Contactos2.png",
						handlePptContactosGPO
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemOutside(
						"Formatos Corporativos",
						"https://drive.google.com/uc?export=view&id=1S1ZPMPAm_756vPPzOBe01OptSc9Se4bR",
						"https://drive.google.com/drive/u/0/folders/0AAvhz91idy1aUk9PVA",
						claimUser
					)}
				</Grid>
				<Grid item xs={6} sm={4} md={3} lg={2}>
					{CardItemOutside(
						"Cápsula Google (Cursos)",
						"https://drive.google.com/uc?export=view&id=1oPOTWIZN4yu9u09NabTm6NhJh23eEFTi",
						"https://sites.google.com/cydingenieria.cl/recomendacionesdigitales",
						claimUser
					)}
				</Grid>
			</Grid>
		</Paper>
	);
}

/**
 * Método encargado de generar un componente Card con link interno.
 * @param {*} text Texto para mostrar.
 * @param {*} img_link Imagen para mostrar.
 * @param {*} action Link interno.
 */
function CardItemInside(text, img_link, action, claimUser_1) {
	return (
		<Card elevation={4} style={{ height: "95%", margin: "5px" }}>
			<CardActionArea
				component={Link}
				to={action}
				style={{ height: "120%" }}
				onClick={() => {
					analytics.logEvent(`Bienvenida_accesos_${text}`, {
						usuarioCorreo: claimUser_1.email,
						usuarioNombre: claimUser_1.name,
						usuarioContrato: claimUser_1.proyecto,
					});
				}}
			>
				<div style={{ height: "50%" }}>
					<img src={img_link} alt="acceso" width="100%" height="100%" />
				</div>
				<CardContent>
					<Typography
						style={{ color: "#731f1f", paddingTop: "0.5rem", fontSize: "14px" }}
						gutterBottom
					>
						{text}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

/**
 * Método encargado de generar un componente Card con link externo.
 * @param {*} text Texto para mostrar.
 * @param {*} img_link Imagen para mostrar.
 * @param {*} action Link externo.
 */
function CardItemOutside(text, img_link, action, claimUser_2) {
	return (
		<Card elevation={4} style={{ height: "95%", margin: "10px" }}>
			<CardActionArea
				href={action}
				target="_blank"
				style={{ height: "120%" }}
				onClick={() => {
					analytics.logEvent(`Bienvenida_accesos_${text}`, {
						usuarioCorreo: claimUser_2.email,
						usuarioNombre: claimUser_2.name,
						usuarioContrato: claimUser_2.proyecto,
					});
				}}
			>
				<div style={{ height: "50%" }}>
					<img src={img_link} alt="acceso" width="100%" height="100%" />
				</div>
				<CardContent>
					<Typography
						style={{ color: "#731f1f", paddingTop: "0.5rem", fontSize: "14px" }}
						gutterBottom
					>
						{text}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

/**
 * Método encargado de generar un componente Card con función.
 * @param {*} text Texto para mostrar.
 * @param {*} img_link Imagen para mostrar.
 * @param {*} action Function a ejecutar.
 */
function CardItemFunction(text, img_link, action) {
	return (
		<Card elevation={4} style={{ height: "95%", margin: "10px" }}>
			<CardActionArea
				component={Link}
				onClick={action}
				style={{ height: "120%" }}
			>
				<div style={{ height: "50%" }}>
					<img src={img_link} alt="acceso" width="100%" height="100%" />
				</div>
				<CardContent>
					<Typography
						style={{ color: "#731f1f", paddingTop: "0.5rem" }}
						gutterBottom
					>
						{text}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}