import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Moment from "moment";
import useSWR from "swr";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MaterialTable from "../../../components/materialTable";
import DialogEliminarNoticia from "./dialogEliminarNoticia";
import PinIcon from "../../../components/pinIcon";
import PinOffIcon from "../../../components/pinOffIcon";
import { ObtenerPublicadas, MostrarOcultarNoticia, PinNoticia, UnpinNoticia } from "./requestComunicados";

function TabPublicadas(props) {
  const { data, isValidating, mutate } = useSWR("publicadas", (key) => ObtenerPublicadas(), { revalidateOnFocus: false });
  const [NoticiaSelect, SetNoticiaSelect] = useState();
  const history = useHistory();
  const notistack = useSnackbar();

  /**
   * Handler para redireccionar y editar una noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleEditar = (event, row) => {
    history.push(`noticia-editar/${row._id}`);
  }

  /**
   * Handler para mostrar u ocultar una noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleMostrarOcultar = (event, row) => {
    MostrarOcultarNoticia(row)
      .then(response => {
        notistack.enqueueSnackbar("Noticia actualizada exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .catch(error => {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar actualizar la noticia.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .finally(() => mutate());
  }

  /**
   * Handler para pinear una noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handlePin = (event, row) => {
    PinNoticia(row)
      .then(response => {
        notistack.enqueueSnackbar("Noticia actualizada exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .catch(error => {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar actualizar la noticia.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .finally(() => mutate());
  }

  /**
   * Handler para despinear una noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleUnpin = (event, row) => {
    UnpinNoticia(row)
      .then(response => {
        notistack.enqueueSnackbar("Noticia actualizada exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .catch(error => {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar actualizar la noticia.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .finally(() => mutate());
  }

  /**
   * Handler para abrir el popup para confirmar eliminación de noticia.
   * @param {*} event Evento.
   * @param {*} row Datos.
   */
  const handleDialogOpen = (event, row) => {
    SetNoticiaSelect(row);
  }

  /**
   * Handler para cerrar el popup para confirmar eliminación de noticia.
   */
  const handleDialogClose = () => {
    SetNoticiaSelect();
    mutate();
  }

  let columns = [
    {
      title: "Título",
      field: "titulo",
    }, {
      title: "Epígrafe",
      field: "epigrafe"
    }, {
      title: "Bajada",
      field: "bajada",
    }, {
      title: "Fecha Publicación",
      field: "fecha_publicacion",
      defaultSort: "desc",
      render: (row) => Moment(row.fecha_publicacion).format("DD/MM/YYYY HH:mm"),
    }
  ];

  let actions = [
    {
      icon: "edit",
      tooltip: "Editar",
      onClick: handleEditar,
    }, row => ({
      icon: row.is_eliminado ? "visibility_off" : "visibility",
      tooltip: row.is_eliminado ? "Mostrar" : "Ocultar",
      onClick: handleMostrarOcultar,
    }), row => ({
      icon: row.is_pinned ? PinIcon : PinOffIcon,
      tooltip: row.is_pinned ? "Quitar Pin" : "Poner Pin",
      onClick: row.is_pinned ? handleUnpin : handlePin,
    }), {
      icon: "delete",
      tooltip: "Eliminar",
      onClick: handleDialogOpen,
    }
  ];

  return (
    <Fragment>
      <MaterialTable
        title="Noticias Publicadas"
        is_loading={!data || isValidating}
        data={data ? data.data : []}
        columns={columns}
        actions={actions}

      />
      <DialogEliminarNoticia
        noticia={NoticiaSelect}
        dialog_close={handleDialogClose}
      />
    </Fragment>
  );
}

export default TabPublicadas;