export const VER_AJUSTES = "verAjustes";
export const VER_AJUSTES_NOTICIA = "verAjustesNoticias";
export const AGREGAR_NOTICIA = "agregarNoticia";
export const EDITAR_NOTICIA = "editarNoticia";
export const AGREGAR_TEMAS_INTERES = "agregarTemasInteres";
export const AGREGAR_IMAGEN_BANNER = "agregarImagenBanner";
export const VER_ACCESOS_GERENTES = "verAccesosGerentes";
export const VER_ACCESOS_RECLUTADORES = "verAccesosReclutadores";
export const AGREGAR_CONTRATO_ADJUDICADO = "agregarContratoAdjudicado";
export const VER_CONTRATACIONES = "verAccesoContrataciones";

//Permisos en Sección GERENTES
// GAF
export const VER_GAF_GGN = "verGAFGGN";
export const VER_GAF_GEI = "verGAFGEI";
export const VER_GAF_GMI = "verGAFGMI";
export const VER_GAF_GIN = "verGAFGIN";
export const VER_GAF_GZS = "verGAFGZS";
export const VER_GAF_GOP = "verGAFGOP";
export const VER_GAF_GPT = "verGAFGPT";
export const VER_GAF_PSI = "verGAFPSI";

//GPO
export const VER_REMUN_GGN = "verRemunGGN";
export const VER_REMUN_GOP = "verRemunGOP";
export const VER_REMUN_GMI = "verRemunGMI";
export const VER_REMUN_GIT = "verRemunGIT";
export const VER_REMUN_GEI = "verRemunGEI";
export const VER_REMUN_GAF = "verRemunGAF";
