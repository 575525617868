import React, {Component, useState} from 'react'
import Loader from './Loader';
import '../../App.css';
import { Document, Page, pdfjs  } from 'react-pdf';
import ControlPanel from './ControlPanel';
import { Box, makeStyles, Modal } from '@material-ui/core';
import ScrollContainer from 'react-indiana-drag-scroll';
import "../../../src/index.css"

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = (props) => {

    const {direccionPDF} = props;
    const [scale, setScale] = useState(1.0);
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
      }

    return (
        <Box style={{backgroundColor:"lightgray"}}>
            <ControlPanel 
            numPages={numPages} 
            pageNumber={pageNumber} 
            setPageNumber={setPageNumber} 
            setScale={setScale} 
            scale={scale}
            />

            {/* <Loader /> */}
            <section id="pdf-section">
                <ScrollContainer className="container" style={{minWidth:"-webkit-fill-available"}}>
        
                    <Box style={{paddingTop:"4rem"}}>
                        
                        <Document
                            file={direccionPDF}
                            onLoadSuccess={onDocumentLoadSuccess}
                            style={{minWidth:"in-width: -webkit-fill-available"}}
                            >
                            <Page pageNumber={pageNumber} scale={scale} />
                        </Document>
                        
                    </Box>
                </ScrollContainer>
            </section>            
        </Box>
    )
}

export default PDFReader
