import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Paper } from "@material-ui/core";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Template1(props) {
	const {
		formik,
	} = props;

	const [RichText, SetRichText] = useState();

	useEffect(() => {
		if (formik.values.cuerpos) {
			const contentBlock = htmlToDraft(formik.values.cuerpos[0] ? formik.values.cuerpos[0] : "");
			if (contentBlock) {
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const editorState = EditorState.createWithContent(contentState);
				SetRichText(editorState);
			}
		}
	}, []);

	useEffect(() => {
		if (RichText) {
			let raw = convertToRaw(RichText.getCurrentContent());
			let html = draftToHtml(raw);
			formik.setFieldValue("cuerpos[0]", html);
		}
	}, [RichText]);

	return (
		<Paper style={{ borderRadius: 20, margin: 10, padding: 10 }}>
			{formik.values.adjuntos && formik.values.adjuntos.length > 0 && (
				<img
					alt={formik.values.adjuntos[0].nombre ? formik.values.adjuntos[0].nombre : ""}
					src={formik.values.adjuntos[0].url ? formik.values.adjuntos[0].url : ""}
					width="100%"
				/>
			)}
			<Editor
				toolbar={{
					options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"],
					inline: {
						options: ["bold", "italic", "underline", "strikethrough"]
					}
				}}
				editorState={RichText}
				onEditorStateChange={SetRichText}
			/>
		</Paper>
	);
}