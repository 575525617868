import React from "react";
import { Typography } from "@material-ui/core";
import { Parallax } from "react-parallax";

/**
 * Método encargado de generar el componente con la imagen y texto de "NOSOTROS".
 * @returns Component.
 */
export default function NosotrosImagen(props) {
	return (
		<Parallax
			bgImage={IMAGE_NOSOTROS_URL}
			strength={150}
			style={{ borderRadius: 25, width: "100%" }}
		>
			<div variant="box" style={{ marginLeft: "2rem", height: "17rem" }}>
				<Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
					NOSOTROS
				</Typography>
			</div>
		</Parallax>
	);
}

/****************************************************************************************
 * 																	CONSTANTES
 ***************************************************************************************/

const IMAGE_NOSOTROS_URL = "https://drive.google.com/uc?export=view&id=1X8yhr4Jq_HHmHshmxV1XZcpXnmB4N4GE";