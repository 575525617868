import React, { Fragment, useState } from "react";
import useSWR from "swr";
import Moment from "moment";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Grid, makeStyles, Typography } from "@material-ui/core";
import { ObtenerNoticiaPinned } from "../requestNoticias";
import { useHistory } from "react-router-dom";
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { analytics, auth } from "../../../services/firebase";

function NoticiaPinned(props) {
  const { data: dataNoticiaPinned, error: errorNoticiaPinned } = useSWR("noticia_pinned", (key) => ObtenerNoticiaPinned(), { revalidateOnFocus: false });
  const classes = useStyles();
  const history = useHistory();

   //analytics
   const [claimUser, setClaimUser] = useState(null);
   const loadUsuarioClaims = async () => {
     let resultToken = auth.currentUser
       .getIdTokenResult()
       .then((getIdTokenResult) => {
         // console.log(getIdTokenResult);
         setClaimUser(getIdTokenResult.claims);
       });
   };

   React.useEffect(() => {
    loadUsuarioClaims();
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {dataNoticiaPinned && dataNoticiaPinned.data && dataNoticiaPinned.data.length > 0 && (
        <Fragment>
          <Grid item md={12} style={{ marginBottom: 10 }}>
            <Typography variant="h4">
              <strong>Noticia Destacada</strong>
            </Typography>
          </Grid>
          <Card
            elevation={5}
            className={classes.root}
            style={{ borderRadius: 15, paddingTop: 0, marginBottom:"2rem" }}
          >            
            <CardActionArea onClick={() => {
              analytics.logEvent(`Home_Noticia_Pinned_${dataNoticiaPinned.data[0]._id}`, {
                usuarioCorreo: claimUser.email,
                usuarioNombre: claimUser.name,
                usuarioContrato: claimUser.proyecto,
              });
              history.push(`/noticia-ver/${dataNoticiaPinned.data[0]._id}`);
            }}
            >
               
              <div style={{backgroundColor:alpha("#263238", 0.5),
              zIndex:2,
              position:"absolute",
              height:"-webkit-fill-available",
              width:"-webkit-fill-available",
              zIndex:9}}></div>          
              <CardMedia
                component="img"
                alt={dataNoticiaPinned.data[0].titulo}
                height="340"
                style={{zIndex:1}}
                image={dataNoticiaPinned.data[0].adjuntos[0].url}
                title={dataNoticiaPinned.data[0].titulo}
              />
                   
              
              <div style={{position:"absolute", top:"20px", left:"20px", color:"white", zIndex:10}}>
                <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    
                    style={{
                      color:"white"
                    }}
                  >
                  {Moment(dataNoticiaPinned.data[0].fecha_publicacion).format("DD/MM/YYYY HH:mm")}
                </Typography>
                <Typography gutterBottom variant="h4" component="h2">
                  <strong>{dataNoticiaPinned.data[0].titulo}</strong>
                </Typography>
                <Typography
                  variant="body1"
              
                  component="p"
                  style={{
                    color:"white"
                  }}
                >
                  {dataNoticiaPinned.data[0].bajada}
                </Typography>
              </div>
              <div style={{position:"absolute", bottom:"20px", left:"20px", color:"white", zIndex:10}}>
              {Array.from(dataNoticiaPinned.data[0].tags).find((t) => t === "Todo CyD")
                && (
                  <Chip
                    label={"Todo CyD"}
                    size="small"
                    variant="default"
                    color="primary"
                    style={{ margin: 2 }}
                  />
                )}
              {!Array.from(dataNoticiaPinned.data[0].tags).find((t) => t === "Todo CyD") && Array.from(dataNoticiaPinned.data[0].tags)
                  .map((t, index) => (
                    <Chip
                      label={t}
                      size="small"
                      variant="default"
                      color="primary"
                      style={{ margin: 2 }}
                      key={`chip_${index}`}
                    />
                  ))}
              </div>
              {/* <CardContent>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  color="textSecondary"
                >
                  {Moment(dataNoticiaPinned.data[0].fecha_publicacion).format("DD/MM/YYYY HH:mm")}
                </Typography>
                <Typography gutterBottom variant="h5" component="h2">
                  {dataNoticiaPinned.data[0].titulo}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                >
                  {dataNoticiaPinned.data[0].bajada}
                </Typography>
              </CardContent> */}
            </CardActionArea>
            
            {/* <CardActions>
              {Array.from(dataNoticiaPinned.data[0].tags).find((t) => t.nombre == "Todo CyD")
                && (
                  <Chip
                    label="Todo CyD"
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ margin: 2 }}
                  />
                )}
              {!Array.from(dataNoticiaPinned.data[0].tags).find((t) => t.nombre == "Todo CyD")
                && Array.from(dataNoticiaPinned.data[0].tags)
                  .map((t, index) => (
                    <Chip
                      label={t}
                      size="small"
                      variant="outlined"
                      color="primary"
                      style={{ margin: 2 }}
                      key={`chip_${index}`}
                    />
                  ))}
            </CardActions>
           */}
          </Card>
        </Fragment>
      )}
    </Fragment>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
  },
}));

export default NoticiaPinned;