import { BACKEND } from "../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener noticias cuyo título incluyen la palabra entregada por parámetro.
 * @param {*} texto Palabra para buscar las noticias.
 * @returns Colección de noticias cuyo título incluyen la palabra entregada por parámetro.
 */
export async function ObtenerNoticias(texto) {
	//TODO: Porqué existe esto aquí?
  try {
    let url = `${BACKEND}/comunicados/titulo/adjunto?titulo_contains=${texto}`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}