import Axios from "axios";
import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";

/**
 * Método encargado de obtener todos los usuarios.
 * @returns Colección de personas.
 */
export async function Obtener() {
	try {
		let url = `${ADMINISTRACION_USUARIOS}/${RUTA_PERSONAS}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de personas en Usuarios.
 */
const RUTA_PERSONAS = "personas";