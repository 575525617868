import React, { Fragment, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ScrollContainer from "react-indiana-drag-scroll";
import { Backdrop, Box, Button, Fade, Grid, Modal, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PDFReader from "../Nosotros/PDFReader";
import { analytics, auth } from "../../services/firebase";
import Imagen from "./components/imagen";
import Presentacion from "./components/presentacion";
import Accesos from "./components/accesos";
import Oficinas from "./components/oficinas";

export default function Bienvenida() {
	const classes = useStyles();
	const [descargaBoton, setDescargaBoton] = useState(false);
	const [pdfRuta, setPDFRuta] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [pptRutaDescarga, setPptRutaDescarga] = useState(null);
	const [pptRuta, setPptRuta] = useState(null);
	const [openModalPpt, setOpenModalPpt] = useState(false);
	const [openModalVideo, setOpenModalVideo] = useState(false);

	const [claimUser, setClaimUser] = useState(null);
	const loadUsuarioClaims = async () => {
		let resultToken = auth.currentUser
			.getIdTokenResult()
			.then((getIdTokenResult) => {
				setClaimUser(getIdTokenResult.claims);
			});
	};

	useEffect(() => {
		loadUsuarioClaims();
	}, []);

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	const handlePdfOrganigrama = () => {
		setPptRuta(
			"https://docs.google.com/presentation/d/e/2PACX-1vS1lrld19j9mRF3k5ihlQSut0eLC3HU6TG26MmSyTJE6I1QMvPZRfkLjbskPf4O2ZretWgBdBjya2ub/embed?start=false&loop=false&delayms=3000&slide=id.g105e7703b9c_1_0"
		);
		analytics.logEvent("Bienvenida_accesos_organigrama", {
			usuarioCorreo: claimUser.email,
			usuarioNombre: claimUser.name,
			usuarioContrato: claimUser.proyecto,
		});

		handleOpenModalPpt();
	};

	const handlePptContactosGPO = () => {
		setPptRuta(
			"https://storage.googleapis.com/mi-cyd/Bienvenida/Contactos%20CyD%20-%20Gerencias.pdf"
		);
		analytics.logEvent("Bienvenida_accesos_contactos_GPO", {
			usuarioCorreo: claimUser.email,
			usuarioNombre: claimUser.name,
			usuarioContrato: claimUser.proyecto,
		});
		handleOpenModalPpt();
	};

	const handleOpenModalPpt = () => {
		setOpenModalPpt(true);
	};

	const handleCloseModalVideo = () => {
		setOpenModalVideo(false);
	};

	const handleCloseModalPpt = () => {
		setOpenModalPpt(false);
		setDescargaBoton(false);
	};

	return (
		<Fragment>
			<Grid container spacing={5}>
				<Grid item xs={12}>
					<Imagen />
				</Grid>

				<Grid item xs={12}>
					<Presentacion />
				</Grid>

				<Grid item xs={12}>
					<Accesos
						claimUser={claimUser}
						handlePdfOrganigrama={handlePdfOrganigrama}
						handlePptContactosGPO={handlePptContactosGPO}
					/>
				</Grid>

				<Grid item xs={12}>
					<Oficinas />
				</Grid>

				<Grid item xs={12}>
					<Presentacion />
				</Grid>

				<Grid item xs={12}>
					<Box display="flex" justifyContent="center">
						<Button
							component="a"
							href="https://cydingenieria.cl/"
							target="_blank"
							variant="contained"
							color="primary"
							style={{ borderRadius: 20, width: isMobile ? "100%" : "40%", padding: 10, marginBottom: 50 }}
						>
							Ir al Sitio de CyD Ingeniería
						</Button>
					</Box>
				</Grid>
			</Grid>

			<Modal
				align="center"
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modalStyle1}
				open={openModalPpt}
				overflow="scroll"
				onClose={handleCloseModalPpt}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openModalPpt}>
					<div
						className={classes.paper}
						style={{ minHeight: "-webkit-fill-available" }}
					>
						<Paper
							style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
						>
							<iframe
								style={{ align: "middle" }}
								src={pptRuta}
								frameborder="0"
								width="100%"
								height="100%"
								allowfullscreen="true"
								mozallowfullscreen="true"
								webkitallowfullscreen="true"
							></iframe>
						</Paper>
						<Box
							zIndex="tooltip"
							style={{
								position: "fixed",
								width: "10%",
								left: "80%",
								top: "90%",
								height: "3rem",
								paddingBottom: "4rem",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={handleCloseModalPpt}
							>
								Cerrar
							</Button>
						</Box>
					</div>
				</Fade>
			</Modal>

			<Modal
				align="center"
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modalStyle1}
				open={openModal}
				overflow="scroll"
				onClose={handleCloseModal}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openModal}>
					<div className={classes.paper}>
						<Paper>
							<PDFReader direccionPDF={pdfRuta}></PDFReader>
						</Paper>
						<Box
							zIndex="tooltip"
							style={{
								position: "fixed",
								width: "69%",
								left: "15%",
								top: "90%",
								height: "3rem",
								paddingBottom: "4rem",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								color="primary"
								onClick={handleCloseModal}
							>
								Cerrar
							</Button>
						</Box>
					</div>
				</Fade>
			</Modal>

			<Modal
				align="center"
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modalStyle1}
				open={openModalPpt}
				overflow="scroll"
				onClose={handleCloseModalPpt}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openModalPpt}>
					<div
						className={classes.paper}
						style={{ minHeight: "-webkit-fill-available" }}
					>
						<Paper
							style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
						>
							<ScrollContainer
								className="container"
								style={{ minWidth: "-webkit-fill-available" }}
							>
								<iframe
									style={{ align: "middle" }}
									src={pptRuta}
									frameborder="0"
									width="100%"
									height="100%"
									allowfullscreen="true"
									mozallowfullscreen="true"
									webkitallowfullscreen="true"
								></iframe>
							</ScrollContainer>
						</Paper>
						<Box
							zIndex="tooltip"
							style={{
								position: "fixed",
								width: "20%",
								left: "70%",
								top: "90%",
								height: "3rem",
								paddingBottom: "4rem",
								justifyContent: "center",
							}}
						>
							{descargaBoton == true && (
								<Button
									variant="contained"
									color="primary"
									href={pptRutaDescarga}
									style={{ marginRight: "1rem" }}
								>
									Descargar
								</Button>
							)}
							<Button
								variant="contained"
								color="primary"
								onClick={handleCloseModalPpt}
							>
								Cerrar
							</Button>
						</Box>
					</div>
				</Fade>
			</Modal>

			<Modal
				align="center"
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modalStyle2}
				open={openModalVideo}
				onClose={handleCloseModalVideo}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={openModalVideo}>
					<div
						className={classes.paper}
					>
						<Box style={{ alignSelf: "center", marginTop: "170px" }}>
							<iframe
								style={{ align: "middle", alignSelf: "center" }}
								src={pptRuta}
								frameborder="0"
								width="900px"
								height="500px"
								allowfullscreen="true"
								mozallowfullscreen="true"
								webkitallowfullscreen="true"
							></iframe>
						</Box>
						<Box
							zIndex="tooltip"
							style={{
								position: "fixed",
								width: "20%",
								left: "65%",
								top: "80%",
								height: "3rem",
								paddingBottom: "4rem",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								color="Primary"
								onClick={handleCloseModalVideo}
								style={{
									fontSize: "1rem",
									color: "#731f1f",
									backgroundColor: "white",
								}}
							>
								<strong>Cerrar</strong>
							</Button>
						</Box>
					</div>
				</Fade>
			</Modal>
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 4,
		color: "#fff",
	},
	modalStyle1: {
		position: "absolute",
		overflow: "scroll",
		height: "auto",
		width: "100%",
		display: "block",
		margin: "auto",
		cursor: "grab",
	},
	modalStyle2: {
		position: "absolute",
		height: "auto",
		width: "70%",
		display: "block",
		margin: "auto",
		cursor: "grab",
	},
}));