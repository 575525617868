import React, { Fragment } from "react";
import { Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import { isBrowser, isMobile } from "react-device-detect";

/**
 * Método encargado de generar el componente para el organigrama y mercado.
 * @returns Component.
 */
export default function Organigrama(props) {
	const {
    handle_organigrama,
    handle_organigrama_Peru,
		handle_analytics,
	} = props;

	return (
    <Paper
      variant="outlined"
      style={{
        overflow: "hidden",
        borderRadius: 25,
        textAlign: "-webkit-center",
      }}
    >
      {/* VERSION MOBILE */}
      {isMobile && (
        <Grid container style={{ height: "auto", alignSelf: "center" }}>
          <Grid item xs={12} width="100%">
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                {Componente("Organigrama CyD Chile", "Ver", handle_organigrama)}
              </Grid>
              <Grid item xs={12}>
                {Componente(
                  "Organigrama CyD Perú",
                  "Ver",
                  handle_organigrama_Peru
                )}
              </Grid>
              <Grid item xs={12}>
                {Componente(
                  "Mercados Y Proyectos",
                  "Mercados y proyectos",
                  handle_analytics
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* VERSION NAVEGADOR */}
      {isBrowser && (
        <Grid
          container
          style={{
            height: "auto",
            alignSelf: "center",
            maxHeight: MAX_HEIGHT,
            paddingBottom: 15,
          }}
        >
          <Grid item xs={2} style={{ textAlign: "left" }}>
            <img
              src={IMAGE_TIPO_ORGANIGRAMA_URL}
              alt="mapa"
              style={{ height: `${MAX_HEIGHT}px` }}
            />
          </Grid>
          <Grid
            item
            sm={8}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center" //"space-between"
              width="100%"
              marginBottom={2}
            >
              <Grid item sm={5} style={{ marginRight: -10 }}>
                {Componente("Organigrama CyD Chile", "Ver", handle_organigrama)}
              </Grid>
              <Grid item sm={5} style={{ marginLeft: -10 }}>
                {Componente(
                  "Organigrama CyD Perú",
                  "Ver",
                  handle_organigrama_Peru
                )}
              </Grid>
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              width="100%"
              style={{ marginTop: 25 }}
            >
              <Grid item sm={5}>
                {Componente(
                  "Mercados y proyectos",
                  "Mercados y proyectos",
                  handle_analytics
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={2} style={{ textAlign: "right" }}>
            <img
              src={IMAGE_TIPO_MERCADOS_URL}
              alt="mercados"
              style={{ height: `${MAX_HEIGHT}px` }}
            />
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}

/**
 * Método encargado de generar un componente reutilizable.
 * @param {*} texto Texto descriptivo.
 * @param {*} boton Texto del botón.
 * @param {*} accion Acción que realiza el botón.
 * @returns Component.
 */
function Componente(texto, boton, accion) {
	return (
		<Fragment>
			<Typography variant="h6" style={{ color: "black", fontWeight: "bold" }}>
				{texto}
			</Typography>
			<Button onClick={accion} color="primary" variant="outlined" style={{ width: "70%", marginTop: 5 }}>
				<Typography variant="h7" style={{ fontWeight: "bold", textTransform: "none" }}>
					{boton}
				</Typography>
			</Button>
		</Fragment>
	);
}

/****************************************************************************************
 * 																	CONSTANTES
 ***************************************************************************************/

const IMAGE_TIPO_ORGANIGRAMA_URL =
  "https://storage.googleapis.com/mi-cyd/nosotros/imagenes/organigrama.png";
const IMAGE_TIPO_MERCADOS_URL =
  "https://storage.googleapis.com/mi-cyd/nosotros/imagenes/mercados.png";
const MAX_HEIGHT = 208;