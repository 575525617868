import React, { useContext, useState } from "react";
import Moment from "moment";
import { useFormik } from "formik";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@material-ui/core";
import FileInput from "../../../components/fileInput";
import { FormikInitialValues, FormikValidationSchema } from "./utils";
import { uploadFileToCloudStorage } from "../../../services/files";
import { Agregar } from "../../../services/requests/mi_cyd/imagenes_banner";
import { MainContext } from "../../../App";

export default function DialogAgregar(props) {
	const {
		handle_close,
	} = props;

	const [Height, SetHeight] = useState(0);
	const [Width, SetWidth] = useState(0);

	const { ShowSnackbar, usuarioSesion } = useContext(MainContext);
	const formik = useFormik({
		initialValues: FormikInitialValues,
		validationSchema: FormikValidationSchema,
		onSubmit: async (values, helper) => {
			try {
				values["_autor_ref"] = usuarioSesion.ref;
				let ahora = Moment().format("DD_MM_YYYY_HH_mm_ss");
				let archivo = await uploadFileToCloudStorage(values.file, "imagenes_banner", `imagen_banner_${ahora}`);
				values.adjunto = archivo;
				await Agregar(values);
				ShowSnackbar("Imagen para el banner subida exitosamente.");
			} catch (error) {
				ShowSnackbar("Error al intentar subir la nueva imagen para el banner.", error);
			} finally {
				helper.resetForm({ values: FormikInitialValues });
				handle_close();
			}
		}
	});

	/**
	 * Método encargado de verificar si el tamaño de la imagen tiene una proporción correcta.
	 * @returns TRUE: Proporción correcta | FALSE: Proporción incorrecta.
	 */
	const CheckProporcionValida = () => {
		if (!Height || !Width) {
			return true;
		}
		let proporcion = Width / Height;
		return proporcion === 17 / 6;
	};

	return (
		<Dialog open={true} onClose={handle_close}>
			<DialogTitle>Agregar imagen</DialogTitle>
			<DialogContent dividers>
				<Typography>Ingrese la información requerida.</Typography>
				<Grid container spacing={2}>
					{/* NOMBRE */}
					<Grid item xs={12}>
						<TextField
							name="nombre"
							label="Nombre"
							value={formik.values.nombre}
							onChange={formik.handleChange}
							variant="outlined"
							size="small"
							fullWidth
							required
							helperText={formik.errors.nombre}
							error={Boolean(formik.errors.nombre)}
						/>
					</Grid>
					{/* LINK EXTERNO */}
					<Grid item xs={12}>
						<TextField
							name="link_externo"
							label="Link externo (opcional)"
							value={formik.values.link_externo}
							onChange={formik.handleChange}
							variant="outlined"
							size="small"
							fullWidth
							helperText={formik.errors.link_externo}
							error={Boolean(formik.errors.link_externo)}
						/>
					</Grid>
					{/* ADJUNTO */}
					<Grid item xs={12}>
						<FileInput
							inputName="file"
							label="Adjunto *"
							handleOnChange={(e) => {
								formik.setFieldValue("file", e.target.files[0]);

								//https://stackoverflow.com/q/67139257
								let img = HTMLImageElement;
								img = document.createElement("img");
								img.onload = function () {
									SetHeight(img.height);
									SetWidth(img.width);
								};
								img.src = URL.createObjectURL(e.target.files[0]);
							}}
							meta={{ touched: formik.touched.file, error: formik.errors.file }}
							accept="image/png, image/jpg, image/jpeg"
							buttonColor="primary"
							buttonVariant="outlined"
							value={formik.values.file}
							textVariant="outlined"
							size="small"
						/>
						{!CheckProporcionValida() && (
							<Typography variant="caption" color="error">
								Solo se permiten imágenes con proporción 17:6. El tamaño ideal es 1700x600 px.
							</Typography>
						)}
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="outlined" color="primary">
					Cancelar
				</Button>
				<Button onClick={formik.submitForm} disabled={formik.isSubmitting || !CheckProporcionValida()} variant="contained" color="primary">
					Aceptar
				</Button>
			</DialogActions>
		</Dialog>
	);
}