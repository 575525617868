import {
  Backdrop,
  Box,
  Button,
  Fade,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import PDFReader from "../../Nosotros/PDFReader";
import { isBrowser, isMobile } from "react-device-detect";

import { makeStyles } from "@material-ui/core/styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const imagenCapacitacion =
  "https://storage.googleapis.com/mi-cyd/Gerentes/Comercial/15_2.png";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",

    cursor: "grab",
  },
}));

export default function Comercial() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pdfRuta, setPDFRuta] = React.useState(null);
  const [pptRuta, setPptRuta] = React.useState(null);

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenModalPpt = () => {
    setOpenModalPpt(true);
  };

  const handleCloseModalPpt = () => {
    setOpenModalPpt(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  return (
    <React.Fragment>
      {/* Repositorio de informes */}
      <div style={{ textAlign: "-webkit-center", paddingTop: "1rem", 
            paddingBottom:"1rem", }}>
        <Paper
          variant="outlined"
          style={{
            width: "80%",
            borderRadius: "20px",
            overflow: "hidden",
            textAlign: "-webkit-center",
            marginBottom: "1rem",
          }}
        >
          {isMobile ? (
          <Grid container style={{ height: "420px" }}>
            

            <Grid
              item
              md={12}
              lg={8}
              width="-webkit-fill-available"
              style={{
                paddingTop: "0.5rem",
                paddingRight: "1rem",
                paddingBottom: "1rem",
              }}
            >
              {/* Reportes Remuneraciones */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>CyDocs Comercial</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://cydcomercial-2247d.firebaseapp.com/"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>

              {/* Dashboard Capacitaciones */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Encuesta de Satisfacción Clientes</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://encuestas.cydocs.cl/"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>

              {/* Dashboard Comité Comercial */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem" }}
              >
                <strong>Comité Comercial</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://drive.google.com/drive/folders/1fWDCRlzgpod4zls0w3fDzy9InCl0SpaB"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>
              {/* Reclutamiento */}
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{ color: "#731f1f", paddingRight: "1rem"}}
              >
                <strong>Junta de Ventas</strong>
              </Typography>
              <Typography
                gutterBottom
                align="right"
                variant="h6"
                style={{
                  color: "black",
                  paddingLeft: "3rem",
                  paddingRight: "1rem",
                  marginBottom: "2rem",
                }}
              >
                <Button
                  color="primary"
                  style={{ width: "20%", textAlign: "center" }}
                  variant="contained"
                  href="https://drive.google.com/drive/folders/1ppWYQY6bnX3djD_KSlTQzJAXVbPz9Xml?usp=sharing"
                  target="_blank"
                >
                  <Typography variant="h7" style={{ flexBasis: "100%" }}>
                    <strong>Ver</strong>
                  </Typography>
                </Button>
              </Typography>
               </Grid>
            {/* </ThemeProvider>             */}
          </Grid>
          ) :( <Grid container style={{ height: "420px" }}>
          <Grid
            container
            item
            md={12}
            lg={4}
            alignItems="left"
            width="-webkit-fill-available"
          >
            <img src={imagenCapacitacion} alt="GPO" height="100%" />
          </Grid>

          <Grid
            item
            md={12}
            lg={8}
            width="-webkit-fill-available"
            style={{
              paddingTop: "0.5rem",
              paddingRight: "1rem",
              paddingBottom: "1rem",
            }}
          >
            {/* Reportes Remuneraciones */}
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{ color: "#731f1f", paddingRight: "1rem" }}
            >
              <strong>CyDocs Comercial</strong>
            </Typography>
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{
                color: "black",
                paddingLeft: "3rem",
                paddingRight: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%", textAlign: "center" }}
                variant="contained"
                href="https://cydcomercial-2247d.firebaseapp.com/"
                target="_blank"
              >
                <Typography variant="h7" style={{ flexBasis: "100%" }}>
                  <strong>Ver</strong>
                </Typography>
              </Button>
            </Typography>

            {/* Dashboard Capacitaciones */}
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{ color: "#731f1f", paddingRight: "1rem" }}
            >
              <strong>Encuesta de Satisfacción Clientes</strong>
            </Typography>
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{
                color: "black",
                paddingLeft: "3rem",
                paddingRight: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%", textAlign: "center" }}
                variant="contained"
                href="https://encuestas.cydocs.cl/"
                target="_blank"
              >
                <Typography variant="h7" style={{ flexBasis: "100%" }}>
                  <strong>Ver</strong>
                </Typography>
              </Button>
            </Typography>

            {/* Dashboard Desempeño */}
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{ color: "#731f1f", paddingRight: "1rem" }}
            >
              <strong>Comité Comercial</strong>
            </Typography>
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{
                color: "black",
                paddingLeft: "3rem",
                paddingRight: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%", textAlign: "center" }}
                variant="contained"
                href="https://drive.google.com/drive/folders/1fWDCRlzgpod4zls0w3fDzy9InCl0SpaB"
                target="_blank"
              >
                <Typography variant="h7" style={{ flexBasis: "100%" }}>
                  <strong>Ver</strong>
                </Typography>
              </Button>
            </Typography>
            {/* Reclutamiento */}
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{ color: "#731f1f", paddingRight: "1rem"}}
            >
              <strong>Junta de Ventas</strong>
            </Typography>
            <Typography
              gutterBottom
              align="right"
              variant="h6"
              style={{
                color: "black",
                paddingLeft: "3rem",
                paddingRight: "1rem",
                marginBottom: "2rem",
              }}
            >
              <Button
                color="primary"
                style={{ width: "20%", textAlign: "center" }}
                variant="contained"
                href="https://drive.google.com/drive/folders/1ppWYQY6bnX3djD_KSlTQzJAXVbPz9Xml?usp=sharing"
                target="_blank"
              >
                <Typography variant="h7" style={{ flexBasis: "100%" }}>
                  <strong>Ver</strong>
                </Typography>
              </Button>
            </Typography>
             </Grid>
          {/* </ThemeProvider>             */}
        </Grid>
     )}
        </Paper>
      </div>
      {/* Modals de PDF */}
      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModal}
        overflow="scroll"
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Paper>
              <PDFReader direccionPDF={pdfRuta}></PDFReader>
            </Paper>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "69%",
                left: "15%",
                top: "90%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModal}
              >
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>

      <Modal
        align="center"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalStyle1}
        open={openModalPpt}
        overflow="scroll"
        onClose={handleCloseModalPpt}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModalPpt}>
          <div
            className={classes.paper}
            style={{ minHeight: "-webkit-fill-available" }}
          >
            <Paper
              style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
            >
              {/* <Box style={{paddingTop:"4rem"}}> */}
              <iframe
                style={{ align: "middle" }}
                src={pptRuta}
                // position="fixed"
                frameborder="0"
                width="100%"
                height="100%"
                allowfullscreen="true"
                mozallowfullscreen="true"
                webkitallowfullscreen="true"
              ></iframe>
              {/* </Box> */}
            </Paper>
            <Box
              zIndex="tooltip"
              style={{
                position: "fixed",
                width: "10%",
                left: "80%",
                top: "90%",
                height: "3rem",
                paddingBottom: "4rem",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleCloseModalPpt}
              >
                Cerrar
              </Button>
            </Box>
          </div>
        </Fade>
      </Modal>
    </React.Fragment>
  );
}

// export default Bienvenida;
