import { gapi } from "gapi-script";

export async function getGmailMails() {
	try {
		const config = {
			discoveryDocs: [
				"https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
			],
		};
		let scopes = "https://mail.google.com/ " +
			"https://www.googleapis.com/auth/gmail.readonly " +
			"https://www.googleapis.com/auth/gmail.modify " +
			"https://www.googleapis.com/auth/gmail.metadata " +
			"https://www.googleapis.com/auth/gmail.addons.current.message.metadata " +
			"https://www.googleapis.com/auth/gmail.addons.current.message.readonly " +
			"https://www.googleapis.com/auth/gmail.addons.current.message.action";

		console.log("initApiGmail...");

		let initApi = await gapi.client.init({
			apiKey: "AIzaSyBs_oN1-rPljWd9OzLdMhVJ83Z8puMxqw8",
			clientId:
				"908645375548-ckon98i6rf2j9btll81vqq3m218lghs5.apps.googleusercontent.com",
			discoveryDocs: config.discoveryDocs,
			scope: scopes,
		});

		console.log("initApiGMAIL...ok");

		let resultadoCorreos = await gapi.client.gmail.users.messages.list({
			userId: "me",
			labelIds: "INBOX",
			maxResults: 10,
		});
		console.log(resultadoCorreos.result.messages[0].id);
		var messageRequest = gapi.client.gmail.users.messages.get({
			userId: "me",
			id: "1785b1bc6c642d8e",
			format: "full",
		});

		// let resultadoList = await gapi.client.calendar.calendarList.list({
		//   maxResults: 10,
		//   minAccessRole: "reader",
		//   showDeleted: false,
		// });

		console.log(resultadoCorreos);
		console.log(messageRequest);
		// console.log(resultadoList);
		return resultadoCorreos;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de abrir una pestaña para mandar email desde Gmail.
 * @param {*} to Email del destinatario, es requerido.
 * @param {*} cc Email para copiar, es opcional.
 * @param {*} bcc Email para copia oculta, es opcional.
 * @param {*} subject Asunto del email.
 * @param {*} body Contenido del email.
 * @link https://stackoverflow.com/a/8852679 Referencia.
 */
export function SendGmail(to, cc = null, bcc = null, subject = "", body = "") {
	let url = `https://mail.google.com/mail/?view=cm&fs=1&to=${to}&su=${subject}&body=${body}`;
	if (cc) {
		url = `${url}&cc=${cc}`;
	}
	if (bcc) {
		url = `${url}&bcc=${bcc}`;
	}
	window.open(url, "_blank");
}