import Axios from 'axios';
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de obtener el usuario-plataforma de un usuario.
 * @param {*} usuarioID UsuarioID.
 * @returns Usuario-plataforma o null.
 */
export async function ObtenerUsuarioPlataforma(usuarioID) {
  try {
    let url = `${BACKEND}/usuarios-plataforma?uid_eq=${usuarioID}`;
    let response = await Axios.get(url);
    return response.data ? response.data.data[0] : null;
  } catch (error) {
    console.error(error);
    throw error;
  }
}