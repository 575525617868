import React, { Fragment, useContext, useState } from "react";
import Moment from "moment";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField, Typography } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import FileInput from "../../../components/fileInput";
import { Upload } from "../../../services/googleStorage";
import { ACCEPTED_FILES_IMAGE, ACCEPTED_FILES_VIDEOS, TEMPLATES, URL_PATTERN } from "./utils";
import { NoticiaAgregarContext } from "./index";
import { MainContext } from "../../../App";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function DialogAdd(props) {
	const {
		is_open,
		template,
		handle_close,
	} = props;

	const [SelectedFile, SetSelectedFile] = useState(null);
	const [FileSize, SetFileSize] = useState({ height: 0, width: 0 });
	const [RichText, SetRichText] = useState(null);
	const [ButtonText, SetButtonText] = useState("");
	const [ButtonURL, SetButtonURL] = useState("");
	const [Loading, SetLoading] = useState(false);
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);
	const MainCTX = useContext(MainContext);

	const handleAgregar = async () => {
		try {
			SetLoading(true);
			let nuevoAdjunto = null;

			if (SelectedFile) {
				let nombreFoto = "foto_" + Moment().locale("es").format("DD_MM_YYYY_HH_mm_ss");
				let uploadedFile = await Upload(SelectedFile, nombreFoto);
				nuevoAdjunto = {
					nombre: SelectedFile.name,
					repositorio: uploadedFile.bucket,
					url: uploadedFile.pathname,
					size: uploadedFile.size,
					mime_type: uploadedFile.content_type,
					path: "",
				}
			}

			NoticiaAgregarCTX.formik.setValues({
				...NoticiaAgregarCTX.formik.values,
				contenido: [...NoticiaAgregarCTX.formik.values.contenido, {
					adjunto: nuevoAdjunto,
					cuerpo: RichText,
					botonTexto: ButtonText,
					botonURL: ButtonURL,
				}],
			});
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar agregar el adjunto.", error);
		} finally {
			SetSelectedFile(null);
			SetRichText(null);
			SetLoading(false);
			handle_close();
		}
	}

	/**
	 * Método encargado de verificar si el tamaño de la imagen tiene una proporción correcta.
	 * @returns TRUE: Proporción correcta | FALSE: Proporción incorrecta.
	 */
	const CheckProporcionValida = () => SelectedFile && ACCEPTED_FILES_IMAGE.includes(SelectedFile.type) ? FileSize.width / FileSize.height === 10 / 5 : true;

	/**
	 * Método encargado de verificar si el template seleccionado es el TEMPLATE 2.
	 * @returns TRUE: Es tipo Template 2 | FALSE: No es tipo Template 2.
	 */
	const CheckTemplate2 = () => template === TEMPLATES.template2.nombre;

	/**
	 * Método encargado de verificar si el template seleccionado es el TEMPLATE 2.
	 * @returns TRUE: Es tipo Template 3 | FALSE: No es tipo Template 3.
	 */
	const CheckTemplate3 = () => template === TEMPLATES.template3.nombre;

	/**
	 * Método encargado de retornar los formatos de archivos aceptados.
	 * @returns Formatos de archivos acceptador.
	 */
	const AcceptedFiles = () => CheckTemplate3() ? `${ACCEPTED_FILES_IMAGE}, ${ACCEPTED_FILES_VIDEOS}` : ACCEPTED_FILES_IMAGE;

	/**
	 * 
	 * @returns TRUE: Botón habilitado | FALSE: Botón inhabilitado.
	 */
	const CheckDisabled = () => {
		if (CheckTemplate2() && ButtonText && ButtonURL) {
			return !SelectedFile || !CheckProporcionValida() || Loading || ButtonText.length < 3 || !URL_PATTERN.test(ButtonURL);
		} else {
			return !SelectedFile || !CheckProporcionValida() || Loading;
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close}>
			<DialogTitle>Imagen y Cuerpo</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Seleccione un archivo (con proporciones 10:5, por ejemplo 1000x500 pixeles) y agregue un cuerpo a la imagen (OPCIONAL).
				</DialogContentText>

				<Grid container spacing={2}>
					{/* DOCUMENTO ADJUNTO */}
					<Grid item xs={12}>
						<FileInput
							inputName="file"
							label="Adjunto *"
							value={SelectedFile}
							handleOnChange={(e) => {
								let file = e.target.files[0];
								file["preview"] = URL.createObjectURL(file);

								SetSelectedFile(file);

								if (ACCEPTED_FILES_IMAGE.includes(file.type)) {
									let img = HTMLImageElement;
									img = document.createElement("img");
									img.onload = function () {
										SetFileSize({ height: img.height, width: img.width });
									};
									img.src = URL.createObjectURL(e.target.files[0]);
								}
							}}
							accept={AcceptedFiles()}
							buttonColor="primary"
							buttonVariant="outlined"
							textVariant="outlined"
							size="small"
						/>
						{!CheckProporcionValida() && (
							<Typography variant="caption" color="error" style={{ marginLeft: 15 }}>
								Solo se permiten imágenes con proporción 10:5. El tamaño ideal es 1000x500 px.
							</Typography>
						)}
					</Grid>

					{/* IMAGEN PREVIEW */}
					{SelectedFile && SelectedFile.preview && (
						<Grid item xs={12}>
							{ACCEPTED_FILES_IMAGE.includes(SelectedFile.type) && (
								<img src={SelectedFile.preview} style={{ width: "50%", display: "block", marginTop: 10, marginBottom: 10, marginLeft: "auto", marginRight: "auto" }}></img>
							)}
							{ACCEPTED_FILES_VIDEOS.includes(SelectedFile.type) && (
								<video controls style={{ width: "50%", display: "block", marginTop: 10, marginBottom: 10, marginLeft: "auto", marginRight: "auto" }}>
									<source src={SelectedFile.preview} type="video/mp4" />
									<source src={SelectedFile.preview} type="video/ogg" />
								</video>
							)}
						</Grid>
					)}

					{/* URL DE BOTÓN (OPCIONAL) */}
					{template === TEMPLATES.template2.nombre && (
						<Fragment>
							<Grid item xs={4}>
								<TextField
									name="url"
									label="Texto del botón"
									value={ButtonText}
									onChange={(event) => SetButtonText(event.target.value)}
									variant="outlined"
									size="small"
									fullWidth
									helperText={ButtonText && ButtonText.length < 3 && "Texto muy corto."}
									error={Boolean(ButtonText) && ButtonText.length < 3}
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField
									name="url"
									label="URL del botón"
									value={ButtonURL}
									onChange={(event) => SetButtonURL(event.target.value)}
									variant="outlined"
									size="small"
									fullWidth
									helperText={ButtonURL && !URL_PATTERN.test(ButtonURL) && "Formato de URL incorrecto."}
									error={Boolean(ButtonURL) && !URL_PATTERN.test(ButtonURL)}
								/>
							</Grid>
						</Fragment>
					)}

					{/* RICH TEXT */}
					<Grid item xs={12}>
						<Paper elevation={4}>
							<Editor
								toolbar={{
									options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"],
									inline: {
										options: ["bold", "italic", "underline", "strikethrough"]
									}
								}}
								editorState={RichText}
								onEditorStateChange={SetRichText}
								editorStyle={{ minHeight: 150, margin: 20 }}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="outlined" color="primary">
					Cancelar
				</Button>
				<Button onClick={handleAgregar} disabled={CheckDisabled()} endIcon={Loading && <CircularProgress size={24} />} variant="contained" color="primary">
					Agregar
				</Button>
			</DialogActions>
		</Dialog>
	);
}