import React from "react";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { useFormik } from "formik";
import { object, string, date } from "yup";

export default function Page(props) {
  const { handle_publicar } =
    props;

  const formik = useFormik({
    initialValues: {
      fecha_adjudicacion: null,
      nombre: "",
      cliente: "",
      gerencia: "",
    },
    validationSchema: object().shape({
      fecha_adjudicacion: date().nullable().required("La fecha es requerida."),
      nombre: string()
        .min(3, "El nombre debe tener al menos ${min} caracteres.")
        .max(100, "El nombre debe tener a lo más ${max} caracteres.")
        .required("El nombre es requerido."),
      cliente: string()
        .min(3, "El cliente debe tener al menos ${min} caracteres.")
        .max(350, "El cliente debe tener a lo más ${max} caracteres.")
        .required("El cliente es requerido."),      
      gerencia: string()
        .min(3, "la gerencia debe tener al menos ${min} caracteres.")
        .max(300, "la gerencia debe tener a lo más ${max} caracteres.")
        .required("la gerencia es requerida."),
    }),
    onSubmit: (values, helper) => {
      try {
        handle_publicar(values);
      } catch (error) {
        console.error(error);
      } finally {
        handleCancel();
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm({
      values: {
        fecha_adjudicacion: null,
        nombre: "",
        cliente: "",
        gerencia: "",
      },
    });
  };


  return (
    <React.Fragment>
      <Paper style={{ padding: "30px", margin: "20px" }}>
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Typography
              style={{
                fontSize: "2rem",
                color: "#731f1f",
                textAlign: "center",
              }}
            >
              Ingresar Contrato Adjudicado
            </Typography>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
            

          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={4}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Fecha de adjudicación:</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <DatePicker

                  label="Fecha de Adjudicación"
                  value={formik.values.fecha_adjudicacion}
                  onChange={(date) =>
                    formik.setFieldValue("fecha_adjudicacion", date)
                  }
                  error={Boolean(formik.errors.fecha_adjudicacion)}
                  helperText={formik.errors.fecha_adjudicacion}
                  okLabel="Aceptar"
                  cancelLabel="Cancelar"
                  inputVariant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={4}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Nombre del Contrato:</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="nombre"
                  label="Nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.nombre)}
                  helperText={formik.errors.nombre}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={4}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Cliente:</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="cliente"
                  label="Cliente"
                  value={formik.values.cliente}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.cliente)}
                  helperText={formik.errors.cliente}
                  variant="outlined"
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{marginBottom:"5px", marginTop:"5px"}}>
            <Grid container>
              <Grid
                item
                xs={4}
                style={{ alignSelf: "center", textAlign: "center" }}
              >
                <Typography
                  style={{ alignSelf: "center", marginRight: "20px", color:"#731f1f" }}
                >
                  <strong>Gerencia: </strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  name="gerencia"
                  label="Gerencia"
                  value={formik.values.gerencia}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.gerencia)}
                  helperText={formik.errors.gerencia}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          
            </Grid>
          </Grid>
          

          <Grid
            item
            xs={12}
            component={Box}
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              onClick={formik.submitForm}
              variant="contained"
              color="primary"
            >
              Agregar
            </Button>
            <Button onClick={handleCancel} color="primary">
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
