import React, { Fragment, useContext, useState } from "react";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Box, Button, Fab, Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import Carousel from "../../../components/carousel";
import DialogAdd from "./dialogAdd";
import DialogEdit from "./dialogEdit";
import { ACCEPTED_FILES_IMAGE, ACCEPTED_FILES_VIDEOS, TEMPLATES } from "./utils";
import { NoticiaAgregarContext } from "./index";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Paso2(props) {
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);
	const classes = useStyles();
	const [ShowAdd, SetShowAdd] = useState(false);
	const [SelectedItem, SetSelectedItem] = useState(null);

	/**
	 * Método encargado de verificar si el botón está habilitado.
	 * @returns TRUE: Botón habilitado | FALSE: Botón deshabilitado.
	 */
	const CheckDisabled = () => {
		let cantidad = Array.from(NoticiaAgregarCTX.formik.values.contenido || []).length;
		if (NoticiaAgregarCTX.formik.values.template === TEMPLATES.template1.nombre) {
			return cantidad >= 1;
		} else {
			return false;
		}
	}

	/**
	 * Método encargado de asignar un elemento como seleccionado para editar.
	 * @param {*} item Datos del elemento.
	 * @param {*} index Posición del elemento.
	 */
	const handleEditar = (item, index) => SetSelectedItem({ ...item, index });

	return (
		<Fragment>
			<Grid container spacing={2}>
				{/* VISTA PREVIA DE NOTICIA */}
				<Grid item xs={8}>
					{/* EPÍGRAFE */}
					{NoticiaAgregarCTX.formik.values.epigrafe && (
						<Box m={1}>
							<Typography variant="h6">
								{NoticiaAgregarCTX.formik.values.epigrafe}
							</Typography>
						</Box>
					)}

					{/* TÍTULO */}
					<Box m={1}>
						<Typography align="center" variant="h3" style={{ color: "#731f1f" }}>
							{NoticiaAgregarCTX.formik.values.titulo}
						</Typography>
					</Box>

					{/* BAJADA */}
					{NoticiaAgregarCTX.formik.values.bajada && (
						<Box m={1}>
							<Typography variant="h6">
								{NoticiaAgregarCTX.formik.values.bajada}
							</Typography>
						</Box>
					)}

					{/* CONTENIDO CON TEMPLATE 1 */}
					{NoticiaAgregarCTX.formik.values.template === TEMPLATES.template1.nombre && (
						Paso2Template1(NoticiaAgregarCTX.formik.values.contenido)
					)}

					{/* CONTENIDO CON TEMPLATE 2 */}
					{NoticiaAgregarCTX.formik.values.template === TEMPLATES.template2.nombre && (
						Paso2Template2(NoticiaAgregarCTX.formik.values.contenido)
					)}

					{/* CONTENIDO CON TEMPLATE 3 */}
					{NoticiaAgregarCTX.formik.values.template === TEMPLATES.template3.nombre && (
						Paso2Template3(NoticiaAgregarCTX.formik.values.contenido)
					)}
				</Grid>

				{/* FORMULARIO DE NOTICIA */}
				<Grid item xs={4}>
					<Paper style={{ padding: 10 }}>
						<Grid container spacing={2} style={{ maxHeight: "70vh", overflowY: "auto" }}>
							<Grid item xs={12}>
								<Box display="flex" justifyContent="center">
									<Typography color="primary" variant="h6">
										Ingrese los campos de la notica
									</Typography>
								</Box>
							</Grid>
							{/* EPIGRAFE */}
							<Grid item xs={12}>
								<TextField
									name="epigrafe"
									label="Epígrafe (Opcional)"
									value={NoticiaAgregarCTX.formik.values.epigrafe}
									onChange={NoticiaAgregarCTX.formik.handleChange}
									variant="outlined"
									size="small"
									multiline
									fullWidth
									className={classes.cuadro}
								/>
							</Grid>
							{/* TITULO */}
							<Grid item xs={12}>
								<TextField
									name="titulo"
									label="Título"
									value={NoticiaAgregarCTX.formik.values.titulo}
									onChange={NoticiaAgregarCTX.formik.handleChange}
									variant="outlined"
									size="small"
									fullWidth
									className={classes.cuadro}
								/>
							</Grid>
							{/* BAJADA */}
							<Grid item xs={12}>
								<TextField
									name="bajada"
									label="Bajada (Opcional)"
									value={NoticiaAgregarCTX.formik.values.bajada}
									onChange={NoticiaAgregarCTX.formik.handleChange}
									variant="outlined"
									size="small"
									multiline
									fullWidth
									className={classes.cuadro}
								/>
							</Grid>
							<Grid item xs={12}>
								<Button onClick={() => SetShowAdd(true)} disabled={CheckDisabled()} variant="contained" color="secondary" fullWidth>
									Agregar adjunto
								</Button>
							</Grid>

							<Grid item xs={12}>
								{ListadoDnD(NoticiaAgregarCTX.formik, handleEditar)}
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>

			{/* DIALOGO PARA AGREGAR ADJUNTO */}
			{ShowAdd && (
				<DialogAdd
					is_open={ShowAdd}
					template={NoticiaAgregarCTX.formik.values.template}
					handle_close={() => SetShowAdd(false)}
				/>
			)}

			{/* DIALOGO PARA EDITAR ADJUNTO */}
			{SelectedItem && (
				<DialogEdit
					contenido={SelectedItem}
					is_open={Boolean(SelectedItem)}
					template={NoticiaAgregarCTX.formik.values.template}
					handle_close={() => SetSelectedItem(null)}
				/>
			)}
		</Fragment>
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function Paso2Template1(contenido) {
	return (
		Array.from(contenido).map((item, index) => (
			<Paper key={`paper_${index}`}>
				<Grid container spacing={2}>
					{/* SI EL CONTENIDO TIENE ADJUNTO */}
					{item && item.adjunto && (
						<Grid item xs={12}>
							{/* SI EL ADJUNTO ES IMAGEN */}
							{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
								<img src={item.adjunto.url} alt="Sin adjunto" style={{ width: "100%" }} />
							)}
							{/* SI EL ADJUNTO ES VIDEO */}
							{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
								<video controls style={{ width: "100%" }}>
									<source src={item.adjunto.url} type="video/mp4" />
									<source src={item.adjunto.url} type="video/ogg" />
								</video>
							)}
						</Grid>
					)}
					{/* SI EL CONTENIDO TIENE CUERPO */}
					{item && item.cuerpo && (
						<Grid item xs={12}>
							<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 10, marginRight: 10 }}>
								<div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(item.cuerpo.getCurrentContent())) }} />
							</Typography>
						</Grid>
					)}
				</Grid>
			</Paper>
		))
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function Paso2Template2(contenido) {
	return (
		Array.from(contenido).map((item, index) => (
			<Paper style={{ marginBottom: 20, marginTop: 20, paddingBottom: 10, paddingTop: 10 }} key={`paper_${index}`}>
				<Grid container spacing={2}>
					{/* SI EL CONTENIDO TIENE CUERPO */}
					{item.cuerpo && (
						<Grid item xs={8}>
							<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 10, marginRight: 10 }}>
								<div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(item.cuerpo.getCurrentContent())) }} />
							</Typography>
						</Grid>
					)}
					{/* SI EL CONTENIDO TIENE ADJUNTO */}
					{item.adjunto && (
						<Grid item xs={4}>
							<Box display="flex" alignItems="center" style={{ height: "100%" }}>
								{/* SI EL ADJUNTO ES IMAGEN */}
								{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
									<img src={item.adjunto.url} alt="Sin adjunto" style={{ width: "100%" }} />
								)}
								{/* SI EL ADJUNTO ES VIDEO */}
								{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
									<video controls style={{ width: "100%" }}>
										<source src={item.adjunto.url} type="video/mp4" />
										<source src={item.adjunto.url} type="video/ogg" />
									</video>
								)}
							</Box>
						</Grid>
					)}
					{/* SI EL CONTENIDO INCLUYE BOTÓN PARA REDIRECCIONAR */}
					{item.botonURL && (
						<Grid item xs={12}>
							<Box display="flex" justifyContent="center">
								<Button onClick={() => window.open(item.botonURL, "_blank")} variant="contained" color="primary">
									{item.botonTexto || "Link"}
								</Button>
							</Box>
						</Grid>
					)}
				</Grid>
			</Paper>
		))
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function Paso2Template3(contenido) {
	return (
		<Carousel
			imagenes={Array.from(contenido).map(c => c.adjunto)}
			adicional={Array.from(contenido).map(c => draftToHtml(convertToRaw(c.cuerpo.getCurrentContent())))}
			autoplay={false}
			show_buttons={true}
			show_indicator={true}
		/>
	);
}

/**
 * Método encargado de generar el componente listado que usar librería Drag&Drop para mover y reorganizar.
 * @param {*} formik Datos del Formik.
 * @param {*} handleEditar Método encargado de editar datos.
 * @returns Componente.
 */
function ListadoDnD(formik, handleEditar) {
	/**
	 * Método encargado de eliminar un elemento del listado, por su index.
	 * @param {*} index Posición del elemento a eliminar.
	 */
	const handleEliminar = (index) => {
		const items = Array.from(formik.values.contenido);
		items.splice(index, 1);
		formik.setFieldValue("contenido", items);
	}

	/**
	 * Método encargado de reorganizar los elementos.
	 * @param {*} item Elemento que se mueve. 
	 */
	const onDragEnd = (item) => {
		if (item.destination) {
			const items = Array.from(formik.values.contenido);
			const [removed] = items.splice(item.source.index, 1);
			items.splice(item.destination.index, 0, removed);
			formik.setFieldValue("contenido", items);
		}
	}

	/**
	 * Método encargado de retornar un estilo para el listado de elementos del DnD.
	 * @param {*} isDraggingOver Flag.
	 * @returns Estilo para el listado.
	 */
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "lightblue" : "lightgrey",
		padding: 10,
	});

	/**
	 * Método encargado de retornar un estilo para los elementos del DnD.
	 * @param {*} isDragging Flag.
	 * @param {*} draggableStyle Estilo base.
	 * @returns Estilo para un elemento.
	 */
	const getItemStyle = (isDragging, draggableStyle) => ({
		position: "relative",
		userSelect: "none",
		background: isDragging ? "lightgreen" : "grey",
		...draggableStyle
	});

	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Droppable droppableId="droppable">
				{(provided, snapshot) => (
					<div
						{...provided.droppableProps}
						ref={provided.innerRef}
						style={getListStyle(snapshot.isDraggingOver)}
					>
						{formik.values.contenido.map((item, index) => (
							<Draggable draggableId={`draggable_${index}`} index={index} key={`draggable_${index}`}>
								{(provided, snapshot) => (
									<div
										ref={provided.innerRef}
										{...provided.draggableProps}
										{...provided.dragHandleProps}
										style={getItemStyle(
											snapshot.isDragging,
											provided.draggableProps.style,
										)}
									>
										{/* SI EL ADJUNTO ES IMAGEN */}
										{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
											<img src={item.adjunto.url} alt="Sin adjunto" width={300} />
										)}
										{/* SI EL ADJUNTO ES VIDEO */}
										{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
											<video width={300} controls>
												<source src={item.adjunto.url} type="video/mp4" />
												<source src={item.adjunto.url} type="video/ogg" />
											</video>
										)}
										{/* BOTÓN PARA ELIMINAR */}
										<Fab onClick={() => handleEliminar(index)} color="secondary" size="small" style={{ position: 'absolute', left: 10, top: 10 }}>
											<Delete />
										</Fab>
										{/* BOTÓN PARA EDITAR */}
										<Fab onClick={() => handleEditar(item, index)} color="primary" size="small" style={{ position: 'absolute', right: 10, top: 10 }}>
											<Edit />
										</Fab>
									</div>
								)}
							</Draggable>
						))}
						{provided.placeholder}
					</div>
				)}
			</Droppable>
		</DragDropContext>
	);
}

const useStyles = makeStyles((theme) => ({
	cuadro: {
		display: "flex",
		marginTop: "10px",
		color: "#731f1f",
	},
}));