import React, { useContext, useEffect, useState } from "react";
import useSWR from "swr";
import Moment from "moment";
import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, Grid, LinearProgress, makeStyles, Paper, Typography } from "@material-ui/core";
import { Beenhere as BeenhereIcon } from "@material-ui/icons";
import { MainContext } from "../../../App";
import { auth } from "../../../services/firebase";
import { ObtenerTemasInteres } from "../../noticias/misNoticias/requestTemasInteres";

export default function TemasInteres(props) {
	const { usuarioSesion } = useContext(MainContext);
	const { data: dataTemas, error: errorNoticias } = useSWR("temas_interes", (key) => ObtenerTemasInteres(usuarioSesion.tags), { revalidateOnFocus: false });
	const classes = useStyles();

	//analytics
	const [claimUser, setClaimUser] = useState(null);
	const loadUsuarioClaims = async () => {
		let resultToken = auth.currentUser
			.getIdTokenResult()
			.then((getIdTokenResult) => {
				setClaimUser(getIdTokenResult.claims);
			});
	};

	useEffect(() => {
		loadUsuarioClaims();
	}, []);

	return (
		<Paper style={{ marginTop: 20, borderRadius: 20 }}>
			<Box display="flex" justifyContent="center" style={{ padding: 10 }}>
				<Typography variant="h6" align="right" style={{ fontWeight: "bold", paddingLeft: 30 }}>
					Temas de Interés
				</Typography>
				<BeenhereIcon style={{ fontSize: 30, color: "#731f1f", marginLeft: 50 }} />
			</Box>
			<Divider variant="middle" />
			<div className={classes.root}>
				{!dataTemas && (
					<Grid item md={6}>
						<label>loading...</label>
						<LinearProgress />
					</Grid>
				)}
				{dataTemas && dataTemas.length === 0 && (
					<Grid item md={6}>
						<label>Sin Noticias</label>
					</Grid>
				)}
				{dataTemas &&
					dataTemas.data.map((tema, index_1) => (
						<Grid container key={index_1}>
							<Grid
								item
								md={12}
								style={{
									paddingLeft: "5px",
									paddingRight: "5px",
								}}
							>
								<div key={`tema_${index_1}`}>
									<Card
										key={`temaCard-${index_1}`}
										elevation={0}
										style={{ margin: "15px" }}
										variant="elevation"
									>
										<CardActionArea href={tema.link_externo} target="_blank">
											<CardMedia
												component="img"
												alt={tema.adjunto.url}
												height="140"
												image={tema.adjunto ? tema.adjunto.url : ""}
												title={tema.titulo}
											/>
											<CardContent style={{ padding: "5px", paddingLeft: 15 }}>
												<Typography variant="caption">
													{" "}
													{Moment(tema.fecha_publicacion).format("DD/MM/YY")}
												</Typography>
												<Typography>
													<strong>{tema.titulo}</strong>
												</Typography>
												<Typography>
													{tema.cuerpo}
												</Typography>
											</CardContent>
										</CardActionArea>
									</Card>
								</div>
							</Grid>
						</Grid>
					))}
			</div>
		</Paper>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		maxHeight: "900px",
		overflowY: "scroll",
		backgroundColor: "#FFFFFF",
		borderBottomLeftRadius: "20px",
		borderBottomRightRadius: "20px",
		paddingBottom: "20px",
	},
}));