import React, { useContext, useState } from "react";
import Page from "./page";
import Moment from "moment";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { PublicarTemaInteres } from "./requestTemasInteres";
import { Upload } from "../../../services/googleStorage";
import { MainContext } from "../../../App";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export default function Index(props) {
	const [imgFoto, setImgFoto] = useState(null);
	const [tempFoto, setTempFoto] = useState(null);
	const { usuarioSesion } = useContext(MainContext);
	const history = useHistory();
	const notistack = useSnackbar();

	const handlePublicar = async (values) => {
		try {
			notistack.enqueueSnackbar("Publicando el tema de interés.", { variant: "default" });

			let nombreFoto = "foto_" + Moment().locale("es").format("DD_MM_YYYY_HH_mm_ss");
			let file = blobToFile(imgFoto, nombreFoto);
			let uploadedFile = null;
			if (file) {
				uploadedFile = await Upload(file, nombreFoto);
			}

			let temaInteres = {
				titulo: values.titulo,
				cuerpo: values.tema_interes,
				link_externo: values.link_externo,
				fecha_publicacion: values.fecha_publicacion,
				_autor_ref: usuarioSesion.ref,
				adjunto: {
					nombre: file.name,
					repositorio: uploadedFile ? uploadedFile.bucket : "",
					url: uploadedFile ? uploadedFile.pathname : "",
					size: uploadedFile ? uploadedFile.size : 0,
					mime_type: uploadedFile ? uploadedFile.content_type : "",
					path: "",
				},
			};

			await PublicarTemaInteres(temaInteres);

			notistack.closeSnackbar();
			notistack.enqueueSnackbar("Tema de interés publicado exitosamente.", {
				variant: "success",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		} catch (error) {
			console.error(error);
			notistack.closeSnackbar();
			notistack.enqueueSnackbar("Error la intentar publicar un tema de interés.", {
				variant: "error",
				anchorOrigin: {
					horizontal: "center",
					vertical: "bottom"
				},
				action: (key) => <IconButton onClick={() => notistack.closeSnackbar(key)}><Close /></IconButton>
			});
		} finally {
			history.replace("/");
		}
	}

	function blobToFile(theBlob, fileName) {
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	}

	return (
		<Page
			handle_publicar={handlePublicar}
			img_foto={imgFoto}
			set_img_foto={setImgFoto}
			temp_foto={tempFoto}
			set_temp_foto={setTempFoto}
		/>
	);
}