import React, { Fragment, useContext } from "react";
import Moment from "moment";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Divider, Grid, Paper, Typography } from "@material-ui/core";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Carousel from "../../../components/carousel";
import { ACCEPTED_FILES_IMAGE, ACCEPTED_FILES_VIDEOS, TEMPLATES } from "./utils";
import { NoticiaAgregarContext } from "./index";
import "moment/locale/es";

export default function Paso4() {
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);

	/**
	 * Método encargado de retornar la colección de Tags.
	 * @returns Colección de Tags activados.
	 */
	const tagsChecked = () => Array.from(NoticiaAgregarCTX.formik.values.tags).filter(t => t.checked);

	/**
	 * Método encargado de verificar si el TAG Todo CyD está activo.
	 * @returns 
	 */
	const isTodoCyD = () => Array.from(NoticiaAgregarCTX.formik.values.tags).find(t => t.nombre === "Todo CyD").checked;

	/**
	 * Método encargado de retornar la fecha de publicación formateada.
	 * @returns Fecha de publicación.
	 */
	const fechaPublicacion = () => Moment(NoticiaAgregarCTX.formik.values.fecha_publicacion).format("DD/MM/YYYY HH:mm");

	return (
		<Fragment>
			{/* NOTICIA CARD */}
			<Typography align="center" variant="h6" style={{ color: "#731f1f", fontWeight: "bold" }}>
				Presentación Tarjeta
			</Typography>
			<Box m={1} align="center">
				<Box m={1} width="30%" align="left">
					<Card elevation={15} style={{ borderRadius: 20 }}>
						<CardActionArea>
							<CardMedia component="img" alt={NoticiaAgregarCTX.formik.values.titulo} height="140" image={NoticiaAgregarCTX.formik.values.contenido[0].adjunto.url} title={NoticiaAgregarCTX.formik.values.titulo} />
							<CardContent>
								<Typography variant="caption" display="block" gutterBottom color="textSecondary">
									{`Fecha Publicación: ${fechaPublicacion()}`}
								</Typography>
								<Typography variant="h6" style={{ color: "#731f1f", fontWeight: "bold" }}>
									{NoticiaAgregarCTX.formik.values.titulo}
								</Typography>

								{NoticiaAgregarCTX.formik.values.bajada && (
									<Box>
										<Typography variant="body2" color="textSecondary" component="p">
											{NoticiaAgregarCTX.formik.values.bajada}
										</Typography>
									</Box>
								)}
							</CardContent>
						</CardActionArea>
						<CardActions>
							{isTodoCyD() && (
								<Chip label="Todo CyD" size="small" variant="outlined" color="primary" style={{ margin: 2 }} />
							)}
							{!isTodoCyD() && tagsChecked().slice(0, Math.min(tagsChecked().length, 5)).map((t, index) => (
								<Chip label={t.sigla} size="small" variant="outlined" color="primary" style={{ margin: 2 }} key={`chip_${index}`} />
							))}
							{!isTodoCyD() && tagsChecked().length > 5 && (
								<Chip label={`${tagsChecked().length - 5}+`} size="small" variant="outlined" color="primary" style={{ margin: 2 }} />
							)}
						</CardActions>
					</Card>
				</Box>
			</Box>

			{/* NOTICIA COMPLETA */}
			<Typography align="center" variant="h6" style={{ color: "#731f1f", fontWeight: "bold", paddingTop: 50 }}>
				Noticia Completa
			</Typography>
			<Box align="center">
				<Paper m={6} variant="outlined" style={{ borderRadius: 20, width: "80%" }}>
					{/* FECHA Y TAGS */}
					<div style={{ width: "100%" }}>
						<Box display="flex" p={1}>
							<Box flexGrow={1}>
								<Typography variant="caption">
									{`Fecha Publicación: ${fechaPublicacion()}`}
								</Typography>
							</Box>
							{isTodoCyD() && (
								<Box>
									<Chip label="Todo CyD" size="small" variant="outlined" color="primary" style={{ margin: 2 }} />
								</Box>
							)}
							{!isTodoCyD() && tagsChecked().map((t, index) => (
								<Box key={`box_${index}`}>
									<Chip label={t.sigla} size="small" variant="outlined" color="primary" style={{ margin: 2 }} />
								</Box>
							))}
						</Box>
					</div>

					{/* EPÍGRAFE */}
					<Box m={1} align="left">
						<Typography>{NoticiaAgregarCTX.formik.values.epigrafe}</Typography>
					</Box>

					{/* TÍTULO */}
					<Box m={1}>
						<Typography align="center" variant="h3" style={{ color: "#731f1f" }}>
							{NoticiaAgregarCTX.formik.values.titulo}
						</Typography>
					</Box>

					{/* BAJADA */}
					{NoticiaAgregarCTX.formik.values.bajada && (
						<Box m={1}>
							<Typography variant="h6">
								{NoticiaAgregarCTX.formik.values.bajada}
							</Typography>
						</Box>
					)}

					{/* CONTENIDO CON TEMPLATE 1 */}
					{NoticiaAgregarCTX.formik.values.template === TEMPLATES.template1.nombre && (
						NoticiaTemplate1(NoticiaAgregarCTX.formik.values.contenido)
					)}

					{/* CONTENIDO CON TEMPLATE 2 */}
					{NoticiaAgregarCTX.formik.values.template === TEMPLATES.template2.nombre && (
						NoticiaTemplate2(NoticiaAgregarCTX.formik.values.contenido)
					)}

					{/* CONTENIDO CON TEMPLATE 3 */}
					{NoticiaAgregarCTX.formik.values.template === TEMPLATES.template3.nombre && (
						NoticiaTemplate3(NoticiaAgregarCTX.formik.values.contenido)
					)}
				</Paper>
			</Box>
		</Fragment>
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function NoticiaTemplate1(contenido) {
	return (
		Array.from(contenido).map((item, index) => (
			<Grid container spacing={2} key={`grid_${index}`}>
				{/* SI EL CONTENIDO TIENE ADJUNTO */}
				{item && item.adjunto && (
					<Grid item xs={12}>
						{/* SI EL ADJUNTO ES IMAGEN */}
						{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
							<img src={item.adjunto.url} alt="Sin adjunto" style={{ width: "100%" }} />
						)}
						{/* SI EL ADJUNTO ES VIDEO */}
						{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
							<video controls style={{ width: "100%" }}>
								<source src={item.adjunto.url} type="video/mp4" />
								<source src={item.adjunto.url} type="video/ogg" />
							</video>
						)}
					</Grid>
				)}
				{/* SI EL CONTENIDO TIENE CUERPO */}
				{item && item.cuerpo && (
					<Grid item xs={12}>
						<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 10, marginRight: 10 }}>
							<div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(item.cuerpo.getCurrentContent())) }} />
						</Typography>
					</Grid>
				)}
			</Grid>
		))
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function NoticiaTemplate2(contenido) {
	return (
		<Grid container spacing={2} style={{ paddingBottom: 20 }}>
			{Array.from(contenido).map((item, index) => (
				<Fragment key={`fragment_${index}`}>
					{/* SI EL CONTENIDO TIENE CUERPO */}
					{item.cuerpo && (
						<Grid item xs={8}>
							<Typography variant="body1" gutterBottom align="justify" style={{ marginLeft: 10, marginRight: 10 }}>
								<div dangerouslySetInnerHTML={{ __html: draftToHtml(convertToRaw(item.cuerpo.getCurrentContent())) }} />
							</Typography>
						</Grid>
					)}

					{/* SI EL CONTENIDO TIENE ADJUNTO */}
					{item.adjunto && (
						<Grid item xs={4}>
							<Box display="flex" alignItems="center" style={{ height: "100%" }}>
								{/* SI EL ADJUNTO ES IMAGEN */}
								{ACCEPTED_FILES_IMAGE.includes(item.adjunto.mime_type) && (
									<img src={item.adjunto.url} alt="Sin adjunto" style={{ width: "100%" }} />
								)}
								{/* SI EL ADJUNTO ES VIDEO */}
								{ACCEPTED_FILES_VIDEOS.includes(item.adjunto.mime_type) && (
									<video controls style={{ width: "100%" }}>
										<source src={item.adjunto.url} type="video/mp4" />
										<source src={item.adjunto.url} type="video/ogg" />
									</video>
								)}
							</Box>
						</Grid>
					)}

					{/* SI EL CONTENIDO INCLUYE BOTÓN PARA REDIRECCIONAR */}
					{item.botonURL && (
						<Grid item xs={12}>
							<Box display="flex" justifyContent="center">
								<Button onClick={() => window.open(item.botonURL, "_blank")} variant="contained" color="primary">
									{item.botonTexto || "Link"}
								</Button>
							</Box>
						</Grid>
					)}

					{/* SEPARADOR */}
					{index !== Array.from(contenido).length - 1 && (
						<Grid item xs={12}>
							<Divider />
						</Grid>
					)}
				</Fragment>
			))}
		</Grid>
	);
}

/**
 * Método encargado de generar el componente que muestra el cuerpo de la noticia, para el Template 1.
 * @param {*} contenido Colección del contenido de la noticia.
 * @returns Componente.
 */
function NoticiaTemplate3(contenido) {
	return (
		<Carousel
			imagenes={Array.from(contenido).map(c => c.adjunto)}
			adicional={Array.from(contenido).map(c => draftToHtml(convertToRaw(c.cuerpo.getCurrentContent())))}
			autoplay={false}
			show_buttons={true}
			show_indicator={true}
		/>
	);
}