import React from "react";

/**
 * Método encargado de retornar el icono PIN.
 * @param {*} width Ancho del icono.
 * @param {*} height Alto del icono.
 */
function PinIcon(props) {
  const {
    width = 24,
    height = 24,
  } = props;

  return (
    <svg style={{ width: width, height: height }} viewBox="0 0 24 24">
      <path fill="currentColor" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
    </svg>
  );
}

export default PinIcon;