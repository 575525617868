import React, { Fragment } from 'react';
import { Box, IconButton, makeStyles, Paper, TextField } from '@material-ui/core';
import {
  FastRewind as FastRewindIcon,
  SkipPrevious as SkipPreviousIcon,
  SkipNext as SkipNextIcon,
  FastForward as FastForwardIcon,
  ZoomOut as ZoomOutIcon,
  ZoomIn as ZoomInIcon,
} from "@material-ui/icons";

const ControlPanel = (props) => {
  const {
    pageNumber,
    numPages,
    setPageNumber,
    setScale,
    scale
  } = props;

  const classes = useStyle();

  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  }
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  }
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  }
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  }

  const onPageChange = (event) => {
    const value = event.target.value;
    setPageNumber(Number(value));
  }

  const isMinZoom = scale <= 0.5;
  const isMaxZoom = scale >= 4.0;

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1)
  }
  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1)
  }

  return (
    <Paper elevation={4} className={classes.paper}>
      <Box>
        {/* PRIMERA PÁGINA */}
        <IconButton onClick={goToFirstPage} disabled={isFirstPage}>
          <FastRewindIcon style={{ color: isFirstPage ? null : "white" }} />
        </IconButton>

        {/* PÁGINA ANTERIOR */}
        <IconButton onClick={goToPreviousPage} disabled={isFirstPage}>
          <SkipPreviousIcon style={{ color: isFirstPage ? null : "white" }} />
        </IconButton>

        {/* INDICADOR DE PÁGINA */}
        <Fragment>
          <label className={classes.page_text_zoom}>{"Página"}</label>
          <TextField value={pageNumber} onChange={onPageChange} inputProps={{ min: 1, max: numPages ? numPages : 1, className:classes.input, }} variant="outlined" size="small" type="number" className={classes.page_textbox} />
          <label className={classes.page_text_zoom}>{`de ${numPages}`}</label>
        </Fragment>

        {/* PáGINA SIGUIENTE */}
        <IconButton onClick={goToNextPage} disabled={isLastPage}>
          <SkipNextIcon style={{ color: isLastPage ? null : "white" }} />
        </IconButton>

        {/* ÚLTIMA PÁGINA */}
        <IconButton onClick={goToLastPage} disabled={isLastPage}>
          <FastForwardIcon style={{ color: isLastPage ? null : "white" }} />
        </IconButton>

        {/* ZOOM OUT */}
        <IconButton onClick={zoomOut} disabled={isMinZoom}>
          <ZoomOutIcon style={{ color: isMinZoom ? null : "white" }} />
        </IconButton>

        {/* ZOOM ACTUAL */}
        <label className={classes.page_text_zoom}>{`${(scale * 100).toFixed()}%`}</label>

        {/* ZOOM IN */}
        <IconButton onClick={zoomIn} disabled={isMaxZoom}>
          <ZoomInIcon style={{ color: isMaxZoom ? null : "white" }} />
        </IconButton>
      </Box>
    </Paper>
  );
}

const useStyle = makeStyles((theme) => ({
  paper: {
    width: "70%",
    backgroundColor: theme.palette.primary.main,
  },
  page_text_zoom: {
    color: "white",
  },
  page_textbox: {
    width: 60,
    margin: 5,
    color: "white",
  },
  input: {
    color: 'white'
}
}));

export default ControlPanel