import React, { Fragment, useContext, useState } from "react";
import useSWR from "swr";
import Moment from "moment";
import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Grid, LinearProgress, makeStyles, Paper, Typography } from "@material-ui/core";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { MainContext } from "../../../App";
import { ObtenerNoticias } from "../requestNoticias";
import { useHistory } from "react-router-dom";
import { analytics, auth } from "../../../services/firebase";
import { Link } from "react-router-dom";

function NoticiasMore(props) {
	const textCuerpo = "";
	const { usuarioSesion } = useContext(MainContext);
	const { data: dataNoticias, error: errorNoticias } = useSWR(
		"noticias_recientes",
		(key) => ObtenerNoticias(usuarioSesion.tags),
		{ revalidateOnFocus: false }
	);
	const classes = useStyles();
	const history = useHistory();

	//analytics
	const [claimUser, setClaimUser] = useState(null);
	const loadUsuarioClaims = async () => {
		let resultToken = auth.currentUser
			.getIdTokenResult()
			.then((getIdTokenResult) => {
				// console.log(getIdTokenResult);
				setClaimUser(getIdTokenResult.claims);
			});
	};

	React.useEffect(() => {
		loadUsuarioClaims();
		window.scrollTo(0, 0);
	}, []);

	return (
		<Fragment>
			{!dataNoticias && (
				<Grid item md={6}>
					<label>loading...</label>
					<LinearProgress />
				</Grid>
			)}
			{dataNoticias && dataNoticias.length === 0 && (
				<Grid item md={6}>
					<label>Sin Noticias</label>
				</Grid>
			)}
			{dataNoticias && dataNoticias.length > 0 && (
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid item md={12} style={{ marginLeft: 10 }}>
							<Typography variant="h4">
								<strong>Noticias Más Recientes</strong>
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Card
							elevation={5}
							className={classes.root}
							style={{ borderRadius: 15, paddingTop: 0 }}
						>
							<CardActionArea
								onClick={() => {
									analytics.logEvent(
										`Home_Ultima_Noticia_${dataNoticias[0]._id}`,
										{
											usuarioCorreo: claimUser.email,
											usuarioNombre: claimUser.name,
											usuarioContrato: claimUser.proyecto,
										}
									);
									history.push(`/noticia-ver/${dataNoticias[0]._id}`);
								}}
							>
								<div
									style={{
										backgroundColor: alpha("#263238", 0.5),
										zIndex: 2,
										position: "absolute",
										height: "-webkit-fill-available",
										width: "-webkit-fill-available",
										zIndex: 9,
									}}
								></div>

								<CardMedia
									component="img"
									alt={dataNoticias[0].titulo}
									height="340"
									style={{ zIndex: 1 }}
									image={
										dataNoticias[0].adjuntos &&
											dataNoticias[0].adjuntos.length > 0
											? dataNoticias[0].adjuntos[0].url
											: ""
									}
									title={dataNoticias[0].titulo}
								/>
								<div
									style={{
										position: "absolute",
										top: "20px",
										left: "20px",
										color: "white",
										zIndex: 10,
									}}
								>
									<Typography
										variant="caption"
										display="block"
										gutterBottom
										style={{
											color: "white",
										}}
									>
										{Moment(dataNoticias[0].fecha_publicacion).format(
											"DD/MM/YYYY HH:mm"
										)}
									</Typography>
									<Typography
										gutterBottom
										variant="h4"
										style={{
											color: "white",
										}}
									>
										<strong>{dataNoticias[0].titulo}</strong>
									</Typography>
									<Typography
										variant="body1"
										style={{
											color: "white",
										}}
										component="p"
									>
										{dataNoticias[0].bajada}
									</Typography>
								</div>
								<div
									style={{
										position: "absolute",
										bottom: "20px",
										left: "20px",
										color: "white",
										zIndex: 10,
									}}
								>
									{Array.from(dataNoticias[0].tags).find(
										(t) => t === "Todo CyD"
									) && (
											<Chip
												label={"Todo CyD"}
												size="small"
												variant="default"
												color="primary"
												style={{ margin: 2 }}
											/>
										)}
									{!Array.from(dataNoticias[0].tags).find(
										(t) => t === "Todo CyD"
									) &&
										Array.from(dataNoticias[0].tags).map((t, index) => (
											<Chip
												label={t}
												size="small"
												variant="default"
												color="primary"
												style={{ margin: 2 }}
												key={`chip_${index}`}
											/>
										))}
								</div>
							</CardActionArea>
						</Card>
					</Grid>
					<Grid item xs={12}></Grid>

					{dataNoticias.slice(1, 5).map((d, index) => (
						<Grid item xs={12} md={6} key={`card_${index}`}>
							<Card
								elevation={5}
								className={classes.root}
								style={{ borderRadius: 15, paddingTop: 0 }}
							>
								<CardActionArea
									onClick={() => {
										history.push(`/noticia-ver/${d._id}`);
										analytics.logEvent(`Home_Noticia_${d._id}`, {
											usuarioCorreo: claimUser.email,
											usuarioNombre: claimUser.name,
											usuarioContrato: claimUser.proyecto,
										});
									}}
								>
									<CardMedia
										component="img"
										alt={d.titulo}
										height="140"
										image={
											d.adjuntos && d.adjuntos.length > 0
												? d.adjuntos[0].url
												: ""
										}
										title={d.titulo}
										style={{ objectFit: "fill" }}
									/>

									<CardContent>
										<Typography
											variant="caption"
											display="block"
											gutterBottom
											color="textSecondary"
										>
											{Moment(d.fecha_publicacion).format("DD/MM/YYYY HH:mm")}
										</Typography>
										<Typography
											gutterBottom
											variant="body2"
											color="textPrimary"
											style={{
												height: "2.8em",
											}}
										>
											<strong>{d.titulo}</strong>
										</Typography>
									</CardContent>
								</CardActionArea>
								<CardActions>
									{Array.from(d.tags).find((t) => t === "Todo CyD") && (
										<Chip
											label={"Todo CyD"}
											size="small"
											variant="outlined"
											color="primary"
											style={{ margin: 2 }}
										/>
									)}
									{!Array.from(d.tags).find((t) => t === "Todo CyD") &&
										Array.from(d.tags).map((t, index) => (
											<Chip
												label={t}
												size="small"
												variant="outlined"
												color="primary"
												style={{ margin: 2 }}
												key={`chip_${index}`}
											/>
										))}
								</CardActions>
							</Card>
						</Grid>
					))}
					{/* ADICIONALES 3 MESES */}
					{dataNoticias.slice(5, 13).map((d, index) => (
						<Grid item md={12} key={`card_${index}`}>
							<Card
								elevation={5}
								// className={classes.root}
								style={{ borderRadius: 15, paddingTop: 0, display: "flex" }}
							>
								<CardActionArea
									onClick={() => {
										history.push(`/noticia-ver/${d._id}`);
										analytics.logEvent(`Home_Noticia_${d._id}`, {
											usuarioCorreo: claimUser.email,
											usuarioNombre: claimUser.name,
											usuarioContrato: claimUser.proyecto,
										});
									}}
								>
									<Grid container>
										<Grid item xs={3}>
											<CardMedia
												component="img"
												alt={d.titulo}
												height="100%"
												image={
													d.adjuntos && d.adjuntos.length > 0
														? d.adjuntos[0].url
														: ""
												}
												title={d.titulo}
												style={{ width: "100%" }}
											/>
										</Grid>
										<Grid item xs={9}>
											<div style={{ display: "flex", flexDirection: "column" }}>
												<CardContent
													style={{
														flex: "1 0 auto",
														paddingTop: 8,
														paddingLeft: 8,
														paddingRight: 8,
														paddingBottom: 0,
													}}
												>
													<Grid container>
														<Grid item xs={6}>
															<Typography
																variant="caption"
																display="block"
																// gutterBottom
																color="textSecondary"
															>
																{Moment(d.fecha_publicacion).format(
																	"DD/MM/YYYY HH:mm"
																)}
															</Typography>
														</Grid>
														<Grid item xs={6} style={{ textAlign: "right" }}>
															{Array.from(d.tags).find(
																(t) => t === "Todo CyD"
															) && (
																	<Chip
																		label={"Todo CyD"}
																		size="small"
																		variant="outlined"
																		color="primary"
																	// style={{ margin: 2 }}
																	/>
																)}
															{!Array.from(d.tags).find(
																(t) => t === "Todo CyD"
															) &&
																Array.from(d.tags).map((t, index) => (
																	<Chip
																		label={t}
																		size="small"
																		variant="outlined"
																		color="primary"
																		style={{ margin: 2 }}
																		key={`chip_${index}`}
																	/>
																))}
														</Grid>
													</Grid>

													<Typography
														gutterBottom
														variant="body2"
														color="textPrimary"
														style={
															{
																// height: "2.8em",
															}
														}
													>
														<strong>{d.titulo}</strong>
													</Typography>
													<Typography
														gutterBottom
														variant="body2"
														color="secondary"
														style={{
															height: "4rem",
															color: "gray",
															width: "100%",
															// paddingBottom:"1rem",
															overflow: "hidden",
															textOverflow: "ellipsis",
															// whiteSpace: "nowrap",
															display: "-webkit-box",
															WebkitBoxOrient: "vertical",
															WebkitLineClamp: 2,
														}}
													>
														<div
															style={{ height: "50px" }}
															dangerouslySetInnerHTML={{
																__html: d.cuerpos,
															}}
														/>
													</Typography>
												</CardContent>
											</div>
										</Grid>
									</Grid>
								</CardActionArea>
							</Card>
						</Grid>
					))}
					<Grid item md={12} style={{ textAlign: "center", marginTop: "1rem", width: "inherit" }}>
						<Paper style={{ borderRadius: 15 }}>
							<Typography width="100%" style={{ padding: "1rem" }}>
								<Button
									component={Link}
									to="/noticias"
									variant="contained"
									color="primary"
								>
									Historial de Noticias
								</Button>
							</Typography>
						</Paper>
					</Grid>
				</Grid>
			)}
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		paddingTop: theme.spacing(2),
	},
}));

export default NoticiasMore;
