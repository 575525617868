import { gapi } from "gapi-script";
import { loadAuth2, loadAuth2WithProps, loadClientAuth2 } from "gapi-script";

export async function getCalendarEvent() {
  var idCorreos_ = [];
  var infoCorreos_ = [];
  var respuestaAPI = [];
  const results_correo = [];
  const results = [];
  try {
    const config = {
      discoveryDocs: [
        "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest/",
        "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest/",
      ],
      discoveryDocsCorreo: [
        "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest/",
      ],
    };
    let scopes =
      "https://www.googleapis.com/auth/calendar.events " +
      "https://www.googleapis.com/auth/calendar.settings.readonly " +
      "https://www.googleapis.com/auth/calendar " +
      "https://mail.google.com/ " +
      "https://www.googleapis.com/auth/gmail.readonly " +
      "https://www.googleapis.com/auth/gmail.modify " +
      "https://www.googleapis.com/auth/gmail.metadata " +
      "https://www.googleapis.com/auth/gmail.addons.current.message.metadata " +
      "https://www.googleapis.com/auth/gmail.addons.current.message.readonly " +
      "https://www.googleapis.com/auth/gmail.addons.current.message.action ";

    console.log("initApi...");

    let initApi = await gapi.client.init({
      apiKey: "AIzaSyBs_oN1-rPljWd9OzLdMhVJ83Z8puMxqw8",
      clientId:
        "908645375548-ckon98i6rf2j9btll81vqq3m218lghs5.apps.googleusercontent.com",
      discoveryDocs: config.discoveryDocs,
      scope: scopes,
    });

    let gapiClient = await loadClientAuth2(
      gapi,
      "908645375548-ckon98i6rf2j9btll81vqq3m218lghs5.apps.googleusercontent.com",
      scopes
    );

    console.log(gapiClient);

    console.log("initApi...ok");

    async function getInfoCalendario() {
      try {
        var resultado = await gapi.client.calendar.events.list({
          calendarId: "primary",
          timeMin: new Date().toISOString(),
          showDeleted: false,
          singleEvents: true,
          maxResults: 10,
          orderBy: "startTime",
        });
        return resultado.result.items;
      } catch (error) {
        console.log("CALENDAR");
        console.error(error);
        throw error;
      }
    }

    let resultadoCorreos = [];

    try {
      // initApi = await gapi.client.init({
      //   apiKey: "AIzaSyBs_oN1-rPljWd9OzLdMhVJ83Z8puMxqw8",
      //   clientId:
      //     "908645375548-ckon98i6rf2j9btll81vqq3m218lghs5.apps.googleusercontent.com",
      //   discoveryDocs: config.discoveryDocsCorreo,
      //   scope: scopes,
      // });
      // console.log(initApi);

      resultadoCorreos = await gapi.client.gmail.users.messages.list({
        userId: "me",
        labelIds: "INBOX",
        maxResults: 10,
      })
    } catch (error) {
      console.log("GMAIL");
      console.error(error);
      throw error;
    }

    //Se guarda la lista de IDs de los correos

    const correosID = resultadoCorreos.result.messages.map(function (correo_1) {
      idCorreos_.push(correo_1.id);
    });
    //Se guarda la información de klos correos

    async function getInfoCorreo(idCorreo_1) {
      var messageRequest = await gapi.client.gmail.users.messages.get({
        userId: "me",
        id: idCorreo_1,
        format: "METADATA",
      });

      function search(nameKey, myArray) {
        for (var i = 0; i < myArray.length; i++) {
          if (myArray[i].name === nameKey) {
            return myArray[i];
          }
        }
      }
      var Subject = search("Subject", messageRequest.result.payload.headers);
      var From = search("From", messageRequest.result.payload.headers);
      var Fecha = search("Date", messageRequest.result.payload.headers);

      return {
        asunto: Subject.value,
        remitente: From.value,
        fechaEnvio: Fecha.value,
        id: idCorreo_1,
      };
    }

    async function consultaConID(idCorreos_) {
      results.push(await getInfoCalendario());
      for (const id of idCorreos_) {
        results_correo.push(await getInfoCorreo(id));
      }
      results.push(results_correo);
      return results;
    }

    let asd = await consultaConID(idCorreos_);

    return asd;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
