import React from "react";
import { Box, Grid, Paper, Typography } from "@material-ui/core";
import { SendGmail } from "../../../services/gmail";

export default function Integrantes() {

	return (
		<Paper style={{ borderRadius: 20, padding: 30 }}>
			<Grid container spacing={2} justifyContent="center" alignItems="center" style={{ paddingLeft: 50, paddingRight: 50 }}>
				{/* TÍTULO */}
				<Grid item xs={12}>
					<Typography variant="h5" align="center" style={{ fontWeight: "bold" }}>
						Integrantes del equipo
					</Typography>
				</Grid>
				{/* INTEGRANTES */}
				{INTEGRANTES.map(integrante => (
					<Grid item xs={3} style={{ padding: 40 }}>
						{/* FOTO DEL INTEGRANTE */}
						<img src={integrante.foto} width={"100%"} style={{ border: "1px solid #731f1f", borderRadius: "0px 40px 0px 0px" }} />
						{/* NOMBRE DEL INTEGRANTE */}
						<Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
							{integrante.nombre}
						</Typography>
						{/* APELLIDO DEL INTEGRANTE */}
						<Typography variant="h6" align="center" style={{ fontWeight: "bold" }}>
							{integrante.apellidos}
						</Typography>
						{/* CARGO DEL INTEGRANTE */}
						<Typography align="center" style={{ fontWeight: "bold" }}>
							{integrante.cargo}
						</Typography>
						{/* CORREO DEL INTEGRANTE */}
						<Box display="flex" justifyContent="center">
							<Typography variant="caption" align="center" style={{ fontWeight: "bold" }}>
								{integrante.correo}
							</Typography>
						</Box>
					</Grid>
				))}
				{/* CONTÁCTANOS */}
				<Grid item xs={12}>
					<Box display="grid" justifyContent="center">
						{/* BÓTON DE CONTACTO */}
						<div onClick={() => SendGmail("comitedecuidado@cydingenieria.cl")} style={{ cursor: "pointer", backgroundColor: "#731f1f", borderRadius: "0px 10px 0px 0px", paddingTop: 5, paddingBottom: 5, paddingLeft: 50, paddingRight: 50, marginBottom: 10 }}>
							<Typography variant="button" align="center" style={{ fontWeight: "bold", color: "white" }}>
								CONTÁCTANOS
							</Typography>
						</div>

						{/* CORREO DE CONTACTO */}
						<Typography variant="caption" align="center" style={{ fontWeight: "bold" }}>
							comitedecuidado@cydingenieria.cl
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Paper >
	);
}

const INTEGRANTES = [
	{
		nombre: "Giannina",
		apellidos: "Gutierrez C.",
		cargo: "Lider de Misión",
		correo: "gguitierrez@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Giannina%20Gutierrez%20PNG.png",
	}, {
		nombre: "Olga",
		apellidos: "Salinas R.",
		cargo: "Consejera",
		correo: "osalinas@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Olga%20Salinas%20PNG.png",
	}, {
		nombre: "Macarena",
		apellidos: "Flores D.",
		cargo: "Consejera",
		correo: "mflores@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Macarena%20Flores.png",
	}, {
		nombre: "Verónica",
		apellidos: "Scepanovic R.",
		cargo: "Consejera",
		correo: "vscepanovic@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Ver%C3%B3nica%20Scepanovic%20PNG.png",
	}, {
		nombre: "Marcos",
		apellidos: "Montoya L.",
		cargo: "Consejero",
		correo: "mmontoya@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Marcos%20Montoya%20PNG.png",
	}, {
		nombre: "Paola",
		apellidos: "Yesca M.",
		cargo: "Consejera",
		correo: "pyesca@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Paola%20Yesca%20PNG.png",
	}, {
		nombre: "Walter",
		apellidos: "Haindl R.",
		cargo: "Consejero",
		correo: "whaindl@cydingenieria.cl",
		foto: "https://storage.googleapis.com/mi-cyd/ComiteCuidado/miembros/Walter%20Haindl%20PNG.png",
	},
];