import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";

export default function Presentacion(props) {

	return (
		<Paper
			elevation={9}
			variant="outlined"
			style={{
				borderRadius: "20px",
				background: "white",
				height: "auto",
				paddingBottom: "2rem",
				paddingTop: "1rem",
			}}
		>

			<Box display="flex" justifyContent="center">
				<Typography gutterBottom align="center" variant="h4" style={{ fontWeight: "bold", color: "#731f1f", paddingTop: "1rem" }}>
					Presentación Bienvenida CyD
				</Typography>
			</Box>

			<Box display="flex" justifyContent="center">
				<iframe
					src={PRESENTACION_CYD_PPT}
					frameborder="0"
					width="70%"
					height="510rem"
					allowfullscreen="true"
					mozallowfullscreen="true"
					webkitallowfullscreen="true"
				></iframe>
			</Box>
		</Paper>
	);
}

const PRESENTACION_CYD_PPT = "https://docs.google.com/presentation/d/e/2PACX-1vR3h9fmbBQdAPJGHSj11oSevip3JQo97T8fdXfjvdbe4dNPlTLOiIGB33ausSPdtuC43auOOInoWb1Q/embed?start=false&loop=false&delayms=3000";