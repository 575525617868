import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import DisplayPDF from "../../../components/displayPDF";
import { CRITERIOS_ESG, RedirectURL } from "../utils";

/**
 * Método encargado de generar el componente para mostrar las políticas corporaticas.
 * @param {*} usuario Datos del usuario logeado.
 * @param {*} expanded FLAG para mostrar el accordion abierto o cerrado.
 * @param {*} handle_change Método encargado de abrir o cerrar el accordion.
 * @returns Component.
 */
export default function CriteriosESG(props) {
	const {
		usuario,
		expanded,
		handle_change,
	} = props;

	/**
	 * Método encargado de redireccionar a una pestaña nueva y contabilizar en Analytics.
	 */
	const handleClick = () => {
		RedirectURL(CRITERIOS_ESG.PDF_URL);
	}

	return (
		<Accordion
			id="criterios_esg"
			expanded={expanded === "panel_criterios"}
			onChange={handle_change("panel_criterios")}
			style={{ borderRadius: 25 }}
		>
			<AccordionSummary expandIcon={<ExpandMore style={{ color: "#731f1f" }} />}>
				<Typography variant="h5" style={{ flexBasis: "100%", color: "#731f1f", fontWeight: "bold" }}>
					Criterios ESG
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container justifyContent="center" spacing={2} style={{ paddingBottom: "2rem", width: "100%" }}>
					<Grid item xs={3} style={{ marginTop: "2rem" }}>
						<div style={{ width: "fit-content" }}>
							<DisplayPDF url={CRITERIOS_ESG.PDF_URL} scale={0.4} />
						</div>
						<Typography onClick={handleClick} gutterBottom align="center" variant="h6" style={{ color: "#731f1f", cursor: "pointer" }}>
							{CRITERIOS_ESG.NAME}
						</Typography>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
}