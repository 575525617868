import React, { Fragment, useContext, useEffect, useState } from "react";
import UseSWR from "swr";
import Moment from "moment";
import { Avatar, Badge, Box, Card, CardActionArea, CardContent, Divider, Grid, LinearProgress, makeStyles, Typography, withStyles } from "@material-ui/core";
import BusinessCenterOutlinedIcon from '@material-ui/icons/BusinessCenterOutlined';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { ObtenerContratosAdjudicados } from "../../ContratosAdjudicados/requestContratosAdjudicados";
import _ from "lodash";

function ContratosCards(props) {

	const [Dato, SetDato] = React.useState();
	const { data: dataContratos } = UseSWR("contratos", (key) => ObtenerContratosAdjudicados(), {
		revalidateOnFocus: false,
	});

	const SmallAvatar = withStyles((theme) => ({
		root: {
			width: 22,
			height: 22,
			border: `2px solid ${theme.palette.background.paper}`,
		},
	}))(Avatar);

	const OrdenarContratos = () => {
		const anioContrato = (item) => Moment(item.fecha).format("YY");
		const arrayOrdenadoPorFecha = dataContratos.data.sort((a, b) => new Moment(b.fecha).format('YYYYMMDD') - new Moment(a.fecha).format('YYYYMMDD'))

		const resultadoOrden = _(arrayOrdenadoPorFecha.slice(0, 10)).groupBy(anioContrato).value();


		for (const key in resultadoOrden) {
			if (Object.hasOwnProperty.call(resultadoOrden, key)) {
				const values = resultadoOrden[key];
				const mesContrato = (item) =>
					Moment(item.fecha).format("MMMM");
				let ordered = _(values).groupBy(mesContrato).value();
				resultadoOrden[key] = ordered;
			}
		}
		return resultadoOrden;
	};

	useEffect(() => {
		if (dataContratos) {
			let dato = OrdenarContratos();
			SetDato(dato);
		}
	}, [dataContratos]);


	const classes = useStyles();
	let today = Moment().get("date");
	let weekday = Moment().get("isoWeekday");

	return (
		<Fragment>
			{/* TITULO E ICONO */}
			<Grid
				container
				style={{

					marginTop: "1rem",
					backgroundColor: "white",
					borderTopRightRadius: "20px",
					borderTopLeftRadius: "20px",
					paddingTop: 8,
					// paddingBottom:8
				}}
			>
				<Grid item xs={10} style={{ alignSelf: "center" }}>
					<Typography
						variant="h6"
						align="center"
						style={{ fontWeight: "bold", paddingLeft: 15 }}
					>
						Contratos Adjudicados
					</Typography>
				</Grid>
				<Grid item xs={2} style={{ width: "30%" }}>
					<BusinessCenterIcon style={{ fontSize: 30, color: "#731f1f" }} />
				</Grid>
				<Grid item xs={12}>
					<Divider variant="middle" />
				</Grid>
			</Grid>

			{!dataContratos && (
				<Grid item md={6}>
					<label>loading...</label>
					<LinearProgress />
				</Grid>
			)}
			{/* LISTADO */}
			{dataContratos?.data && dataContratos.data.length > 0 && (
				<div className={classes.root} style={{ padding: "1rem" }}>
					{Dato &&
						Object.keys(Dato)
							.reverse()
							.map((day, index_1) => (
								<div key={`div_${index_1}`}>
									<Typography className={classes.heading}>
										<Typography
											gutterBottom
											variant="body2"
										>
											<strong>20{day}</strong>
										</Typography>
									</Typography>
									<label>
										{Dato &&
											Object.keys(Dato[day]).map((mes, index_2) => (
												<div key={`div_${index_2}`}>
													<Box style={{ textAlign: "left", color: "gray" }}>
														<Typography style={{ fontSize: "10px" }}>
															{`${mes[0].toUpperCase()}${mes.slice(
																1
															)}`}
														</Typography>

													</Box>

													{Dato[day][mes].map((d, index_3) => (
														<div key={`div_${index_3}`}>
															<Grid container>
																<Grid
																	item
																	xs={2}
																	style={{ paddingLeft: "0.4rem", alignSelf: "center" }}
																>
																	<Badge
																		overlap="circular"
																		anchorOrigin={{
																			vertical: "bottom",
																			horizontal: "right",
																		}}
																	// badgeContent={
																	//   <SmallAvatar
																	//     style={{
																	//       fontSize: "10px",
																	//       backgroundColor: "#731f1f",
																	//     }}
																	//   >
																	//     <BusinessCenterOutlinedIcon style={{ fontSize: 15, color: "white" }} />


																	//   </SmallAvatar>
																	// }
																	>
																		<Avatar
																			style={{
																				width: "45px",
																				height: "45px",
																				color: "black",
																				backgroundColor: "#eeeeee",
																			}}
																		>
																			<BusinessCenterOutlinedIcon style={{ fontSize: 30, color: "#731f1f" }} />
																		</Avatar>
																	</Badge>
																	{/* <img src={`https://ui-avatars.com/api/?size=40&background=E7E7E7&color=000000&rounded=true&bold=true&name=${day}`}/> */}
																	{/* <label>{`Día ${day}`}</label> */}
																</Grid>
																<Grid item xs={10} style={{ paddingLeft: "5px" }}>
																	<div >
																		<Card

																			elevation={0}
																			//   variant="outlined"
																			className={classes.card}
																		>

																			<CardContent
																				style={{ padding: "5px", paddingLeft: 15 }}
																			>
																				<Typography className={classes.typography_nombre}>
																					<strong>{d.nombre}</strong>
																				</Typography>
																				<Typography className={classes.typography_cliente}>
																					Cliente: <strong>{d.cliente}</strong>
																				</Typography>
																				<Typography className={classes.typography_gerencia}>
																					Gerencia: <strong>{d.gerencia}</strong>
																				</Typography>


																				{/* <Typography className={classes.typography_cargo}>
                                Autor: {evento.creator.displayName}
                              </Typography>
                              <Typography className={classes.typography_cargo}>
                                Correo: {evento.creator.email}
                              </Typography> */}

																				{/* <Link>{evento.htmlLink}</Link> */}
																			</CardContent>
																		</Card>
																	</div>
																</Grid>
															</Grid>

														</div>

													))}
													<Divider style={{ marginBottom: "10px", marginTop: "10px" }}></Divider>
												</div>
											))}
									</label>
								</div>
							))}
					{/* SIN CUMPLEAÑEROS */}
					{/* {(!data || !data[today] || Array.from(data[today]).length === 0) && (
          <Typography className={classes.sin_cumpleañeros}>
            Sin cumpleañeros
          </Typography>
        )} */}
					{/* CUMPLEAÑEROS DEL DIA */}
					{/* {data &&
          data[today] &&
          Array.from(data[today]).map((d, index) => (
            <UserCard key={`userCard-${index}`} persona={d.persona} />
          ))} */}


				</div>
			)}
		</Fragment>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		maxHeight: "17rem",
		overflowY: "scroll",
		backgroundColor: "#FFFFFF",
		borderBottomLeftRadius: "20px",
		borderBottomRightRadius: "20px",
		paddingBottom: "20px",
	},
	fecha: {
		marginLeft: theme.spacing(2),
		color: "#731f1f",
	},
	sin_cumpleañeros: {
		marginLeft: theme.spacing(3),
		color: "#731f1f",
	},
	typography_nombre: {
		fontWeight: "bold",
		color: "#731f1f"
	},
	typography_gerencia: {
		fontSize: "11px",
		color: "black"
	},
	typography_cliente: {
		fontSize: "13px",
		color: "black"
	},
}));

export default ContratosCards;
