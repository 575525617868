import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de obtener un perfil, por su ID.
 * @param {*} perfilID ID del perfil.
 * @returns Datos del perfil.
 */
export async function ObtenerPorID(perfilID) {
	try {
		let url = `${BACKEND}/${RUTA_PERFILES}/${perfilID}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de obtener todos los perfiles.
 * @returns Colección de perfiles.
 */
export async function Obtener() {
	try {
		let url = `${BACKEND}/${RUTA_PERFILES}`;
		let response = await Axios.get(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de agregar un nuevo perfil.
 * @param {*} perfil Datos del perfil.
 * @returns Datos del nuevo perfil.
 */
export async function Agregar(perfil) {
	try {
		let url = `${BACKEND}/${RUTA_PERFILES}`;
		let response = await Axios.post(url, perfil);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de actualizar un perfil.
 * @param {*} perfil Datos actualizados del perfil.
 * @returns Datos del perfil actualizado.
 */
export async function Actualizar(perfil) {
	try {
		let url = `${BACKEND}/${RUTA_PERFILES}/${perfil._id}`;
		let response = await Axios.put(url, perfil);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/**
 * Método encargado de eliminar un perfil.
 * @param {*} perfilID ID del perfil.
 * @returns Respuesta.
 */
export async function Eliminar(perfilID) {
	try {
		let url = `${BACKEND}/${RUTA_PERFILES}/${perfilID}`;
		let response = await Axios.delete(url);
		return response.data;
	} catch (error) {
		console.error(error);
		throw error;
	}
}

/******************************************************************************
 * 																CONSTANTES
 *****************************************************************************/

/**
 * Ruta de perfiles en Mi CyD.
 */
const RUTA_PERFILES = "perfiles";