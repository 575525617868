import React, { useState, useEffect } from "react";
import MaterialTable from "@material-table/core";
import { red, green, yellow } from "@material-ui/core/colors/";
const location = require('../../data/material-table.location.json');

/**
 * Componente para hacer la tabla con lostado de datos.
 * @param {*} title Título de la tabla.
 * @param {*} is_loading Flag para determinar si está cargando los datos.
 * @param {*} data Datos para mostrar.
 * @param {*} columns Columnas seleccionadas para mostrar, con RENDER si aplica.
 * @param {*} actions Acciones (botones) por fila y libres.
 * @param {*} components Modificación de componentes (opcional).
 * @param {*} export_function Función para exportar datos en Excel.
 * @param {*} filtering Flag para determinar si incluye Filtering.
 * @param {*} grouping Flag para determinar si incluye Grouping.
 * @param {*} searching Flag para determinar si incluye Search.
 * @param {*} empty_rows Flag para determinar si se rellena o no con filas vacías.
 * @param {*} page_size Tamaño inicial de elementos por página.
 */
function Index(props) {
	const {
		title,
		is_loading,
		data,
		columns,
		actions,
		components,
		export_function,
		filtering = true,
		grouping = true,
		searching = true,
		empty_rows = false,
		exportButton = true,
		columnsButton = true,
		exportAllData = true,
		page_size = 20,
	} = props;

	const [PageSize, SetPageSize] = useState(page_size);
	const [PageSizes, SetPageSizes] = useState([]);

	useEffect(() => {
		let pageSizes = [];
		if (data && data.length > 0) {
		  pageSizes.push(Math.ceil(data.length / 10) * 1);
		  pageSizes.push(Math.ceil(data.length / 10) * 2);
		  pageSizes.push(Math.ceil(data.length / 10) * 5);
		  pageSizes.push(Math.ceil(data.length / 10) * 10);
		}
		if (!pageSizes.find(a => a === page_size)) {
		  pageSizes.push(page_size);
		}
	
		pageSizes.sort((a, b) => a - b);
		SetPageSizes(pageSizes);
	  }, [data, page_size]);

	useEffect(() => {
		SetPageSize(PageSizes[0]);
	}, [PageSizes]);

	let options = {
		padding: "dense",
		pageSize: PageSize,
		pageSizeOptions: PageSizes,
		emptyRowsWhenPaging: empty_rows,
		columnsButton: columnsButton,
		exportButton: exportButton,
		exportAllData: exportAllData,
		search: searching,
		grouping: grouping,
		filtering: filtering,
		actionsColumnIndex: 0,
		headerStyle: {
			fontWeight: 'bold',
			color: "#731f1f",
			textAlign: "center",
		},
		exportCsv: (columns, data) => { export_function() },
		rowStyle: rowData => ({
			backgroundColor: rowData.estado === "rechazado" ? red[500] : rowData.estado === "pendiente" ? yellow[500] : rowData.estado === "recibido" ? green[500] : "white"
		}),
	}

	let localComponents = {}

	return (
		<MaterialTable
			title={title}
			isLoading={is_loading}
			columns={columns}
			options={options}
			localization={location}
			actions={actions}
			data={data}
			components={Object.assign(localComponents, components)}
			style={{ borderRadius: 25 }}

		/>
	);
}

export default Index;