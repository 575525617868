import React, { useContext } from "react";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { NoticiaAgregarContext } from "./index";

export default function Paso3(props) {
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);

	/**
	 * Método encargado de activar/desactivar un switch de TAG.
	 * @param {*} event Datos del evento.
	 */
	const handleSwitch = (event) => NoticiaAgregarCTX.handleChangeTags(event.target.name);

	return (
		<Grid container spacing={0}>
			{/* TÍTULO */}
			<Grid item xs={9}>
				<Typography>Tags</Typography>
			</Grid>
			{/* FECHA DE PUBLICACIÓN */}
			<Grid item xs={3}>
				<DateTimePicker
					label="Fecha de Publicación"
					inputVariant="outlined"
					value={NoticiaAgregarCTX.formik.values.fecha_publicacion}
					onChange={(date) => NoticiaAgregarCTX.formik.setFieldValue("fecha_publicacion", date)}
					okLabel="Aceptar"
					cancelLabel="Cancelar"
					fullWidth
					size="small"
				/>
			</Grid>
			{/* COLUMNA 1 TAGS */}
			{Array.from(NoticiaAgregarCTX.formik.values.tags).slice(0, 9).map((t, index) => (
				<Grid item xs={5} key={`grid_1_${index}`}>
					<FormControlLabel
						label={t.nombre}
						control={<Switch checked={t.checked} name={t.nombre} onClick={handleSwitch} />}
					/>
				</Grid>
			))}
			{/* COLUMNA 2 TAGS */}
			{Array.from(NoticiaAgregarCTX.formik.values.tags).slice(9).map((t, index) => (
				<Grid item xs={5} key={`grid_2_${index}`}>
					<FormControlLabel
						label={t.nombre}
						control={<Switch checked={t.checked} name={t.nombre} onClick={handleSwitch} />}
					/>
				</Grid>
			))}
		</Grid>
	);
}