import Axios from "axios";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de publicar un tema de interés.
 * @param {*} temaInteres Datos del tema de interés.
 * @returns Tema de interés.
 */
export async function PublicarTemaInteres(temaInteres) {
  try {
    let url = `${BACKEND}/temas-interes`;
    let response = await Axios.post(url, temaInteres);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener los temas de interés.
 * @param {*} temaInteres Datos del tema de interés.
 * @returns Tema de interés.
 */
 export async function ObtenerTemasInteres() {
  try {
    let url = `${BACKEND}/temas-interes`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar un tema de interés.
 * @param {*} noticia Datos del tema de interés.
 */
 export async function EliminarTemaInteres(temaInteres) {
  try {
    let url = `${BACKEND}/temas-interes/${temaInteres._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}