import React, { useState } from "react";
import { AppBar, Box, makeStyles, Tab, Tabs } from "@material-ui/core";
import TabUsuariosPlataforma from "./tabUsuariosPlataforma";
import TabRoles from "./tabRoles";
import TabPerfiles from "./tabPerfiles";
import TabPermisos from "./tabPermisos";

function Page(props) {
	const [TabIndex, SetTabIndex] = useState(0);

	const classes = useStyles();

	const handleChange = (event, newValue) => {
		SetTabIndex(newValue);
	};

	return (
		<div className={classes.root}>
			<AppBar position="static">
				<Tabs value={TabIndex} onChange={handleChange} TabIndicatorProps={{ style: { backgroundColor: "white" } }}>
					<Tab label="Usuarios Plataforma" />
					<Tab label="Roles" />
					<Tab label="Perfiles" />
					<Tab label="Permisos" />
				</Tabs>
			</AppBar>
			<TabPanel value={TabIndex} index={0}>
				<TabUsuariosPlataforma />
			</TabPanel>
			<TabPanel value={TabIndex} index={1}>
				<TabRoles />
			</TabPanel>
			<TabPanel value={TabIndex} index={2}>
				<TabPerfiles />
			</TabPanel>
			<TabPanel value={TabIndex} index={3}>
				<TabPermisos />
			</TabPanel>
		</div>
	);
}

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			{...other}
		>
			{value === index && (
				<Box p={2}>
					{children}
				</Box>
			)}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.paper,
	},
}));

export default Page;