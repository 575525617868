/**
 * Método encargado de obtener el ID del Twitter, según gerencia.
 * @param {*} gerencia Gerencia del usuario.
 * @returns ID de Twitter.
 */
export function ObtenerTwitterID(gerencia) {
  let twitter = twitters.find((t) => t.gerencia === gerencia);
  return twitter;
}

const twitters = [
  {
    gerencia: "GAF",
    lista: "1372905004760174600",
  },
  {
    gerencia: "GDN",
    lista: "1372905775891365900",
  },
  {
    gerencia: "GGN",
    lista: "1372906404080717826",
  },
  {
    gerencia: "GIT",
    lista: "1372906836966449156",
  },
  {
    gerencia: "GMI",
    lista: "1372907324822667267",
  },
  {
    gerencia: "GOP",
    lista: "1372907800347734019",
  },
  {
    gerencia: "GPO",
    lista: "1372908127625084928",
  },
  {
    gerencia: "GEI",
    lista: "1372908473726418944",
  },
  {
    gerencia: "GZS",
    lista: "1394341669856755717",
  },
];
