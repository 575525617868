import React, { Fragment } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";

export default function Oficinas(props) {

	return (
		<Paper elevation={8} variant="outlined" align="center" style={{ borderRadius: "20px", background: "white", height: "auto", marginTop: "0.5rem", paddingBottom: "2rem" }}>
			<Grid container style={{ paddingTop: "2rem", textAlign: "center" }}>
				<Grid item xs={12}>
					<Typography gutterBottom align="center" variant="h4" style={{ fontWeight: "bold", color: "#731f1f", marginBottom: "2rem" }}>
						Nuestras Oficinas
					</Typography>
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12}>
					{OficinaComponent(OFICINAS.CASA_MATRIZ)}
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12}>
					{OficinaComponent(OFICINAS.AUGUSTO_LEGUIA)}
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12}>
					{OficinaComponent(OFICINAS.CONCEPCION)}
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12}>
					{OficinaComponent(OFICINAS.ANTOFAGASTA)}
				</Grid>
			</Grid>
		</Paper>
	);
}

/**
 * Método encargado de generar un componente para mostrar la ubicación de las oficinas.
 * @param {*} datos Datos de la oficina.
 * @returns Component.
 */
function OficinaComponent(datos) {
	return (
		<Fragment>
			{/* GOOGLE MAPS */}
			<iframe src={datos.googleMaps} width="250vw" height="400" style={{ borderRadius: "20px" }} />
			{/* NOMBRE */}
			<Typography variant="h6" style={{ fontWeight: "bold", margin: 10 }}>{datos.nombre}</Typography>
			{/* DIRECCIÓN */}
			<Typography style={{ margin: 10 }}>{datos.direccion}</Typography>
			{/* OFICINAS */}
			{datos.oficinas && (
				<Typography style={{ margin: 10 }}>{datos.oficinas}</Typography>
			)}
			{/* UBICACIÓN */}
			<Typography style={{ margin: 10 }}>{datos.ubicacion}</Typography>
			{/* TELÉFONO */}
			<Typography style={{ margin: 10 }}>{datos.telefono}</Typography>
		</Fragment>
	);
}

const OFICINAS = {
	CASA_MATRIZ: {
		nombre: "CASA MATRIZ",
		direccion: "Isidora Goyenechea 3162",
		oficinas: "Oficinas 601, 301 y 302",
		ubicacion: "Las Condes, Santiago",
		telefono: "(56) 22 370 2600 / 601 / 620",
		googleMaps: "https://www.google.com/maps/d/embed?mid=1-qQspF6-cmy_RaX7RM0y1dTdWFfjzcdC",
	},
	AUGUSTO_LEGUIA: {
		nombre: "OFICINA AUGUSTO LEGUÍA",
		direccion: "Augusto Leguía Norte 127",
		oficinas: "",
		ubicacion: "Las Condes, Santiago",
		telefono: "(56) 22 370 2640",
		googleMaps: "https://www.google.com/maps/d/embed?mid=1kDw_13tLfYgA99XTnQFLRq3fX41tKujI",
	},
	CONCEPCION: {
		nombre: "OFICINA CONCEPCIÓN",
		direccion: "Cochrane 758 Interior",
		oficinas: "",
		ubicacion: "Concepción",
		telefono: "(56) 41 223 5856",
		googleMaps: "https://www.google.com/maps/d/embed?mid=1KrRnBojUZ4XydSrRZQPQ26TV-wW6Vm0g",
	},
	ANTOFAGASTA: {
		nombre: "OFICINA ANTOFAGASTA",
		direccion: "José Blümell 0373",
		oficinas: "",
		ubicacion: "Antofagasta",
		telefono: "(56) 55 288 9100",
		googleMaps: "https://www.google.com/maps/d/embed?mid=1KrRnBojUZ4XydSrRZQPQ26TV-wW6Vm0g",
	},
}