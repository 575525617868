import React from "react";
import { useSnackbar } from "notistack";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import Moment from "moment";
import { EliminarContrato } from "./requestContratosAdjudicados";

function DialogEliminarNoticia(props) {
  const {
    noticia,
    dialog_close,
  } = props;

  const notistack = useSnackbar();

  /**
   * Handler para cancelar eliminación.
   */
  const handleCancelar = () => {
    dialog_close();
  }

  /**
   * Handler para aceptar eliminación.
   */
  const handleAceptar = () => {
    EliminarContrato(noticia)
      .then(response => {
        notistack.enqueueSnackbar("Registro de contrato adjudicado eliminado exitosamente.", {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .catch(error => {
        console.error(error);
        notistack.enqueueSnackbar("Error al intentar eliminar el registro de contrato.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "bottom"
          },
          action: <IconButton onClick={() => notistack.closeSnackbar()}><Close /></IconButton>
        });
      })
      .finally(() => dialog_close());
  }

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      open={Boolean(noticia)}
    >
      <DialogTitle>{"Eliminar Registro Contrato Adjudicado"}</DialogTitle>
      <DialogContent dividers>
        <Typography variant="h6">{"Se debe confirmar la eliminación del registro de contrato adjudicado."}</Typography>
        <Typography>{`Título: ${noticia ? noticia.nombre : ""}`}</Typography>
        <Typography>{`Fecha de adjudicación: ${noticia ? Moment(noticia.fecha).format("DD/MM/YYYY HH:mm") : ""}`}</Typography>
        <Typography variant="overline">{"No podrá ser recuperado posteriormente."}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelar} autoFocus variant="outlined" color="primary">
          {"Cancelar"}
        </Button>
        <Button onClick={handleAceptar} variant="contained" color="primary">
          {"Aceptar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DialogEliminarNoticia;