import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Fade,
  Grid,
  GridList,
  GridListTile,
  Link,
  Modal,
  Paper,
  Typography,
  BottomNavigationAction,
} from "@material-ui/core";
import { Parallax } from "react-parallax";
import "../../App.css";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { isBrowser, isMobile } from "react-device-detect";

import PDFReader from "../Nosotros/PDFReader";
import { analytics, auth } from "../../services/firebase";
import { motion } from "framer-motion";

import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const image1 =
  "https://drive.google.com/uc?export=view&id=1gJVyR8NcrUX1efCQzxnvaEU7SV6mgsMz";
const imagenCapacitacion =
  "https://storage.googleapis.com/mi-cyd/formacionydesarrollo/capacitaciones2.png";
const capsulas_google =
  "https://drive.google.com/uc?export=view&id=1oPOTWIZN4yu9u09NabTm6NhJh23eEFTi";
const intro_talana =
  "https://drive.google.com/uc?export=view&id=1uAMp4xG1bSEUS3Yf6wdiWx9E5T2d8j3R";
const compliance =
  "https://drive.google.com/uc?export=view&id=1SBx57RVd9kwv_OHrRQWiXdiC9YI4jmIx";
const documentos =
  "https://drive.google.com/uc?export=view&id=1JUssnkm6Pnn8kCMI7Ns9IqYGJGVKg4sP";
const imagen_cyd_academy =
  "https://storage.googleapis.com/mi-cyd/formacionydesarrollo/academy.png";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 4,
    color: "#fff",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },

  modalStyle1: {
    position: "absolute",
    // float: ,
    // top:'50%',
    // left:'50%',
    overflow: "scroll",
    height: "auto",
    width: "100%",
    display: "block",
    margin: "auto",

    cursor: "grab",
  },
}));

const titulos = createTheme();

titulos.typography.h3 = {
  fontSize: "1.2rem",
  "@media (min-width:600px)": {
    fontSize: "1.7rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "2.3rem",
  },
  fontFamily: "Poppins",
};

titulos.typography.h4 = {
  fontSize: "1rem",
  "@media (min-width:600px)": {
    fontSize: "1.2rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "2rem",
  },
  fontFamily: "Poppins",
};

titulos.typography.h5 = {
  fontSize: "0.5rem",
  "@media (min-width:600px)": {
    fontSize: "0.9rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "1.3rem",
  },
  fontFamily: "Poppins",
  fontWeight: "100",
};

titulos.typography.h6 = {
  fontSize: "0.5rem",
  "@media (min-width:600px)": {
    fontSize: "0.7rem",
  },
  [titulos.breakpoints.up("md")]: {
    fontSize: "1rem",
  },
  fontFamily: "Poppins",
  fontWeight: "100",
};

export default function Bienvenida() {
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [pdfRuta, setPDFRuta] = React.useState(null);
  const [pptRuta, setPptRuta] = React.useState(null);
  const [decargaRuta, setDescargaRuta] = React.useState(null);
  const [descargaBoton, setDescargaBoton] = React.useState(false);

  //analytics
  const [claimUser, setClaimUser] = useState(null);
  const loadUsuarioClaims = async () => {
    let resultToken = auth.currentUser
      .getIdTokenResult()
      .then((getIdTokenResult) => {
        // console.log(getIdTokenResult);
        setClaimUser(getIdTokenResult.claims);
      });
  };

  const [openModal, setOpenModal] = React.useState(false);
  const [openModalPpt, setOpenModalPpt] = React.useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setDescargaBoton(false);
    setOpenModal(false);
  };
  const handleOpenModalPpt = () => {
    setOpenModalPpt(true);
  };

  const handleCloseModalPpt = () => {
    setDescargaBoton(false);
    setOpenModalPpt(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  // Apertura de PDFs  /

  // APERTURA DE PDFs INFORMATIVOS
  //CyD Academy
  const actaCDZ = () => {
    analytics.logEvent("f&d_cdz_acta", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
    setPptRuta(
      "https://storage.googleapis.com/mi-cyd/formacionydesarrollo/CDZ/Acta%20Constituci%C3%B3n%20CBC%20-%20CDZ%2023.09.2020%20Firmado.pdf"
    );
    handleOpenModalPpt();
  };
  const planCDZ = () => {
    analytics.logEvent("f&d_cdz_plan", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
    setPptRuta(
      "https://storage.googleapis.com/mi-cyd/formacionydesarrollo/CDZ/Plan%20de%20Capacitaci%C3%B3n%20CDZ%20Miner%C3%ADa%202021.pdf"
    );
    handleOpenModalPpt();
  };

  //   CyD ACademy
  const handlePptClassroom = () => {
    analytics.logEvent("f&d_manual_classroom", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
    setPptRuta(
      "https://docs.google.com/presentation/d/e/2PACX-1vSb4WxYtIMgC7KvpYmirLIktYaMpXSAxf_G87ssi5j71bsXJvaF8KTR_rxxUWu_mA/embed?start=true&loop=false&delayms=3000"
    );
    setDescargaRuta(
      "https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Instructivo%20CLASSROOM%20(1).pptx"
    );
    setDescargaBoton(true);
    handleOpenModalPpt();
  };

  //   Plan Anual de Cursos
  const handlePlanCapacitacion = () => {
    analytics.logEvent("f&d_plan_anual_capacitaciones", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
    setPptRuta(
      "https://storage.googleapis.com/mi-cyd/formacionydesarrollo/PlanCapacitaci%C3%B3n.pdf"
    );
    handleOpenModalPpt();
  };

  const handleActaComite = () => {
    setPptRuta(
      "https://drive.google.com/file/d/1jXSmLm4Y6BLXvDi2ecLVOYAzAOV7ILr8/preview"
    );
    handleOpenModalPpt();
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  // analytics
  const analyticsCursosCapacitaciones = () => {
    console.log("pruebaolfdsjdjkfbdskj");
    analytics.logEvent("f&d_capacitaciones_cursos", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
  };

  const analyticsCursosCyDAcademy = () => {
    analytics.logEvent("f&d_cydacademy_cursos", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
  };

  const analyticsPostulaInstructor = () => {
    analytics.logEvent("f&d_CyDAcademy_Postular", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
  };

  const analyticsHerramientas = () => {
    analytics.logEvent("f&d_herramientas_google", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
  };

  const analyticsTalana = () => {
    analytics.logEvent("f&d_introduccion_Talana", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
  };

  const analyticsCumplimiento = () => {
    analytics.logEvent("f&d_cumplimiento_normativo", {
      usuarioCorreo: claimUser.email,
      usuarioNombre: claimUser.name,
      usuarioContrato: claimUser.proyecto,
    });
  };

  React.useEffect(() => {
    loadUsuarioClaims();
    analytics.setCurrentScreen("/formacion_y_desarrollo", {
      idUsuario: "PENDIENTE",
    });
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
     
      <Grid
        style={{
          marginLeft: 14,
          marginRight: 14,
        }}
      >
        {/* Introduccion y capacitaciones */}
        <div style={{ textAlign: "-webkit-center" }}>
          {/* Formación y Desarrollo */}
          <Parallax
            bgImage={image1}
            strength={150}
            style={{
              borderRadius: "20px",
              zIndex: 2,
              marginRight: "1rem",
              marginBottom: "2rem",
              width: "100%",
            }}
          >
            <div
              variant="box"
              style={{
                marginRight: "1rem",
                marginBottom: "1rem",
                height: "auto",
                overflow: "hidden",
                height: "17rem",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  marginLeft: "2rem",
                  zIndex: 10,
                }}
              >
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  style={{
                    color: "white",
                  }}
                ></Typography>
                <Typography variant="h3" style={{ color: "white" }}>
                  <strong>FORMACIÓN Y DESARROLLO</strong>
                </Typography>

              </div>
              <div id="cursosOnline"></div>
            </div>
          </Parallax>

          {/* Cursos Online de CyD */}
          <motion.div
            initial="hidden"
            animate="visible"
            variants={{
              hidden: {
                scale: 0.8,
                opacity: 0,
                y: 100,
              },
              visible: {
                scale: 1,
                opacity: 1,
                y: 0,
                transition: {
                  delay: 0.5,
                },
              },
            }}
          >
            <Paper
              variant="outlined"
              style={{
                marginTop: "1rem",
                textAlign: "-webkit-center",
                paddingTop: "1rem",
                paddingBottom: "2rem",
                marginBottom: "1rem",
                borderRadius: "20px",
              }}
            >
              {/* <ThemeProvider theme={titulos}> */}
              <Typography
                gutterBottom
                align="center"
                variant="h4"
                style={{ color: "#731f1f", paddingTop: "1rem" }}
              >
                <strong>Capacitaciones Corporativas</strong>
              </Typography>
              <div id="capacitacionescorporativas"></div>
              <div id="cydacademy"></div>
              {/* </ThemeProvider> */}

              <Box
                m={4}
                style={{
                  marginBottom: "1rem",
                  paddingLeft: "2rem",
                  paddingRight: "2rem",
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={4}>
                    <img
                      src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/iconoscapacitaciones.png"
                      width="100%"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    style={{ alignSelf: "center" }}
                  >
                    <Typography variant="body1" align="justify">
                      En CyD nos preocupamos por tu desarrollo, es por esto que
                      hemos habilitado el Site de Capacitaciones on-line, donde
                      podrás encontrar diferentes tipos de cursos según la
                      necesidad formativa que tengas. Te invitamos a explorar el
                      sitio para que conozcas las ofertas de cursos.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </motion.div>
          {/* CApacitaciones corporativas // cydACademy */}
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: "0.2rem" }}>
              {/* Capacitaciones Corporativas */}
              <Paper
                variant="outlined"
                style={{
                  borderRadius: "20px",
                  overflow: "hidden",
                  marginTop: "1rem",
                  textAlign: "-webkit-center",
                  marginBottom: "1rem",
                  height: "auto",
                }}
              >
                <Grid container style={{ height: "auto" }}>
                  {/* Cuso Site Capacitaciones | TEXTO */}
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    // lg={4}
                    width="-webkit-fill-available"
                    style={{
                      paddingTop: "0.5rem",
                      paddingRight: "1rem",
                      paddingBottom: "1rem",
                      alignSelf: "center",
                    }}
                  >
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h5"
                      style={{
                        color: "#731f1f",
                        // paddingLeft: "1rem",
                      }}
                    >
                      <strong>Cursos Site de Capacitaciones</strong>
                    </Typography>
                    <div id="documentoscapacitaciones"></div>
                    <Typography
                      variant="body1"
                      align="justify"
                      style={{ paddingLeft: "1rem", paddingTop: "1rem" }}
                    >
                      Aquí podemos encontrar la oferta de cursos disponibles en
                      CyD, para un período determinado y según categorías de
                      conocimiento. Si se trata de capacitaciones On-line,
                      podemos postular directamente y realizar estos cursos sin
                      costo para los colaboradores. Estas son las categorías,
                      los invitamos a revisar los cursos disponibles.
                    </Typography>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h6"
                      style={{
                        color: "black",
                        // paddingLeft: "3rem",
                        // paddingRight: "1rem",
                        paddingTop: "0.5rem",
                        marginBottom: "2rem",
                      }}
                    >
                      <Button
                        color="primary"
                        style={{ width: "30%", textAlign: "center" }}
                        variant="contained"
                        href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/inicio"
                        target="_blank"
                        onClick={() => {
                          analytics.logEvent(
                            "f&d_Capacitaciones_ir_al_sitio",
                            {
                              usuarioCorreo: claimUser.email,
                              usuarioNombre: claimUser.name,
                              usuarioContrato: claimUser.proyecto,
                            }
                          );
                        }}
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Ir al sitio </strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Grid>
                  {/* Curso site capacitaciones | BOTONES */}
                  <Grid
                    item
                    xs={12}
                    md={5}
                    // lg={5}
                    width="-webkit-fill-available"
                    style={{
                      paddingTop: "0.5rem",
                      paddingRight: "1rem",
                      paddingBottom: "1rem",
                      alignSelf: "center",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        <Grid container>
                          {/* CAPACITACIONES | HABILIDADES BLANDAS */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/habilidades-blandas"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20Habilidades%20blandas%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_habilidades_blandas",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>
                          {/* CAPACITACIONES | OFIMATICA */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/ofim%C3%A1tica"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20Ofim%C3%A1tica%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_ofimatica",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>
                          {/* CAPACITACIONES | TÉCNICOS */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/t%C3%A9cnicos"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20T%C3%A9cnicos%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_tecnicos",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>
                          {/* CAPACITACIONES | BIM */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/bim"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20BIM%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent("f&d_capacitaciones_bym", {
                                    usuarioCorreo: claimUser.email,
                                    usuarioNombre: claimUser.name,
                                    usuarioContrato: claimUser.proyecto,
                                  });
                                }}
                              />
                            </a>
                          </Grid>
                          {/* CAPACITACIONES | SGI */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/cursos-sgi-y-cphs"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20SGI%20%26%20CPHS%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent("f&d_capacitaciones_sgi", {
                                    usuarioCorreo: claimUser.email,
                                    usuarioNombre: claimUser.name,
                                    usuarioContrato: claimUser.proyecto,
                                  });
                                }}
                              />
                            </a>
                          </Grid>
                          {/* CAPACITACIONES | ADMINISTRACIÓN Y RRHH */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/administraci%C3%B3n-rrhh"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20Administraci%C3%B3n%20y%20RRHH%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_administracion_rrhh",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>

                          {/* CAPACITACIONES | NCH - Normas Chilenas */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/normas-chilenas"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20NCH%20-%20Normas%20chilenas%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_normas_chilenas",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>

                           {/* CAPACITACIONES | CyD Academy */}
                           <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/cyd-academy"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20CyD%20Academy%20(Site%20Capacitaciones).png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_cyd_academy",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>

                          {/* CAPACITACIONES | Cápsulas Google */}
                          <Grid item xs={6} lg={4} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/recomendacionesdigitales/inicio"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/nuevos_botones/Bot%C3%B3n%20c%C3%A1psulas%20Google.png"
                                style={{
                                  width: "65%",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_cápsulas_google",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Curso site capacitaciones | Imagen  */}
                  {isBrowser ? (<Grid
                    item
                    xs={3}
                    // lg={3}
                    style={{ textAlign: "left", alignItems: "left" }}
                    width="-webkit-fill-available"
                  >
                    <Box height="100%">
                      <img
                        src={imagenCapacitacion}
                        alt="mapa"
                        // height="270px"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "0 0",
                        }}
                      />
                    </Box>
                  </Grid>
                  ) : ("")}
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          {/* CYD ACADEMY */}
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: "0.2rem" }}>
              {/* Capacitaciones Corporativas */}
              <Paper
                variant="outlined"
                style={{
                  borderRadius: "20px",
                  overflow: "hidden",
                  marginTop: "1rem",
                  textAlign: "-webkit-center",
                  marginBottom: "1rem",
                  height: "auto",
                }}
              >
                <Grid container style={{ height: "auto" }}>
                  {/* CYD ACADEMY | TEXTO */}
                  <Grid
                    item
                    sm={5}
                    xs={12}
                    width="-webkit-fill-available"
                    style={{
                      paddingTop: "0.5rem",
                      paddingRight: "1rem",
                      paddingBottom: "1rem",
                      alignSelf: "center",
                    }}
                  >

                    <Typography
                      gutterBottom
                      align="center"
                      variant="h5"
                      style={{
                        color: "#731f1f",
                        // paddingLeft: "1rem",
                      }}
                    >
                      <strong>CyD Academy</strong>
                    </Typography>
                    <div id="documentoscapacitaciones"></div>
                    <Typography
                      variant="body1"
                      align="justify"
                      style={{ paddingLeft: "1rem", paddingTop: "1rem" }}
                    >
                      A través de esta plataforma almacenamos y transmitimos el
                      know-how de CyD para CyD. Tenemos disponible cursos
                      internos que están alojados en Classrorm y también
                      cápsulas de información relevantes que pueden ser
                      consultados por los colaboradores. Si son expertos en una
                      materia que podría ser de utilidad en el trabajo de otros
                      colaboradores, entonces los invitamos a ser parte de CyD
                      Academy.
                    </Typography>
                  </Grid>
                  {/* CYD ACADEMY | BOTONES */}
                  <Grid
                    item
                    sm={3}
                    xs={12}
                    width="-webkit-fill-available"
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    <Grid container>
                      <Grid
												item
                        xs={12}
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        <Grid container>
                          <Grid item xs={12} lg={6} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/capacitaciones-cyd/cyd-academy"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/academy.png"
                                style={{
                                  width: "130px",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_habilidades_blandas",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>
                          <Grid item xs={12} lg={6} style={{ marginBottom: "15px" }}>
                            <a
                              href="https://sites.google.com/cydingenieria.cl/recomendacionesdigitales/c%C3%A1psulas"
                              target="_blank"
                            >
                              <img
                                src="https://storage.googleapis.com/mi-cyd/formacionydesarrollo/Botones/capsulas.png"
                                style={{
                                  width: "130px",
                                  objectFit: "fill",
                                }}
                                onClick={() => {
                                  analytics.logEvent(
                                    "f&d_capacitaciones_ofimatica",
                                    {
                                      usuarioCorreo: claimUser.email,
                                      usuarioNombre: claimUser.name,
                                      usuarioContrato: claimUser.proyecto,
                                    }
                                  );
                                }}
                              />
                            </a>
                          </Grid>
                        </Grid>
                      </Grid>
                     
                    </Grid>

                  </Grid>
                  {/* CYD ACADEMY | POSTULAR MANUAL  */}
                  <Grid
                    item
                    // xs={12}
                    xs={12}
                    md={2}
                    width="-webkit-fill-available"
                    style={{
                      // paddingTop: "0.5rem",
                      paddingRight: "1rem",
                      // paddingBottom: "1rem",
                      alignSelf: "center",
                    }}
                  >
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h7"
                      style={{
                        color: "black",
                        // paddingLeft: "3rem",
                        // paddingRight: "1rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <strong>¿Quieres ser instructor de CyD Academy?</strong>
                      <br />
                      <Button
                        color="primary"
                        style={{
                          width: "10%",
                          textAlign: "center",
                          marginBottom: "1rem",
                          marginTop: "0.5rem",
                        }}
                        variant="outlined"
                        href="https://forms.gle/d9dJSxHBN14ATdiL9"
                        target="_blank"
                        onClick={analyticsPostulaInstructor}
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Postula </strong>
                        </Typography>
                      </Button>
                    </Typography>
                    <Typography
                      gutterBottom
                      align="center"
                      variant="h7"
                      style={{
                        color: "black",
                        // paddingLeft: "3rem",
                        // paddingRight: "1rem",
                      }}
                    >
                      <br />
                      <strong>Manual de uso Classroom</strong>
                      <br />
                      <Button
                        color="primary"
                        style={{
                          width: "10%",
                          textAlign: "center",
                          marginBottom: "1rem",
                          marginTop: "0.5rem",
                        }}
                        onClick={handlePptClassroom}
                        variant="outlined"
                      >
                        <Typography variant="h7" style={{ flexBasis: "100%" }}>
                          <strong>Aquí </strong>
                        </Typography>
                      </Button>
                    </Typography>
                  </Grid>
                  {/* CYD ACADEMY | IMAGEN */}
                  {isBrowser ? (
                    <Grid
                      item
                      // md={12}
                      xs={2}
                      style={{ textAlign: "right", alignItems: "right" }}
                      width="-webkit-fill-available"
                    >
                      <img
                        src={imagen_cyd_academy}
                        alt="mapa"
                        height="220px"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                          objectPosition: "0 0",
                        }}
                      />
                    </Grid>
                  ) : ("")}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>

        {/* Modals de PDF */}
        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModal}
          overflow="scroll"
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModal}>
            <div className={classes.paper}>
              <Paper>
                <PDFReader direccionPDF={pdfRuta}></PDFReader>
              </Paper>
              <Box
                zIndex="tooltip"
                style={{
                  position: "fixed",
                  width: "69%",
                  left: "15%",
                  top: "90%",
                  height: "3rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>

        <Modal
          align="center"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalStyle1}
          open={openModalPpt}
          overflow="scroll"
          onClose={handleCloseModalPpt}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openModalPpt}>
            <div
              className={classes.paper}
              style={{ minHeight: "-webkit-fill-available" }}
            >
              <Paper
                style={{ minWidth: "-webkit-fill-available", height: "97vh" }}
              >
                {/* <Box style={{paddingTop:"4rem"}}> */}
                <iframe
                  style={{ align: "middle" }}
                  src={pptRuta}
                  // position="fixed"
                  frameborder="0"
                  width="100%"
                  height="100%"
                  allowfullscreen="true"
                  mozallowfullscreen="true"
                  webkitallowfullscreen="true"
                ></iframe>
                {/* </Box> */}
              </Paper>
              <Box
                zIndex="tooltip"
                style={{
                  position: "fixed",
                  width: "20%",
                  left: "70%",
                  top: "90%",
                  height: "3rem",
                  paddingBottom: "4rem",
                  justifyContent: "center",
                }}
              >
                {descargaBoton == true && (
                  <Button
                    variant="contained"
                    color="primary"
                    // onClick={handleCloseModalPpt}
                    href={decargaRuta}
                    style={{ marginRight: "1rem" }}
                  >
                    Descargar
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleCloseModalPpt}
                >
                  Cerrar
                </Button>
              </Box>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </React.Fragment>
  );
}

// export default Bienvenida;
