import React from "react";
import { Parallax } from "react-parallax";
import { Typography } from "@material-ui/core";

export default function Imagen(props) {

	return (
		<Parallax bgImage={IMAGEN_CYD} strength={150} style={{ borderRadius: "20px", zIndex: 2, marginRight: "1rem", width: "100%" }}>
			<div variant="box" style={{ marginRight: "1rem", marginBottom: "1rem", height: "auto", overflow: "hidden", height: "17rem" }}>
				<div style={{ position: "absolute", top: "20px", marginLeft: "2rem", zIndex: 10 }}>
					<Typography variant="h3" style={{ color: "white", fontWeight: "bold" }}>
						BIENVENIDO A CyD INGENIERÍA
					</Typography>
					<img src={LOGO_CYD_BANNER} height="100px" />
				</div>
			</div>
		</Parallax>
	);
}

const IMAGEN_CYD = "https://drive.google.com/uc?export=view&id=1vlRPZTDpL1u_8zNDTTxM73hm_D-2Kwtf";
const LOGO_CYD_BANNER = "https://drive.google.com/uc?export=view&id=1jSBHprSvA_EcOViNj24Q3NX_kP-bMCpz";