import React, { Fragment, useContext, useEffect } from "react";
import useSWR from "swr";
import { useFormik } from "formik";
import { object, string, array } from "yup";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import MaterialTable from "../../../components/materialTable";
import * as UsuariosPlataformaRequest from "../../../services/requests/mi_cyd/usuarios_plataforma";
import * as PerfilesRequest from "../../../services/requests/mi_cyd/perfiles";
import * as UsuariosRequest from "../../../services/requests/usuarios/usuarios";
import * as GerenciasRequest from "../../../services/requests/portafolio/gerencias";
import { MainContext } from "../../../App";

export default function TabUsuariosPlataforma(props) {
	const UsuariosPlataformaSWR = useSWR("usuariosPlataforma", (key) => UsuariosPlataformaRequest.Obtener(), { revalidateOnFocus: false });
	const UsuariosSWR = useSWR("usuarios", (key) => UsuariosRequest.Obtener(), { revalidateOnFocus: false });
	const PerfilesSWR = useSWR("perfiles", (key) => PerfilesRequest.Obtener(), { revalidateOnFocus: false });
	const GerenciasSWR = useSWR("gerencias", (key) => GerenciasRequest.Obtener(), { revalidateOnFocus: false });
	const { ShowSnackbar } = useContext(MainContext);

	useEffect(() => {
		if (UsuariosPlataformaSWR.error) {
			ShowSnackbar("Error al intentar obtener los usuarios de plataforma.", UsuariosPlataformaSWR.error)
		}
		if (UsuariosSWR.error) {
			ShowSnackbar("Error al intentar obtener los usuarios.", UsuariosSWR.error);
		}
		if (PerfilesSWR.error) {
			ShowSnackbar("Error al intentar obtener los perfiles.", PerfilesSWR.error);
		}
		if (GerenciasSWR.error) {
			ShowSnackbar("Error al intentar obtener las gerencias.", GerenciasSWR.error);
		}
	}, [UsuariosPlataformaSWR.error, UsuariosSWR.error, PerfilesSWR.error, GerenciasSWR.error]);

	const formik = useFormik({
		initialValues: {
			nombre: "",
			rut: "",
			uid: "",
			gerencia_sigla: "",
			_perfiles_ref: [],
			tags: [],
			_preferencias_ref: [],
		},
		validationSchema: object().shape({
			nombre: string()
				.min(3, "El nombre debe tener al menos ${min} caracteres.")
				.max(50, "El nombre debe tener a los más ${max} caracteres.")
				.required("El nombre es requerido."),
			rut: string()
				.min(7, "El RUT debe tener al menos ${min} caracteres.")
				.max(10, "El RUT debe tener a los más ${max} caracteres.")
				.required("El RUT es requerido."),
			uid: string()
				.min(7, "El UID debe tener al menos ${min} caracteres.")
				.required("El UID es requerido."),
			gerencia_sigla: string()
				.min(3, "La gerencia debe tener al menos ${min} caracteres.")
				.max(5, "La gerencia debe tener a los más ${max} caracteres.")
				.required("La gerencia es requerida."),
			_perfiles_ref: array()
				.of(string())
				.min(1, "Se debe incluir al menos ${min} perfil.")
				.required("El perfil es requerido."),
			tags: array()
				.of(string())
				.min(1, "Se debe incluir al menos ${min} tag.")
				.required("Los Tags son requeridos."),
			_preferencias_ref: array()
				.of(string())
				.optional(),
		}),
		onSubmit: (values, helper) => handleAceptar(values),
		enableReinitialize: true,
	});

	/**
	 * Handler para agregar un nuevo rol.
	 */
	const handleAgregar = () => {
		formik.setFieldValue("tipo", "agregar");
	}

	/**
	 * Handler para actualizar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleActualizar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "actualizar");
	}

	/**
	 * Handler para eliminar un rol.
	 * @param {*} event Evento.
	 * @param {*} row Datos.
	 */
	const handleEliminar = (event, row) => {
		formik.setValues(row);
		formik.setFieldValue("tipo", "eliminar");
	}

	/**
	 * Handler para aceptar la acción.
	 * @param {*} values 
	 */
	const handleAceptar = async (values) => {
		try {
			switch (values.tipo) {
				case "agregar":
					await UsuariosPlataformaRequest.Agregar(values);
					break;
				case "actualizar":
					await UsuariosPlataformaRequest.Actualizar(values);
					break;
				case "eliminar":
					await UsuariosPlataformaRequest.Eliminar(values);
					break;
				default:
					throw new Error("Acción no especificada.")
			}
			ShowSnackbar("Acción realizada exitosamente.");
		} catch (error) {
			ShowSnackbar("Error al intentar realizar la acción.", error);
		} finally {
			handleCancelar();
		}
	}

	const handleCancelar = () => {
		UsuariosPlataformaSWR.mutate();
		UsuariosSWR.mutate();
		formik.resetForm();
	}

	let columns = [
		{
			title: "Nombre",
			field: "nombre",
			defaultSort: 'asc'
		}, {
			title: "RUT",
			field: "rut",
		}, {
			title: "Gerencia",
			field: "gerencia_sigla",
		}
	];

	let actions = [
		{
			tooltip: "Agregar",
			icon: "add",
			onClick: () => handleAgregar(),
			isFreeAction: true,
		}, {
			tooltip: "Editar",
			icon: "edit",
			onClick: handleActualizar,
		}, {
			tooltip: "Eliminar",
			icon: "delete",
			onClick: handleEliminar,
		}
	];

	return (
		<Fragment>
			<MaterialTable
				title="Usuario de Plataforma"
				is_loading={UsuariosPlataformaSWR.isValidating}
				data={UsuariosPlataformaSWR && UsuariosPlataformaSWR.data?.data}
				columns={columns}
				actions={actions}
			/>
			<Dialog open={Boolean(formik.values.tipo)} maxWidth="md" fullWidth>
				<DialogTitle>
					{formik.values.tipo === "agregar" && "Agregar Usuario de Plataforma"}
					{formik.values.tipo === "actualizar" && "Actualizar Usuario de Plataforma"}
					{formik.values.tipo === "eliminar" && "Eliminar Usuario de Plataforma"}
				</DialogTitle>
				<DialogContent dividers>
					<DialogContentText>Formulario con información del Usuario de Plataforma.</DialogContentText>
					<Grid container spacing={2}>
						{/* USUARIO */}
						<Grid item xs={6}>
							<Autocomplete
								options={UsuariosSWR && UsuariosSWR.data ? UsuariosSWR.data.filter(u => u.is_gsuite_talana === true) : []}
								value={formik.values.uid && UsuariosSWR && UsuariosSWR.data ? Array.from(UsuariosSWR.data).find(u => u.usuario_id === formik.values.uid) : null}
								getOptionLabel={(usuario) => `${usuario.nombre_completo} (${usuario.contacto.email})`}
								disabled={formik.values.tipo !== "agregar"}
								onChange={(event, value) => {
									if (value) {
										formik.setFieldValue("nombre", value.nombre_completo);
										formik.setFieldValue("rut", value.run);
										formik.setFieldValue("uid", value.usuario_id);
										formik.setFieldValue("gerencia_sigla", value.gerencia_ref.sigla);
									} else {
										formik.setFieldValue("nombre", "");
										formik.setFieldValue("rut", "");
										formik.setFieldValue("uid", "");
										formik.setFieldValue("gerencia_sigla", "");
									}
								}}
								noOptionsText={"Sin personas"}
								renderInput={(params) => (
									<TextField
										name="nombre"
										label="Usuario"
										variant="outlined"
										fullWidth
										error={formik.touched.nombre && Boolean(formik.errors.nombre)}
										helperText={formik.touched.nombre ? formik.errors.nombre : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						{/* GERENCIA */}
						<Grid item xs={6}>
							<Autocomplete
								options={GerenciasSWR ? GerenciasSWR.data : []}
								value={GerenciasSWR && GerenciasSWR.data ? Array.from(GerenciasSWR.data).find(g => g.sigla === formik.values.gerencia_sigla) : null}
								getOptionLabel={(g) => g.sigla}
								disabled={formik.values.tipo === "eliminar"}
								onChange={(event, value) => {
									if (value) {
										formik.setFieldValue("gerencia_sigla", value.sigla);
									} else {
										formik.setFieldValue("gerencia_sigla", "");
									}
								}}
								noOptionsText={"Sin gerencias"}
								renderInput={(params) => (
									<TextField
										name="gerencia_sigla"
										label="Sigla Gerencia"
										variant="outlined"
										fullWidth
										error={formik.touched.gerencia_sigla && Boolean(formik.errors.gerencia_sigla)}
										helperText={formik.touched.gerencia_sigla ? formik.errors.gerencia_sigla : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						{/* PERFILES */}
						<Grid item xs={12}>
							<Autocomplete
								multiple
								options={PerfilesSWR ? PerfilesSWR.data?.data : []}
								value={PerfilesSWR && PerfilesSWR.data?.data ? Array.from(PerfilesSWR.data.data).filter(p => formik.values._perfiles_ref.includes(p._id)) : []}
								getOptionLabel={(perfil) => perfil.nombre}
								disabled={formik.values.tipo === "eliminar"}
								onChange={(event, value) => {
									if (value) {
										formik.setFieldValue("_perfiles_ref", value.map(v => v._id));
									} else {
										formik.setFieldValue("_perfiles_ref", []);
									}
								}}
								renderInput={(params) => (
									<TextField
										label="Perfiles"
										variant="outlined"
										fullWidth
										error={formik.touched._perfiles_ref && Boolean(formik.errors._perfiles_ref)}
										helperText={formik.touched._perfiles_ref ? formik.errors._perfiles_ref : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
						{/* TAGS */}
						<Grid item xs={12}>
							<Autocomplete
								multiple
								options={GerenciasSWR ? GerenciasSWR.data : []}
								value={GerenciasSWR && GerenciasSWR.data ? Array.from(GerenciasSWR.data).filter(g => formik.values.tags.includes(g.sigla)) : []}
								getOptionLabel={(gerencia) => gerencia.sigla}
								disabled={formik.values.tipo === "eliminar"}
								onChange={(event, value) => {
									console.log(value);
									if (value) {
										formik.setFieldValue("tags", value.map(v => v.sigla));
									} else {
										formik.setFieldValue("tags", []);
									}
								}}
								renderInput={(params) => (
									<TextField
										label="Tags"
										variant="outlined"
										fullWidth
										error={formik.touched.tags && Boolean(formik.errors.tags)}
										helperText={formik.touched.tags ? formik.errors.tags : ""}
										{...params}
									/>
								)}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCancelar} variant="outlined" color="primary">
						Cancelar
					</Button>
					<Button onClick={formik.submitForm} variant="contained" color="primary">
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
		</Fragment>
	);
}