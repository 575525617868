import Axios from "axios";
import { BACKEND } from "../../constants/urls";

/**
 * Método encargado de publicar un tema de interés.
 * @param {*} temaInteres Datos del tema de interés.
 * @returns Tema de interés.
 */
export async function PublicarContratoAdjudicado(contrato) {
  try {
    let url = `${BACKEND}/contrato-adjudicado/`;
    let response = await Axios.post(url, contrato);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de publicar un tema de interés.
 * @param {*} temaInteres Datos del tema de interés.
 * @returns Tema de interés.
 */
export async function ObtenerContratosAdjudicados() {
  try {
    let url = `${BACKEND}/contrato-adjudicado`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


/**
 * Método encargado de eliminar una noticia.
 * @param {*} noticia Datos de la noticia.
 */
 export async function EliminarContrato(contrato) {
  try {
    let url = `${BACKEND}/contrato-adjudicado/${contrato._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}