import React, { Fragment, useCallback, useState } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { Paper, Typography } from "@material-ui/core";
import "./styleHistoria.css";

export default function HistoriaCyD(props) {
	const [Page, SetPage] = useState(0);
	const [emblaRef, emblaApi] = useEmblaCarousel({
		draggable: true,
		align: 'start'
	}, []);

	if (emblaApi) {
		//Función asignada en caso de evento "scroll".
		emblaApi.on("scroll", () => {
			let currentPage = emblaApi.selectedScrollSnap();
			SetPage(currentPage);
		});
	}

	/**
	 * Método encargado de pasar a la imagen anterior.
	 */
	const scrollPrev = useCallback(() => {
		if (emblaApi) {
			emblaApi.scrollPrev();
		}
	}, [emblaApi]);

	/**
	 * Método encargado de pasar a la imagen siguiente.
	 */
	const scrollNext = useCallback(() => {
		if (emblaApi) {
			emblaApi.scrollNext();
		}
	}, [emblaApi]);

	return (
		<Fragment>
			<div className="embla__historia" key={`banner_${HISTORIA.length}_images`}>
				<div className="embla__historia__viewport" ref={emblaRef}>
					{/* CAROUSEL */}
					<div className="embla__historia__container">
						{HISTORIA.map((historia, index) => (
							<div className={`embla__historia__slide ${Page === index ? "active" : ""}`} onClick={() => emblaApi?.scrollTo(index)} key={`banner_adjunto_${index}`}>
								<img src={historia.image.url} alt="Sin adjunto" className="embla__historia__slide__img" style={{ opacity: Page === index ? 1 : 0.3 }} />
								<div className="embla__historia__slide__year">{historia.year}</div>
							</div>
						))}
					</div>
				</div>

				{/* BOTONES ANTERIOR Y SIGUIENTE */}
				<div style={{ position: "relative" }}>
					<PrevButton onClick={scrollPrev} />
					<NextButton onClick={scrollNext} />
				</div>
			</div>

			{/* SI EL CONTENIDO ADICIONAL ES TIPO HTML */}
			{emblaApi && (
				<Paper style={{ borderRadius: 10, marginLeft: 40, marginRight: 40 }}>
					<Typography variant="body1" align="justify" style={{ marginLeft: 20, marginRight: 20, marginBottom: 20, paddingTop: 10 }}>
						<div dangerouslySetInnerHTML={{ __html: HISTORIA[Page].content }} />
					</Typography>
				</Paper>
			)}
		</Fragment>
	);
}

/**
 * Método encargado de generar el botón para ir a la página anterior.
 * @param {*} onClick Método encargado de cambiar la página.
 * @returns Component.
 */
const PrevButton = (props) => {
	const { onClick } = props;
	return (
		<button onClick={onClick} className="embla__historia__button embla__historia__button--prev">
			<svg className="embla__historia__button__svg" viewBox="137.718 -1.001 366.563 644">
				<path d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z" />
			</svg>
		</button>
	)
}

/**
 * Método encargado de generar el botón para ir a la página siguiente.
 * @param {*} onClick Método encargado de cambiar la página.
 * @returns Component.
 */
const NextButton = (props) => {
	const { onClick } = props;
	return (
		<button onClick={onClick} className="embla__historia__button embla__historia__button--next">
			<svg className="embla__historia__button__svg" viewBox="0 0 238.003 238.003">
				<path d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z" />
			</svg>
		</button>
	)
}

const HISTORIA = [
	{
		year: "1977",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201977.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Idea de independizarse</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Juan Enrique Cruz Salas, Project Manager de una de las empresas más grandes de ingeniería del mundo, la organización norteamericana Arthur McKee, descubre un nicho de mercado en el área de consultoría de ingeniería. </span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Creación de la empresa </b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">La firma, que se llamó inicialmente Juan Enrique Cruz y Cía., fue pionera en la industria de la consultoría de ingeniería y se ubicó en la calle Augusto Leguía Norte 127, 2 piso.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1978",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201978.jpg",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Primer contrato - MOP</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">La compañía se adjudica su primer contrato con el departamento de Vialidad del Ministerio de Obras Públicas (MOP). Se trata de la inspección técnica del camino que une Loncoche-San José de la Mariquina (Región de La Araucanía). Posteriormente, se suma un segundo contrato de inspección técnica del puente Pichoy en Valdivia.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1979",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201979.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Segundo y tercer contrato vialidad, Ministerio de Obras Públicas</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">La compañía gana la inspección técnica de tres caminos: </span></p><ul><li><p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Lanco-Panguipulli</span></p></li></ul><ul><li><p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Osorno-Puerto Octay</span></p></li></ul><ul><li><p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Puerto Varas-Ensenada </span><br></p></li></ul>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Puentes</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">En ese mismo período, la recientemente creada organización se adjudica la supervisión de 24 puentes que deben transformarse de madera a hormigón.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1981",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201981.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Nuevo socio</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Se incorpora el ingeniero Benjamín Dávila Montané como socio y se modifica la razón social, de modo que la empresa pasa a llamarse Cruz y Dávila Ingenieros Consultores Ltda.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Creación del Departamento de Tasaciones</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Se realizan arbitrajes en las empresas constructoras: Delta, Brotec, Claro Vicuña Valenzuela y Figalem.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1983-1985",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201983-1985.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Obras sanitarias</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Debuta el Departamento de Obras Sanitarias y el cliente es la Empresa Metropolitana de Obras Sanitarias, EMOS.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Edificación privada</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Se da inicio al proceso de edificación privada con la inspección del “Edificio Amanecer”, en la ciudad de Concepción. A continuación se organiza la oficina de CyD Ingeniería en esa ciudad.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1986-1989",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201986-1989.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Codelco</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Primera Inscripción Codelco Chile, División Chuquicamata.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Asesoría obras Congreso Nacional</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería es convocada para realizar los estudios del presupuesto de lo que será el futuro Congreso Nacional. El mandante solicita a la consultora asesoría en la construcción, y esto lo hacen tanto los representantes del Gobierno saliente, como los del recientemente electo Presidente Patricio Aylwin.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1991-1993",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%201991-1993.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Tranques</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Se realiza la primera inspección técnica de un tranque de relaves en la Mina El Soldado para la Compañía Disputada de Las Condes. </span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Obras Villuco</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Proyecto e inversión en nuevo Loteo Fundo Villuco, en Concepción.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Aserradero Los Alpes</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Se inicia el proceso de gerenciamiento del aserradero Los Alpes, Octava Región, Concepción.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1993",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-1993.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Contrato con el Metro de Santiago</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería se adjudica uno de los contratos más importantes de su historia con el Metro de Santiago, en el proyecto de la nueva Línea 5. Desde hace más de 20 años, hasta el día de hoy, atiende a ese cliente, con los mejores resultados.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1996",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-1996.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Ingeniero independiente</b></span>&nbsp;</p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD desarrolla, por primera vez, la tarea de Ingeniero Independiente en la construcción de la Autopista del Itata, acceso Norte a Concepción. Son los primeros servicios como consultor técnico de bancos nacionales e internacionales que llegaban a financiar los grandes proyectos de concesiones de Chile.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1997",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-1997.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Cambio organizacional</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">La empresa experimenta un gran crecimiento y cada vez es más necesaria su profesionalización. Se constituyen las Gerencias de Área y algunos gerentes se incorporan como socios.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Nuevas oficinas</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Después de muchos años de permanecer en un edificio de cuatro pisos en la calle Augusto Leguía Norte 127, 2 piso, la Gerencia Corporativa se traslada a Isidora Goyenechea 3162, Of. 601.</span><br>&nbsp;</p>
		`,
	}, {
		year: "1998",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-1998.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Puentes Llacolén y Bicentenario</b></span>&nbsp;</p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">En lo que constituyeron trabajos emblemáticos para la ciudad de Concepción, CyD Ingeniería participó en la inspección técnica de los nuevos puentes que cruzan el río Bío-Bío, el Llacolén y, luego, el Bicentenario en 2012.</span><br>&nbsp;</p
		`,
	}, {
		year: "1999",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-1999.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Primer aeropuerto Carriel Sur</b></span>&nbsp;</p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Único aeropuerto del Gran Concepción, ubicado en la Comuna de Talcahuano. En abril de 2009 recibió la homologación de aeropuerto internacional, gracias a las reparaciones efectuadas en su pista y la instalación de un sistema de aterrizaje instrumental. Ministerio de Educación Contratos en todo Chile para la inspección técnica de las obras de infraestructura del plan de Jornada Escolar Completa, del Ministerio de Educación.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2000",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202000.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Merval</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería asume el gerenciamiento del proyecto que transforma el ferrocarril de Valparaíso en un Metrotren, realizando obras de ingeniería gigantescas, que implicaron, entre otras cosas, soterrar un tramo del medio de transporte, pero sin detener su operación.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2002",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202002.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Autopista Costanera Norte</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD participó en ese megaproyecto como empresa asesora del Ministerio de Obras Públicas e inspección fiscal para la primera etapa, y posteriormente, como equipo de inspección técnica de la concesionaria.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2003",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202003.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Bío Vías</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Aprovechando la experiencia de CyD en el Metro de Santiago y de Valparaíso, la empresa fue convocada por la Gerencia Zonal del Bio-Bío de la Empresa de los Ferrocarriles del Estado (EFE), para participar en el gerenciamiento de un conjunto de proyectos de mejoramiento de la infraestructura vial y del sistema de transporte público de Concepción.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2004",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202004.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Certificaciones</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Se realiza el proceso de Certificación de Calidad ISO-9001. En los años posteriores se complementó con las Certificaciones ISO-14001 de Medio Ambiente y OHSAS-18001 (hoy ISO 45001) referida a la Prevención, Seguridad y Salud Ocupacional. Las tres certificaciones se mantienen actualizadas a la fecha, con verificaciones cada seis meses.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2006",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202006.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Embalses Convento Viejo, El Bato y Chacrillas</b></span>&nbsp;</p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería es la empresa chilena con más experiencia en la inspección técnica de embalses. La Dirección de Obras Hidráulicas del Ministerio de Obras Públicas comenzó a licitar estos trabajos y la compañía se adjudicó varias. Los embalses más importantes fueron Convento Viejo, El Bato y Embalse Chacrillas que se realizaron entre los años 2006 y 2016. Actualmente se lleva a cabo la inspección de la construcción de los embalses Valle Hermoso, Empedrado y La Punilla.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2007",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202007.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Finaliza el proceso de sucesión</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Los dos socios principales traspasan la Gerencia General y deciden delegar en los que serán los dos nuevos Gerentes Generales, Enrique Cruz y Hugo Ortiz.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Hoteles y casinos Enjoy Dreams</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería fue convocada, tanto por los propietarios de Enjoy, la familia Martínez, como por Dreams, la familia Fischer para el desarrollo de sus proyectos de casinos de juego. CyD intervino en Enjoy Antofagasta, Enjoy Coquimbo, Enjoy Santiago, Dreams Temuco, Dreams Valdivia, Dreams Punta Arenas y Dreams Puerto Varas.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2008-2009",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202008-2009.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Concesiones viales y aeroportuarias</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería participa como inspección técnica de las concesiones de varias autopistas y aeropuertos en todo Chile, destacando el Nuevo Aeropuerto de La Araucanía.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2010",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202010.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Cambio organizacional</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería inicia la transformación de la empresa para preparar una nueva fase de crecimiento y desarrollo. Así, la compañía queda conformada por una Presidencia, una Gerencia General a cargo de dos ejecutivos, ocho Gerencias Operativas y dos Gerencias de Apoyo.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2011",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202011.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Presencia en la minería, inauguran oficina de Antofagasta</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Luego de desarrollar un importante plan en Antofagasta durante cuatro años, CyD Ingeniería  marca un hito relevante dentro de la historia de la compañía, al incorporarse a la actividad minera e inaugurar las oficinas en esa ciudad del norte. A partir de la fecha, la organización cuenta con clientes tales como Codelco Gaby, Centinela, Soquimich, BHP Billiton.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2012",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202012.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Rancagua Express</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería inicia la asesoría y gerenciamiento de otro proyecto ferroviario emblemático, Rancagua Express, el que busca unir Rancagua y Santiago en sólo 55 minutos.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2014",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202014.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Central hidroeléctrica Los Cóndores</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Por primera vez, ENEL externalizó este tipo de trabajo y lo hizo en un consorcio que formaron CyD Ingeniería y Zañartu. Se trata de una central hidroeléctrica subterránea de pasada de 150 MW de potencia instalada, que utilizará agua de la Laguna del Maule, a través de un túnel de aducción de 12 kilómetros. Aportará al Sistema Interconectado Central una energía media anual de 642 GWh, equivalente a la demanda de 200 mil hogares.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2015",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202015.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Inicio de la internacionalización</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Con el establecimiento de contactos en Paraguay, Colombia y Perú, CyD Ingeniería inicia el proceso de internacionalización dentro de Latinoamérica.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2016",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202016.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Voluntariado corporativo</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Si bien desde siempre existió una estrecha interacción con el Hogar de Cristo, se decidió organizar un voluntariado corporativo que permitiera la participación directa de muchos colaboradores de CyD en los distintos proyectos del Hogar de Cristo y de otras instituciones.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2017",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202017.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Metro Líneas 6 y 3</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Con la inauguración de la Línea 6 y próximamente con la nueva Línea 3, CyD Ingeniería continúa siendo parte del crecimiento de la red de Metro de Santiago. </span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería celebra sus 40 años, con una dotación de más de 850 colaboradores.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2018",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202018.JPG",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Planta desaladora Minera Escondida</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería, en conjunto con la empresa americana CDM Smith y el equipo del Mandante, estuvieron a cargo del gerenciamiento de la construcción del proyecto de ampliación de la Planta Desaladora de Minera Escondida, perteneciente al grupo BHP. Esta planta es la mayor desaladora de Sudamérica y permite reducir al mínimo el consumo de agua continental de la mina.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2019",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%20%202019.jpg",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Chuquicamata subterránea</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Aprovechando la experiencia de ambas empresas, CyD Ingeniería y Zañartu comienzan su participación en el proyecto Chuquicamata Subterránea de Codelco, a través del consorcio CDZ Minería. Este importante hito marca el inicio de futuros servicios entregados a la compañía cuprífera.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Ingeniería Metro de Santiago</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería, en conjunto con Zañartu y Subterra, inicia la entrega de servicios de ingeniería básica y de detalle para las nuevas líneas de Metro de Santiago. Esto se suma a los servicios de gerenciamiento que CyD ha prestado a esta compañía.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2020",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202020.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Metro de Bogotá</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">En el marco de la internacionalización y gracias a casi tres décadas de experiencia con Metro de Santiago, CyD Ingeniería, a través de un consorcio chileno-francés-colombiano, se adjudica el gerenciamiento de la primera línea del Metro de Bogotá, uno de los proyectos más importantes de Colombia.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 17px;font-family: 'Poppins';"><b>Traspaso generacional</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD continúa proyectándose al futuro a través de un nuevo traspaso generacional que permite que ejecutivos jóvenes pasen a liderar las distintas áreas. De esta forma, Enrique Cruz asume la Presidencia Ejecutiva y Hugo Ortiz se mantiene en la Gerencia General. A la vez, la estructura de la compañía se organiza en cuatro gerencias operativas y tres gerencias de apoyo.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2021",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202021.png",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 18px;font-family: 'Poppins';"><b>Comité de cuidado</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Mientras Chile y el mundo se adaptaba al contexto de pandemia por COVID-19, nació el Comité de Cuidado, con el objetivo de velar por el bienestar de los colaboradores de CyD Ingeniería, sumándose así al Voluntariado Corporativo que fue creado años antes para ir en ayuda de los más necesitados. Ambas iniciativas se enmarcan en la cultura de la empresa y responden a los principios y valores amparados en el SER CyD.</span><br>&nbsp;</p>
		`,
	}, {
		year: "2022",
		image: {
			url: "https://storage.googleapis.com/mi-cyd/nosotros/Historia%20CyD/L%C3%ADnea%20de%20tiempo%20CyD%20-%202022.jpg",
			mime_type: "image/png",
		},
		content: `
			<p style="text-align:justify;"><span style="color: rgb(128,37,40);background-color: transparent;font-size: 12pt;font-family: 'Poppins';"><b>Hospitales y minería</b></span></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">Gracias a su amplia experiencia en diversos ámbitos, CyD Ingeniería se ha posicionado como un referente importante en el rubro de la ingeniería de consulta en Chile. Hoy la compañía está presente en los proyectos de hospitales públicos más relevantes del país, entregando servicios de asesoría a la inspección fiscal durante la construcción y operación. Además, tiene una destacada participación en importantes contratos de minería, con más de 800 profesionales.</span><br></p>
			<p style="text-align:justify;"><span style="color: rgb(0,0,0);background-color: transparent;font-size: 11pt;font-family: 'Poppins';">CyD Ingeniería celebra sus 45 años, con una dotación de más de 1.800 colaboradores.</span><br>&nbsp;</p>
		`,
	}
];