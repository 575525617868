import axios from 'axios';
import { PORTAFOLIO } from '../../constants/urls'
/**
 * Obtener gerencias
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getGerencias(filter = {}, cancelToken = null) {
	return axios.get(`${PORTAFOLIO}/gerencias/`,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Obtener gerencia por id
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
 export function getGerencia(id, cancelToken = null) {
	return axios.get(`${PORTAFOLIO}/gerencias/${id}`,
		{
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Obtener contratos
 * @param {*} filter filtro para resultados
 * @param {*} cancelToken token para cancelacion
 */
export function getContratos(filter = {}, cancelToken = null) {
	return axios.get(`${PORTAFOLIO}/contratos/`,
		{
			params: {
				gerencia_ref: filter._gerencia_ref
			},
			cancelToken: cancelToken
		});
}

/**
 * Obtener contrato por id
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
export function getContrato(id, cancelToken = null) {
	return axios.get(`${PORTAFOLIO}/contratos/${id}`,
		{
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Obtener sociedades
 * @param {*} filter filtro para resultasdos
 * @param {*} cancelToken token para cancelacion
 */
export function getSociedades(filter = {}, cancelToken = null) {
	return axios.get(`${PORTAFOLIO}/sociedades/`,
		{
			params: filter,
			cancelToken: cancelToken
		}).then(res => res.data);
}

/**
 * Obtener sociedad por id
 * @param {*} id
 * @param {*} cancelToken token para cancelacion
 */
export function getSociedad(id, cancelToken = null) {
	return axios.get(`${PORTAFOLIO}/sociedades/${id}`,
		{
			cancelToken: cancelToken
		}).then(res => res.data);
}