import React, { Fragment, useState } from "react";
import { Backdrop, Box, Button, Chip, CircularProgress, Fab, Grid, makeStyles, Paper, TextField, Typography } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import Template1 from "./template1";
import Template2 from "./template2";
import Template3 from "./template3";
import DialogTags from "./dialogTags";

export default function Page(props) {
	const {
		formik,
		tags,
		handle_cancelar,
	} = props;

	const {
		errors,
		handleChange,
		touched,
		values,
		handleSubmit,
		setFieldValue,
		isSubmitting,
	} = formik;

	const [IsOpen, SetIsOpen] = useState(false);

	const classes = useStyles();

	/**
	 * Handler para abrir popup de Tags.
	 */
	const handleOpenDialog = () => {
		SetIsOpen(true);
	}

	/**
	 * Handler para cerrar popup de Tags.
	 */
	const handleCloseDialog = () => {
		SetIsOpen(false);
	}

	return (
		<div>
			{!values || !values._id && (
				<Fragment>
					<CircularProgress color="inherit" style={{ marginRight: 20 }} />
					<Typography>Cargando la noticia...</Typography>
				</Fragment>
			)}

			{values && values._id && (
				<Fragment>
					{/* FORMULARIO DE LA NOTICIA */}
					<Paper elevation={4} style={{ borderRadius: 20, padding: 10, margin: 10 }}>
						<form>
							<Grid container spacing={2}>
								{/* EPÍGRAFE */}
								<Grid item xs={12}>
									<TextField
										name="epigrafe"
										label="Epígrafe (Opcional)"
										value={values.epigrafe}
										onChange={handleChange}
										variant="outlined"
										size="small"
										fullWidth
									/>
								</Grid>
								{/* TÍTULO */}
								<Grid item xs={12}>
									<TextField
										name="titulo"
										label="Título"
										value={values.titulo}
										onChange={handleChange}
										variant="outlined"
										size="small"
										fullWidth
										error={touched.titulo && Boolean(errors.titulo)}
										helperText={touched.titulo ? errors.titulo : ""}
									/>
								</Grid>
								{/* BAJADA */}
								<Grid item xs={12}>
									<TextField
										name="bajada"
										label="Bajada (Opcional)"
										value={values.bajada}
										onChange={handleChange}
										variant="outlined"
										size="small"
										fullWidth
									/>
								</Grid>
								{/* FECHA DE PUBLICACIÓN */}
								<Grid item xs={3}>
									<DateTimePicker
										name="fecha_publicacion"
										label="Fecha de Publicación"
										inputVariant="outlined"
										value={values.fecha_publicacion}
										onChange={date => setFieldValue("fecha_publicacion", date)}
										okLabel="Aceptar"
										cancelLabel="Cancelar"
										size="small"
										fullWidth
									/>
								</Grid>
								{/* TAGs */}
								<Grid item xs={12}>
									<Grid container display="flex" justifyContent="flex-end" alignItems="center">
										{Array.from(values.tags).find((t) => t === "Todo CyD") && (
											<Chip
												label="Todo CyD"
												size="small"
												variant="outlined"
												color="primary"
												style={{ margin: 2 }}
											/>
										)}
										{!Array.from(values.tags).find((t) => t === "Todo CyD") && Array.from(values.tags).map((t, index) => (
											<Chip
												label={t}
												size="small"
												variant="outlined"
												color="primary"
												style={{ margin: 2 }}
												key={`chip_${index}`}
											/>
										))}
										<Fab onClick={handleOpenDialog} color="secondary" size="small" className={classes.fab_button}>
											<EditIcon />
										</Fab>
									</Grid>
									<Grid container display="flex" justifyContent="flex-end" alignItems="center">
										{/* MENSAJE DE ERROR DE TAGs */}
										<Typography variant="caption" color="error">
											{touched.titulo ? errors.tags : ""}
										</Typography>
									</Grid>
								</Grid>
								<Grid item xs={12}>
								</Grid>
							</Grid>
						</form>
					</Paper>

					{String(values.template).toUpperCase() === TEMPLATE_1 && (
						<Template1 formik={formik} />
					)}
					{String(values.template).toUpperCase() === TEMPLATE_2 && (
						<Template2 formik={formik} />
					)}
					{String(values.template).toUpperCase() === TEMPLATE_3 && (
						<Template3 formik={formik} />
					)}

					{/* BOTONES DE ACCIÓN CANCELAR/ACEPTAR */}
					<Box display="flex" justifyContent="center">
						<Box p={1}>
							<Button onClick={handle_cancelar} variant="outlined" color="secondary">Cancelar</Button>
						</Box>
						<Box p={1}>
							<Button onClick={handleSubmit} variant="contained" color="primary">Actualizar</Button>
						</Box>
					</Box>

					{/* DIALOG PATA MODIFICAR LOS TAGs */}
					{IsOpen && (
						<DialogTags
							formik={formik}
							tags={tags}
							is_open={IsOpen}
							handle_close={handleCloseDialog}
						/>
					)}
				</Fragment>
			)}

			{/* PANTALLA DE CARGA */}
			<Backdrop open={isSubmitting} className={classes.backdrop}>
				<CircularProgress color="inherit" style={{ marginRight: 20 }} />
				<Typography>Actualizando la Noticia...</Typography>
			</Backdrop>
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 4,
		color: "#fff",
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: "25ch",
	},
	cuadro: {
		display: "flex",
		marginTop: "10px",
		color: "#731f1f"
	},
	rich_text: {
		minHeight: 300,
		margin: theme.spacing(2),
	},
	fab_button: {
		marginLeft: theme.spacing(1),
	},
}));

const TEMPLATE_1 = "TEMPLATE_1";
const TEMPLATE_2 = "TEMPLATE_2";
const TEMPLATE_3 = "TEMPLATE_3";