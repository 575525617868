import React, { Fragment, useContext, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, TextField } from "@material-ui/core";
import { Editor } from "react-draft-wysiwyg";
import { Upload } from "../../../services/googleStorage";
import { ACCEPTED_FILES_IMAGE, ACCEPTED_FILES_VIDEOS, TEMPLATES, URL_PATTERN } from "./utils";
import { NoticiaAgregarContext } from "./index";
import { MainContext } from "../../../App";

import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function DialogEdit(props) {
	const {
		contenido,
		is_open,
		template,
		handle_close,
	} = props;

	const [SelectedFile, SetSelectedFile] = useState(contenido.adjunto);
	const [RichText, SetRichText] = useState(contenido.cuerpo);
	const [ButtonText, SetButtonText] = useState(contenido.botonTexto);
	const [ButtonURL, SetButtonURL] = useState(contenido.botonURL);
	const [Loading, SetLoading] = useState(false);
	const NoticiaAgregarCTX = useContext(NoticiaAgregarContext);
	const MainCTX = useContext(MainContext);

	const handleEditar = async () => {
		try {
			SetLoading(true);
			let contenidoArray = Array.from(NoticiaAgregarCTX.formik.values.contenido);
			contenidoArray[contenido.index] = {
				...contenidoArray[contenido.index],
				cuerpo: RichText,
				botonTexto: ButtonText,
				botonURL: ButtonURL,
			}
			NoticiaAgregarCTX.formik.setValues({
				...NoticiaAgregarCTX.formik.values,
				contenido: contenidoArray,
			});
		} catch (error) {
			MainCTX.ShowSnackbar("Error al intentar editar el adjunto.", error);
		} finally {
			SetSelectedFile(null);
			SetRichText(null);
			SetLoading(false);
			handle_close();
		}
	}

	/**
	 * Método encargado de verificar si el template seleccionado es el TEMPLATE 2.
	 * @returns TRUE: Es tipo Template 2 | FALSE: No es tipo Template 2.
	 */
	const CheckTemplate2 = () => template === TEMPLATES.template2.nombre;

	/**
	 * 
	 * @returns TRUE: Botón habilitado | FALSE: Botón inhabilitado.
	 */
	const CheckDisabled = () => {
		if (CheckTemplate2() && ButtonText && ButtonURL) {
			return !SelectedFile || Loading || ButtonText.length < 3 || !URL_PATTERN.test(ButtonURL);
		} else {
			return !SelectedFile || Loading;
		}
	}

	return (
		<Dialog open={is_open} onClose={handle_close}>
			<DialogTitle>Imagen y Cuerpo</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Seleccione un archivo y agregue un cuerpo a la imagen (OPCIONAL).
				</DialogContentText>

				<Grid container spacing={2}>
					{/* IMAGEN PREVIEW */}
					{SelectedFile && (
						<Grid item xs={12}>
							{ACCEPTED_FILES_IMAGE.includes(SelectedFile.mime_type) && (
								<img src={SelectedFile.url} style={{ width: "50%", display: "block", marginTop: 10, marginBottom: 10, marginLeft: "auto", marginRight: "auto" }}></img>
							)}
							{ACCEPTED_FILES_VIDEOS.includes(SelectedFile.mime_type) && (
								<video controls style={{ width: "50%", display: "block", marginTop: 10, marginBottom: 10, marginLeft: "auto", marginRight: "auto" }}>
									<source src={SelectedFile.url} type="video/mp4" />
									<source src={SelectedFile.url} type="video/ogg" />
								</video>
							)}
						</Grid>
					)}

					{/* URL DE BOTÓN (OPCIONAL) */}
					{template === TEMPLATES.template2.nombre && (
						<Fragment>
							<Grid item xs={4}>
								<TextField
									name="texto"
									label="Texto del botón"
									value={ButtonText}
									onChange={(event) => SetButtonText(event.target.value)}
									variant="outlined"
									size="small"
									fullWidth
									helperText={ButtonText && String(ButtonText).length < 3 && "Texto muy corto."}
									error={Boolean(ButtonText) && String(ButtonText).length < 3}
								/>
							</Grid>
							<Grid item xs={8}>
								<TextField
									name="url"
									label="URL del botón"
									value={ButtonURL}
									onChange={(event) => SetButtonURL(event.target.value)}
									variant="outlined"
									size="small"
									fullWidth
									helperText={ButtonURL && !URL_PATTERN.test(ButtonURL) && "Formato de URL incorrecto."}
									error={Boolean(ButtonURL) && !URL_PATTERN.test(ButtonURL)}
								/>
							</Grid>
						</Fragment>
					)}

					{/* RICH TEXT */}
					<Grid item xs={12}>
						<Paper elevation={4}>
							<Editor
								toolbar={{
									options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"],
									inline: {
										options: ["bold", "italic", "underline", "strikethrough"]
									}
								}}
								editorState={RichText}
								onEditorStateChange={SetRichText}
								editorStyle={{ minHeight: 150, margin: 20 }}
							/>
						</Paper>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handle_close} variant="outlined" color="primary">
					Cancelar
				</Button>
				<Button onClick={handleEditar} disabled={CheckDisabled()} endIcon={Loading && <CircularProgress size={24} />} variant="contained" color="primary">
					Editar
				</Button>
			</DialogActions>
		</Dialog>
	);
}