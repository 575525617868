import React, { useContext, useEffect } from "react";
import Page from "./page";
import { MainContext } from "../../App";
import { ObtenerTwitterID } from "../../services/twitter";
import { getCalendarEvent } from "../../services/calendar";
import { getGmailMails } from "../../services/gmail";
import { onPermissionCheck } from "../../services/firebase";

export default function HomeConteiner() {
  const { usuarioSesion } = useContext(MainContext);
  const [open, setOpen] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [eventos, SetEventos] = React.useState(null);
  const [correos, setCorreos] = React.useState(null);

  //  React.useEffect(()=>{
  //     const loadCalendar = async ()=>{
  //       let resultado = await getCalendarEvent()
  //       console.log(resultado);
  //     }
  //     loadCalendar();
  //   },[])

  // useEffect(() => {
  //   onPermissionCheck().then(async (result) => {
  //     getCalendarEvent()
  //       .then((resultado) => {
  //         SetEventos(resultado);
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         SetEventos([]);
  //       });
  //   });
  // }, []);

  useEffect(() => {
    onPermissionCheck().then(async (result) => {
      getCalendarEvent()
        .then((resultado) => {
          console.log(resultado);
          SetEventos(resultado);
        })
        .catch((error) => {
          console.error(error);
          SetEventos([]);
        });
      // getGmailMails()
      //   .then((resultadoCorreos) => {
      //     setCorreos(resultadoCorreos);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     setCorreos([]);
      //   });
      
    });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Page
      open={open}
      activeStep={activeStep}
      handleClickOpen={handleClickOpen}
      handleClose={handleClose}
      handleNext={handleNext}
      handleBack={handleBack}
      twitter={ObtenerTwitterID(
        usuarioSesion ? usuarioSesion.gerencia.sigla : null
      )}
      calendario={eventos && eventos[0]}
      correos={eventos && eventos[1]}
    />
  );
}
