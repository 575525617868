import { ADMINISTRACION_USUARIOS } from "../../../constants/urls";
import Axios from "axios";

/**
 * Método encargado de obtener todas las personas de CyD.
 */
export async function ObtenerPersonas() {
  try {
    let url = `${ADMINISTRACION_USUARIOS}/personas`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}