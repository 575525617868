import Axios from "axios";
import Moment from "moment";
import { BACKEND } from "../../../constants/urls";

/**
 * Método encargado de obtener todas las noticias publicadas.
 */
export async function ObtenerPublicadas() {
  try {
    let url = `${BACKEND}/comunicados?fecha_publicacion_lte=${Moment().toISOString()}&&en_revision_eq=false`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todas las noticias en revisión.
 */
export async function ObtenerEnRevision() {
  try {
    let url = `${BACKEND}/comunicados?en_revision_eq=true`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener todas las noticias programadas.
 */
export async function ObtenerProgramadas() {
  try {
    let url = `${BACKEND}/comunicados?fecha_publicacion_gt=${Moment()}&&en_revision_eq=false`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de obtener los temas de interes.
 * @returns Colección de temas de interes.
 */
export async function ObtenerTemasInteres() {
  try {
    let url = `${BACKEND}/comunicados?tipo_eq=tema_interes`;
    let response = await Axios.get(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de mostrar/ocultar una noticia, dependiendo de su estado actual.
 * @param {*} noticia Datos de la noticia.
 */
export async function MostrarOcultarNoticia(noticia) {
  try {
    let valor = noticia.is_eliminado;
    let url = `${BACKEND}/comunicados/${noticia._id}`;
    let response = await Axios.put(url, { is_eliminado: !valor });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de marcar una noticia como revisada.
 * @param {*} noticia Datos de la noticia.
 */
export async function RevisarNoticia(noticia) {
  try {
    let url = `${BACKEND}/comunicados/${noticia._id}`;
    let response = await Axios.put(url, { en_revision: false });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de pinear una noticia.
 * @param {*} noticia Datos de la noticia.
 */
export async function PinNoticia(noticia) {
  try {
    let noticiaPinned = await ObtenerPinned();
    if (noticiaPinned) {
      //Si existe una noticia pineada.
      await UnpinNoticia(noticiaPinned);
    }
    let url = `${BACKEND}/comunicados/${noticia._id}`;
    //Se pinea la noticia seleccionada.
    let response = await Axios.put(url, { is_pinned: true });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de despinear una noticia.
 * @param {*} noticia Datos de la noticia.
 */
export async function UnpinNoticia(noticia) {
  try {
    let url = `${BACKEND}/comunicados/${noticia._id}`;
    let response = await Axios.put(url, { is_pinned: false });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método encargado de eliminar una noticia.
 * @param {*} noticia Datos de la noticia.
 */
export async function EliminarNoticia(noticia) {
  try {
    let url = `${BACKEND}/comunicados/${noticia._id}`;
    let response = await Axios.delete(url);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * Método privado encargado de obtener la noticia actualmente pineada.
 */
async function ObtenerPinned() {
  try {
    let url = `${BACKEND}/comunicados?is_pinned_eq=true`;
    let response = await Axios.get(url);
    let noticiaPinned = response.data.data && response.data.data.length > 0 ? response.data.data[0] : null;
    return noticiaPinned;
  } catch (error) {
    console.error(error);
    throw error;
  }
}