import React from "react";
import { Avatar, Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { isMobile } from "react-device-detect";

function Index(props) {
	const {
		persona,
	} = props;

	const classes = useStyles();

	return (
		<Card elevation={0} className={classes.card}>
			<CardContent style={{ padding: "5px" }}>
				<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
					<Grid item md={2} xs={2} sm={2}>
						<Avatar
							alt={`${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno[0]}.`}
							src={persona.photo ? persona.photo : `https://ui-avatars.com/api/?name=${persona.nombre}+${persona.apellido_paterno}&background=f5f5f5&color=731f1f&rounded=true`}
							className={classes.avatar_size}
						/>
					</Grid>
					<Grid container item xs={10} sm={10} md={10} direction="column" justifyContent="flex-start" alignItems="flex-start" style={{ paddingLeft: "10px" }}>
						<Typography className={classes.typography_nombre}>{`${persona.nombre} ${persona.apellido_paterno} ${persona.apellido_materno[0]}.`}</Typography>
						<Typography className={classes.typography_email}><strong>{persona.email}</strong></Typography>
						{persona.codigo_proyecto && (
							<Typography className={classes.typography_cargo}><strong>{persona.codigo_proyecto.slice(0, 3)}</strong> - {persona.cargo}</Typography>
						)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
}

const useStyles = makeStyles((theme) => ({
	card: {
		flexGrow: 1,
		margin: theme.spacing(1),
		backgroundColor: "#FFFFFF",
	},
	avatar_size: {
		width: isMobile ? "40px" : "40px",
		height: "40px",
	},
	typography_nombre: {
		fontWeight: "bold",
		color: "#731f1f"
	},
	typography_email: {
		fontSize: "11px",
		color: "black"
	},
	typography_cargo: {
		fontSize: "11px",
		color: "#black"
	}
}));

export default Index;